import {Component, Input, Output, EventEmitter} from '@angular/core';

@Component({
  selector: 'app-main-header',
  templateUrl: './main-header.component.html',
  styleUrls: ['./main-header.component.scss']
})
export class MainHeaderComponent {
    @Input() modulo!: string
    @Output() manualClick = new EventEmitter<boolean>();
    @Output() masivoClick = new EventEmitter<boolean>();
    @Output() ocrClick = new EventEmitter<boolean>();
    @Output() apiClick = new EventEmitter<boolean>();


    emitManualClick(value: boolean){
        this.manualClick.emit(value)
    }
    emitMasivoClick(value: boolean){
        this.masivoClick.emit(value)
    }
    emitOcrClick(value: boolean){
        this.ocrClick.emit(value)
    }
    emitApiClick(value: boolean){
        this.apiClick.emit(value)
    }

}
