<!--#stepper linear-->
<mat-stepper linear class="bg-transparent" labelPosition="bottom">
    <mat-step
        *ngFor="let step of containerData; index as idx"
        [stepControl]="getFormControl(step.formControl)"
    >
        <ng-template matStepLabel>{{ step.title }}</ng-template>
        <ng-template matStepContent>
            <ng-container [ngSwitch]="step.formControl">
                <div
                    class="flex font-bold py-12 text-gray text-3xl"
                    [ngClass]="{
                        'justify-center': step.formControl !== 'beneficiarios',
                        'justify-between': step.formControl === 'beneficiarios'
                    }"
                >
                    {{ getStepTitle(step.formControl) }}

                    <mat-form-field
                        class="w-72"
                        *ngIf="step.formControl === 'beneficiarios'"
                    >
                        <mat-label
                            >Cuantos beneficiarios tiene el
                            colaborador</mat-label
                        >
                        <mat-select
                            placeholder="selecciona"
                            [(ngModel)]="beneficiariesCount"
                            (selectionChange)="
                                handleBeneficiariesCountChange($event)
                            "
                        >
                            <mat-option
                                *ngFor="
                                    let opt of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                                "
                                [value]="opt"
                            >
                                {{ opt }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>

                <div class="px-1/4" *ngSwitchCase="'datos_empresa'">
                    <ng-template
                        *ngTemplateOutlet="companyTemplate"
                    ></ng-template>
                </div>
                <div *ngSwitchCase="'datos_empleado'">
                    <ng-container
                        *ngIf="
                            type !== TipoEntidadType.ALL;
                            else elseEmployeeBlock
                        "
                    >
                        <ng-template
                            *ngTemplateOutlet="employeeTemplate"
                        ></ng-template>
                    </ng-container>
                    <ng-template #elseEmployeeBlock>
                        <ng-template
                            *ngTemplateOutlet="employeeFUATemplate"
                        ></ng-template>
                    </ng-template>
                </div>
                <div
                    *ngSwitchCase="'informacion_afiliacion'"
                    class="px-1/5 pb-6"
                >
                    .
                    <ng-template
                        *ngTemplateOutlet="affiliationTemplate"
                    ></ng-template>
                </div>
                <div class="px-1/5 pb-6" *ngSwitchCase="'beneficiarios'">
                    <ng-template
                        *ngTemplateOutlet="beneficiariesTemplate"
                    ></ng-template>
                </div>

                <div class="px-1/4">
                    <div
                        class="grid gap-4 grid-cols-1 content-center sm:grid-cols-1 lg:grid-cols-1"
                    >
                        <ng-container
                            #saveChangesValidBeneficiaries
                            *ngIf="
                                step.formControl === 'beneficiarios';
                                else saveChangesValidDefault
                            "
                        >
                            <button
                                [disabled]="!isBeneficiariesValid()"
                                *ngIf="!acceptedSteps[idx]"
                                (click)="acceptedSteps[idx] = true"
                                mat-stroked-button
                                color="primary"
                            >
                                Guardar cambios
                            </button>
                            <button
                                *ngIf="acceptedSteps[idx]"
                                mat-raised-button
                                class="bg-green text-white"
                            >
                                Validado
                            </button>
                        </ng-container>
                        <ng-template #saveChangesValidDefault>
                            <button
                                [disabled]="
                                    !getFormControl(step.formControl).valid
                                "
                                *ngIf="!acceptedSteps[idx]"
                                (click)="acceptedSteps[idx] = true"
                                mat-stroked-button
                                color="primary"
                            >
                                Guardar cambios
                            </button>
                            <button
                                *ngIf="acceptedSteps[idx]"
                                mat-raised-button
                                class="bg-green text-white"
                            >
                                Validado
                            </button>
                        </ng-template>

                        <button
                            *ngIf="
                                step.formControl === 'datos_empresa' ||
                                    step.formControl === 'datos_empleado';
                                else nextElseBlock
                            "
                            [disabled]="!acceptedSteps[idx]"
                            mat-raised-button
                            color="primary"
                            matStepperNext
                        >
                            Siguiente
                        </button>
                        <ng-template #nextElseBlock>
                            <button
                                *ngIf="
                                    haveBeneficiaries &&
                                        step.formControl !== 'beneficiarios';
                                    else nextBeneficiarieElseBlock
                                "
                                [disabled]="!acceptedSteps[idx]"
                                mat-raised-button
                                color="primary"
                                matStepperNext
                            >
                                Siguiente
                            </button>

                            <!--<ng-template #nextBeneficiarieElseBlock>
                                <button
                                    [disabled]="!acceptedSteps[idx]"
                                    mat-raised-button
                                    color="primary"
                                    (click)="saveAffiliation()"
                                >
                                    Enviar Afiliación
                                </button>
                            </ng-template>-->
                            <ng-template #nextBeneficiarieElseBlock>
                                <button
                                    [disabled]="!acceptedSteps[idx]"
                                    mat-raised-button
                                    color="primary"
                                    (click)="saveAffiliation()"
                                >
                                    Generar Afiliación
                                </button>
                            </ng-template>
                        </ng-template>
                    </div>
                </div>
            </ng-container>
        </ng-template>
    </mat-step>
</mat-stepper>

<ng-template #companyTemplate>
    <app-company-form
        [form]="getFormControlAsFormGroup('datos_empresa')"
        (companyChange)="onCompanySelected($event)"
        [departamentos]="departmentsList"
    ></app-company-form>
</ng-template>

<ng-template #employeeTemplate>
    <app-employee-form
        [clientId]="getFormControlAsFormGroup('datos_empresa').get('id').value"
        [form]="getFormControlAsFormGroup('datos_empleado')"
        [departamentos]="departmentsList"
    ></app-employee-form>
</ng-template>

<ng-template #employeeFUATemplate>
    <app-employee-fua-form
        [clientId]="getFormControlAsFormGroup('datos_empresa').get('id').value"
        [parametros]="parametersData"
        [form]="getFormControlAsFormGroup('datos_empleado')"
        [departamentos]="departmentsList"
    ></app-employee-fua-form>
</ng-template>

<ng-template #affiliationTemplate>
    <app-affiliation-form
        [entity]="type"
        [form]="getFormControlAsFormGroup('informacion_afiliacion')"
        [tieneBeneficiarios]="haveBeneficiaries"
        [indeedDate]="
            getFormControlAsFormGroup('datos_empresa').get('fecha_ingreso')
                .value
        "
        [clientId]="getFormControlAsFormGroup('datos_empresa').get('id').value"
        [employeeDocuments]="
            getFormControlByFormAsFormArray(
                'documentos',
                getFormControlAsFormGroup('datos_empleado')
            )
        "
        (beneficiariosChange)="haveBeneficiariesHandler()"
    >
    </app-affiliation-form>
</ng-template>

<ng-template #beneficiariesTemplate>
    <app-beneficiaries-container
        [form]="getFormControlAsFormArray('beneficiarios')"
        [parametros]="parametersData"
        [amount]="beneficiariesCount"
        [departamentos]="departmentsList"
        [entity]="type"
        (atDelete)="handleAtDelete($event)"
        (updateValidity)="handleUpdateValidityBeneficiaries($event)"
    >
    </app-beneficiaries-container>
</ng-template>
