import {Component, EventEmitter, inject, Input, Output, SimpleChanges} from '@angular/core';
import {AbstractControl, FormArray, FormBuilder, FormControl} from "@angular/forms";
import {FileService} from "@utils/functions/fileService";
import Swal from "sweetalert2";
import {UtilPopupService} from "@shared/services/util-popup.service";
import {AfiliacionesDocumentoService} from "@shared/services/documents/afiliaciones-documento.service";
import {TipoEntidadType} from "@shared/enums/tipo-afiliacion.enum";

@Component({
  selector: 'app-beneficiares-documents-form',
  templateUrl: './beneficiares-documents-form.component.html',
  styleUrls: ['./beneficiares-documents-form.component.scss']
})
export class BeneficiaresDocumentsFormComponent {
  @Input() parametros = Object({})
  @Input() codeType: string
  @Input() documentos: FormArray
  @Input() entity!: string
  @Input() isEditing = true

  @Output() eventSaveChanges: EventEmitter<boolean> = new EventEmitter<boolean>()

  FILES_PAD = {
    copia_documento_identidad_trabajador: {cod: 'BEN_CAJA_PAD_CP_DI_TRABJ', entity: 'ccf', value: ''},
    copia_documento_identidad_padre_madre_caja: {cod: 'BEN_CAJA_PAD_CP_DI_PADRES', entity: 'ccf', value: ''},
    copia_documento_identidad_padre_madre_eps: {cod: 'BEN_EPS_PAD_CP_DI_PADRES', entity: 'eps', value: ''},
    copia_registro_civil_nacimiento_trabajador_demostrar_parentesco_caja: {
      cod: 'BEN_CAJA_PAD_CP_RC_NAC_TRABJ',
      entity: 'ccf',
      value: ''
    },
    copia_registro_civil_nacimiento_trabajador_demostrar_parentesco_eps: {
      cod: 'BEN_EPS_PAD_CP_RC_TRABJ',
      entity: 'eps',
      value: ''
    },
    certificado_afiliacion_en_grupo_familiar_eps: {cod: 'BEN_EPS_PAD_CER_EPS', entity: 'eps', value: ''},
    declaracion_juramentada_dependencia_economica_eps: {cod: 'BEN_EPS_PAD_DJ_DEP_ECO', entity: 'eps', value: ''}
  }

  FILES_PAD_VALUES = {
    copia_documento_identidad_trabajador: '',
    copia_documento_identidad_padre_madre: '',
    copia_registro_civil_nacimiento_trabajador_demostrar_parentesco: '',
    certificado_afiliacion_en_grupo_familiar: '',
    declaracion_juramentada_dependencia_economica: '',
  }

  FILES_CON = {
    copia_documento_identificacion: {cod: 'BEN_CAJA_CON_CP_DI', entity: 'ccf', value: ''},
    copia_documento_identificacion_beneficiario: {cod: 'BEN_EPS_CON_CP_DI_BEN', entity: 'eps', value: ''},
    registro_civil_matrimonio: {cod: 'BEN_EPS_CON_REG_CIVIL_MAT', entity: 'eps', value: ''}
  }

  FILES_CON_VALUES = {
    copia_documento_identificacion: '',
    registro_civil_matrimonio: '',
  }

  FILES_COM = {
    copia_documento_identificacion: {cod: 'BEN_CAJA_COM_CP_DI', entity: 'ccf', value: ''},
    copia_documento_identificacion_beneficiario: {cod: 'BEN_EPS_COM_CP_DI_BEN', entity: 'eps', value: ''},
    declaracion_juramentada: {cod: 'BEN_EPS_COM_DJ', entity: 'eps', value: ''}
  }

  FILES_COM_VALUES = {
    copia_documento_identificacion: '',
    declaracion_juramentada: '',
  }

  FILES_CUS = {
    copia_registro_civil_nacimiento_beneficiario: {cod: 'BEN_EPS_CUS_CP_RC_NAC_BEN', entity: 'eps', value: ''},
    copia_documento_identidad: {cod: 'BEN_EPS_CUS_CP_DI', entity: 'eps', value: ''},
    documento_custodia_provenga_autoridad_competente: {cod: 'BEN_EPS_CUS_DOC_CUST', entity: 'eps', value: ''}
  }

  FILES_CUS_VALUES = {
    copia_registro_civil_nacimiento_beneficiario: '',
    copia_documento_identidad: '',
    documento_custodia_provenga_autoridad_competente: '',
  }

  FILES_HIJ_7 = {
    copia_registro_civil_nacimiento_caja: {cod: 'BEN_HIJ_M_7_CP_RC_NAC_HJ', entity: 'ccf', value: ''},
    copia_registro_civil_nacimiento_eps: {cod: 'BEN_EPS_HIJ_M_7_CP_RC_NAC_HJ', entity: 'eps', value: ''}
  }

  FILES_HIJ_7_VALUES = {
    copia_registro_civil_nacimiento: '',
  }

  FILES_HIJ_7_18 = {
    copia_registro_civil_nacimiento_caja: {cod: 'BEN_HIJ_7_18_CP_RC_NAC', entity: 'ccf', value: ''},
    copia_registro_civil_nacimiento_eps: {cod: 'BEN_EPS_HIJ_7_18_CP_RC_NAC', entity: 'eps', value: ''},
    copia_tarjeta_identidad_caja: {cod: 'BEN_HIJ_7_18_CP_TI', entity: 'ccf', value: ''},
    copia_tarjeta_identidad_eps: {cod: 'BEN_EPS_HIJ_7_18_CP_TI', entity: 'eps', value: ''},

  }

  FILES_HIJ_7_18_VALUES = {
    copia_registro_civil_nacimiento: '',
    copia_tarjeta_identidad: ''
  }

  FILES_HIJ_18_23 = {
    copia_registro_civil_nacimiento: {cod: 'BEN_HIJ_18_25_CP_RC_NAC', entity: 'ccf', value: ''},
    copia_documento_identidad: {cod: 'BEN_HIJ_18_25_CP_DI', entity: 'ccf', value: ''}
  }

  FILES_HIJ_18_23_VALUES = {
    copia_registro_civil_nacimiento: '',
    copia_documento_identidad: ''
  }

  FILES_HER_23 = {
    copia_legible_registro_civil: {cod: 'BEN_CAJA_CP_RC_TRABJ', entity: 'ccf', value: ''},
    copia_documento_identificacion_trabajador: {cod: 'BEN_CAJA_CP_DI_TRABJ', entity: 'ccf', value: ''},
    copia_legible_registro_civil_hermano: {cod: 'BEN_CAJA_CP_RC_HER', entity: 'ccf', value: ''},
    copia_documento_identificacion_hermano: {cod: 'BEN_CAJA_CP_DI_HER', entity: 'ccf', value: ''}
  }

  FILES_HER_23_VALUES = {
    copia_legible_registro_civil: '',
    copia_documento_identificacion_trabajador: '',
    copia_legible_registro_civil_hermano: '',
    copia_documento_identificacion_hermano: '',
  }

  FILES_HIJ_MEN_25 = {
    copia_registro_civil_nacimientos_copia_documento_identidad: {
      cod: 'BEN_EPS_HIJ_18_25_CP_RC_NAC_CP_DI',
      entity: 'eps',
      value: ''
    },
    declaracion_jurementada_demostrando_dependencia_economica: {
      cod: 'BEN_EPS_HIJ_18_25_DJ_DEP_ECO_BEN',
      entity: 'eps',
      value: ''
    },
    certificado_escolaridad_vigente: {cod: 'BEN_EPS_HIJ_18_25_CER_ESC_VIG', entity: 'eps', value: ''},
  }

  FILES_HIJ_MEN_25_VALUES = {
    copia_registro_civil_nacimientos_copia_documento_identidad: '',
    declaracion_jurementada_demostrando_dependencia_economica: '',
    certificado_escolaridad_vigente: '',
  }

  FILES_HIJ_25_DISC = {
    copia_registro_civil_nacimiento_beneficiario: {
      cod: 'BEN_EPS_HER_23_DISC_CP_RC_NAC_BEN',
      entity: 'eps',
      value: ''
    },
    copia_documento_identidad_beneficiario: {cod: 'BEN_EPS_HER_23_DISC_CP_DI_BEN_X_EDAD', entity: 'eps', value: ''},
    certificado_discapacidad: {cod: 'BEN_EPS_HER_23_DISC_CER_DISC_MSPS', entity: 'eps', value: ''},
    formulario_afiliacion_firmado: {cod: 'BEN_EPS_HER_23_DISC_FORM_AFI_TRABJ', entity: 'eps', value: ''}
  }

  FILES_HIJ_25_DISC_VALUES = {
    copia_registro_civil_nacimiento_beneficiario: '',
    copia_documento_identidad_beneficiario: '',
    certificado_discapacidad: '',
    formulario_afiliacion_firmado: '',
  }

  FILES_HIJ_A_18 = {
    copia_registro_civil_nacimiento: {cod: 'BEN_EPS_HIJ_A_18_CP_RC_NAC', entity: 'eps', value: ''},
    copia_tarjeta_identidad: {cod: 'BEN_EPS_HIJ_A_18_CP_TI', entity: 'eps', value: ''},
    certificado_afiliacion_eps: {cod: 'BEN_EPS_HIJ_A_18_CER_EPS', entity: 'eps', value: ''},
    declaracion_juramentada_dependencia_economica_convivencia: {
      cod: 'BEN_EPS_HIJ_A_18_DJ_DEP_ECO',
      entity: 'eps',
      value: ''
    },
  }

  FILES_HIJ_A_18_VALUES = {
    copia_registro_civil_nacimiento: '',
    copia_tarjeta_identidad: '',
    certificado_afiliacion_eps: '',
    declaracion_juramentada_dependencia_economica_convivencia: '',
  }

  FILES_HIJ_A_18_25 = {
    copia_documento_identidad: {cod: 'BEN_EPS_HIJ_A_18_25_CP_DI', entity: 'eps', value: ''},
    declaracion_juramentada: {cod: 'BEN_EPS_HIJ_A_18_25_DJ_DEP_ECO_BEN', entity: 'eps', value: ''},
    certificado_adopcion: {cod: 'BEN_EPS_HIJ_A_18_25_CERT_ADOP', entity: 'eps', value: ''},
    cetificado_afiliacion_eps: {cod: 'BEN_EPS_HIJ_A_18_25_CERT_EPS', entity: 'eps', value: ''},
  }

  FILES_HIJ_A_18_25_VALUES = {
    copia_documento_identidad: '',
    declaracion_juramentada: '',
    certificado_adopcion: '',
    cetificado_afiliacion_eps: '',
  }

  FILES_H_HUER_18 = {
    copia_registro_civil: {cod: 'BEN_EPS_HER_18_CP_RC_TRABJ', entity: 'eps', value: ''},
    copia_legible_registro_civil_documento_identificacion: {
      cod: 'BEN_EPS_HER_18_CP_RC_CP_DI_TI_HER',
      entity: 'eps',
      value: ''
    },
    certificado_defuncion_padres: {cod: 'BEN_EPS_HER_18_CERT_DEF', entity: 'eps', value: ''},
    declaracion_juramentada_dependencia_economica_convivencia: {
      cod: 'BEN_EPS_HER_18_DJ_DEP_ECO',
      entity: 'eps',
      value: ''
    },
    certificado_afiliacion_eps: {cod: 'BEN_EPS_HER_18_CERT_EPS', entity: 'eps', value: ''},
  }

  FILES_H_HUER_18_VALUES = {
    copia_registro_civil: '',
    copia_legible_registro_civil_documento_identificacion: '',
    certificado_defuncion_padres: '',
    declaracion_juramentada_dependencia_economica_convivencia: '',
    certificado_afiliacion_eps: '',
  }

  FILES_H_HUER_25 = {
    copia_registro_civil: {cod: 'BEN_EPS_HER_18_23_CP_RC_TRABJ', entity: 'eps', value: ''},
    copia_legible_registro_civil_hermano: {cod: 'BEN_EPS_HER_18_23_CP_RC_CP_DI_CC_HER', entity: 'eps', value: ''},
    certificado_defuncion_padres: {cod: 'BEN_EPS_HER_18_23_CERT_DEF', entity: 'eps', value: ''},
    declaracion_juramentada_dependencia_economica_convivencia: {
      cod: 'BEN_EPS_HER_18_23_DJ_DEP_ECO_CONV',
      entity: 'eps',
      value: ''
    },
    certificado_afiliacion_eps: {cod: 'BEN_EPS_HER_18_23_CER_EPS', entity: 'eps', value: ''},
    certificado_escolaridad_vigente: {cod: 'BEN_EPS_HER_18_23_CERT_ESC', entity: 'eps', value: ''},
  }

  FILES_H_HUER_25_VALUES = {
    copia_registro_civil: '',
    copia_legible_registro_civil_hermano: '',
    certificado_defuncion_padres: '',
    declaracion_juramentada_dependencia_economica_convivencia: '',
    certificado_afiliacion_eps: '',
    certificado_escolaridad_vigente: '',
  }

  FILES_H_HUER_25_DISC = {
    copia_registro_civil_beneficiario_trabajador: {
      cod: 'BEN_EPS_HER_23_DISC_H_CP_RC_NAC_BEN_Y_TRABJ',
      entity: 'eps',
      value: ''
    },
    copia_documento_identidad_beneficiario: {cod: 'BEN_EPS_HER_23_DISC_H_CP_DI_BEN', entity: 'eps', value: ''},
    registro_civil_defuncion_padres: {cod: 'BEN_EPS_HER_23_DISC_H_RC_DEF_PAD_MAD', entity: 'eps', value: ''},
    declaracion_juramentada_dependencia_economica_convivencia: {
      cod: 'BEN_EPS_HER_23_DISC_H_DJ_DEP_CONV',
      entity: 'eps',
      value: ''
    },
    certificado_discapacidad: {cod: 'BEN_EPS_HER_23_DISC_H_CER_DISC_MSPS', entity: 'eps', value: ''},
    certificado_afiliacion_eps: {cod: 'BEN_EPS_HER_23_DISC_H_CERT_EPS', entity: 'eps', value: ''},
    formulario_afiliacion_firmado: {cod: 'BEN_EPS_HER_23_DISC_H_FORM_AFI_TRABJ', entity: 'eps', value: ''},
  }

  FILES_H_HUER_25_DISC_VALUES = {
    copia_registro_civil_beneficiario_trabajador: '',
    copia_documento_identidad_beneficiario: '',
    registro_civil_defuncion_padres: '',
    declaracion_juramentada_dependencia_economica_convivencia: '',
    certificado_discapacidad: '',
    certificado_afiliacion_eps: '',
    formulario_afiliacion_firmado: '',
  }

  private utilPopupService: UtilPopupService;
  private docsObject: any = {}
  private fb: FormBuilder
  isValid = false
  constructor(
    private fileS: FileService,
    private affiliationDocS: AfiliacionesDocumentoService
  ) {
    this.utilPopupService = inject(UtilPopupService);
    this.fb = inject(FormBuilder);

  }

  ngOnChanges(changes: SimpleChanges) {

    if (changes.codeType) {
      if (this.isEditing) {

        this.clearFormArray(this.documentos)
        this.resetValuesFilled(this.codeType)
        this.isValid = false
        this.eventSaveChanges.emit(false)
      }
    }
  }

  areValuesFilled(code: string){
    switch (code){
      case 'Pad':
        for(let key in this.FILES_PAD_VALUES){
          if(this.FILES_PAD_VALUES[key] === ''){
            return false
          }
        }
        return true
      case 'Con':
        for(let key in this.FILES_CON_VALUES){
          if(this.FILES_CON_VALUES[key] === ''){
            return false
          }
        }
        return true
      case 'Com':
        for(let key in this.FILES_COM_VALUES){
          if(this.FILES_COM_VALUES[key] === ''){
            return false
          }
        }
        return true
      case 'Ben_cus':
        for (let key in this.FILES_CUS_VALUES) {
          if(this.FILES_CUS_VALUES[key] === ''){
            return false
          }
        }
        return true
      case 'Hij_men_7':
        for (let key in this.FILES_HIJ_7_VALUES) {
          if(this.FILES_HIJ_7_VALUES[key] === ''){
            return false
          }
        }
        return true
      case 'Hij_men_18':
        for (let key in this.FILES_HIJ_7_18_VALUES) {
          if(this.FILES_HIJ_7_18_VALUES[key] === ''){
            return false
          }
        }
        return true
      case 'Hij_men_23':
        for (let key in this.FILES_HIJ_18_23_VALUES) {
          if(this.FILES_HIJ_18_23_VALUES[key] === ''){
            return false
          }
        }
        return true
      case 'Hij_men_25':
        for (let key in this.FILES_HIJ_MEN_25_VALUES) {
          if(this.FILES_HIJ_MEN_25_VALUES[key] === ''){
            return false
          }
        }
        return true
      case 'Hij_a_18':
        for (let key in this.FILES_HIJ_A_18_VALUES) {
          if(this.FILES_HIJ_A_18_VALUES[key] === ''){
            return false
          }
        }
        return true
      case 'Hij_a_18_25':
        for (let key in this.FILES_HIJ_A_18_25_VALUES) {
          if(this.FILES_HIJ_A_18_25_VALUES[key] === ''){
            return false
          }
        }
        return true
      case 'Hij_25_disc':
        for (let key in this.FILES_HIJ_25_DISC) {
          if(this.FILES_HIJ_25_DISC[key] === ''){
            return false
          }
        }
        return true
      case 'h_huer_18':
        for (let key in this.FILES_H_HUER_18_VALUES) {
          if(this.FILES_H_HUER_18_VALUES[key] === ''){
            return false
          }
        }
        return true
      case 'h_men_23':
        for (let key in this.FILES_HER_23_VALUES) {
          if(this.FILES_HER_23_VALUES[key] === ''){
            return false
          }
        }
        return true
      case 'h_huer_25':
        for (let key in this.FILES_H_HUER_25_VALUES) {
          if(this.FILES_H_HUER_25_VALUES[key] === ''){
            return false
          }
        }
        return true
      case 'h_huer_25_disc':
        for (let key in this.FILES_H_HUER_25_DISC_VALUES) {
          if(this.FILES_H_HUER_25_DISC_VALUES[key] === ''){
            return false
          }
        }
        return true
    }
  }

  private resetValuesFilled(code: string){
    switch (code){
      case 'Pad':
        for(let key in this.FILES_PAD_VALUES){
          this.FILES_PAD_VALUES[key] = ''
        }
        break
      case 'Con':
        for(let key in this.FILES_CON_VALUES){
          this.FILES_CON_VALUES[key] = ''
        }
        break
      case 'Com':
        for(let key in this.FILES_COM_VALUES){
          this.FILES_COM_VALUES[key] = ''
        }
        break
      case 'Ben_cus':
        for (let key in this.FILES_CUS_VALUES) {
          this.FILES_CUS_VALUES[key] = ''
        }
        break
      case 'Hij_men_7':
        for (let key in this.FILES_HIJ_7_VALUES) {
          this.FILES_HIJ_7_VALUES[key] = ''
        }
        break
      case 'Hij_men_18':
        for (let key in this.FILES_HIJ_7_18_VALUES) {
          this.FILES_HIJ_7_18_VALUES[key] = ''
        }
        break
      case 'Hij_men_23':
        for (let key in this.FILES_HIJ_18_23_VALUES) {
          this.FILES_HIJ_18_23_VALUES[key] = ''
        }
        break
      case 'Hij_men_25':
        for (let key in this.FILES_HIJ_MEN_25_VALUES) {
          this.FILES_HIJ_MEN_25_VALUES[key] = ''
        }
        break
      case 'Hij_a_18':
        for (let key in this.FILES_HIJ_A_18_VALUES) {
          this.FILES_HIJ_A_18_VALUES[key] = ''
        }
        break
      case 'Hij_a_18_25':
        for (let key in this.FILES_HIJ_A_18_25_VALUES) {
          this.FILES_HIJ_A_18_25_VALUES[key] = ''
        }
        break
      case 'Hij_25_disc':
        for (let key in this.FILES_HIJ_25_DISC) {
          this.FILES_HIJ_25_DISC[key] = ''
        }
        break
      case 'h_huer_18':
        for (let key in this.FILES_H_HUER_18_VALUES) {
          this.FILES_H_HUER_18_VALUES[key] = ''
        }
        break
      case 'h_men_23':
        for (let key in this.FILES_HER_23_VALUES) {
          this.FILES_HER_23_VALUES[key] = ''
        }
        break
      case 'h_huer_25':
        for (let key in this.FILES_H_HUER_25_VALUES) {
          this.FILES_H_HUER_25_VALUES[key] = ''
        }
        break
      case 'h_huer_25_disc':
        for (let key in this.FILES_H_HUER_25_DISC_VALUES) {
          this.FILES_H_HUER_25_DISC_VALUES[key] = ''
        }
        break
    }
  }

  async ngOnInit() {
    this.docsObject = await this.getObjectDocs()
  }

  private clearFormArray = (formArray: FormArray) => {
    while (formArray.length !== 0) {
      formArray.removeAt(0)
    }
  }

  private async getObjectDocs() {
    let retObj = {}
    await this.affiliationDocS.getListaPersonaDocumentos().toPromise().then(data => {
      data.forEach(doc => {
        retObj[doc.codigo] = doc.id
      })
    }).catch(error => {
      this.utilPopupService.mostrarMensaje(`Error al consoltar servicio persona documentos: ${error.descripcion}`, 'error', 'Error', false)
      console.log(error)
    })

    return retObj
  }

  private updateCreateDocFormGroup(obj: {
    base64: string,
    extension: string
  }, index: number, docId: number) {
    if (index !== -1) {

      this.documentos.at(index).patchValue({
        base64: obj.base64,
        extension: obj.extension,
      })
    } else if (index === -1) {

      this.documentos.push(
        this.fb.group({
          base64: [obj.base64],
          extension: [obj.extension],
          id_persona_documento: [docId],
        })
      )
    }
  }

  openFile1(fileObj: any = {}, containerValue: any = {}, valueCod: string = '',) {
    this.fileS.openFile().subscribe(obj => {
      containerValue[valueCod] = obj.name

      const indexToPush = this.getDocumentIndex(this.documentos, this.docsObject[fileObj.cod])

      this.updateCreateDocFormGroup(obj, indexToPush, this.docsObject[fileObj.cod])
    }, (_error) => {
      this.utilPopupService.mostrarMensaje(`Error al cargar el archivo: ${_error}`, 'error', 'Error', false)
    })
  }


  openFile(fileObj: any = {}, containerValue: any = {}, valueCod: string = '') {
    this.fileS.openFile().subscribe(
      (obj) => {
        const file = obj[0]?.file || obj;
        if (!this.isPDF(file)) {
          this.utilPopupService.mostrarMensaje('El tipo de archivo no es válido. Por favor, seleccione un archivo .PDF','error','Tipo de archivo no válido',false);
          return;
        }
        if (!this.isValidFileSize(file)) {
          this.utilPopupService.mostrarMensaje('El archivo excede el tamaño máximo permitido. Seleccione un archivo que pese menos de 2 megabytes','error','Tamaño de archivo no válido',false);
          return;
        }
        containerValue[valueCod] = obj.name;
        const indexToPush = this.getDocumentIndex(this.documentos, this.docsObject[fileObj.cod]);
        this.updateCreateDocFormGroup(obj, indexToPush, this.docsObject[fileObj.cod]);
      },
      (error) => {
        this.utilPopupService.mostrarMensaje(`Error al cargar el archivo: ${error}`, 'error', 'Error', false);
      }
    );
  }
  


  openFileByEntity(entity: string, objCCF: any = {}, objEPS: any = {}, containerValue: any = {}, valueCod: string = '') {
    this.fileS.openFile().subscribe(
      (obj) => {
        const file = obj[0]?.file || obj;
        if (!this.isPDF(file)) {
          this.utilPopupService.mostrarMensaje('El tipo de archivo no es válido. Por favor, seleccione un archivo .PDF','error','Tipo de archivo no válido',false);
          return;
        }
        if (!this.isValidFileSize(file)) {
          this.utilPopupService.mostrarMensaje('El archivo excede el tamaño máximo permitido. Seleccione un archivo que pese menos de 2 megabytes','error','Tamaño de archivo no válido',false);
          return;
        }
      containerValue[valueCod] = obj.name
      if (this.entity === TipoEntidadType.EPS || this.entity === TipoEntidadType.ALL) {
        const indexToPushEPS = this.getDocumentIndex(this.documentos, this.docsObject[objEPS.cod])

        this.updateCreateDocFormGroup(obj, indexToPushEPS, this.docsObject[objEPS.cod])
      }
      if (this.entity === TipoEntidadType.CCF || this.entity === TipoEntidadType.ALL) {
        const indexToPushCCF = this.getDocumentIndex(this.documentos, this.docsObject[objCCF.cod])

        this.updateCreateDocFormGroup(obj, indexToPushCCF, this.docsObject[objCCF.cod])
      }
    }, (_error) => {
      this.utilPopupService.mostrarMensaje(`Error al cargar el archivo: ${_error}`, 'error', 'Error', false)
    })
  }


  isPDF(file: File): boolean {
    const extension = file.name.split('.').pop()?.toLowerCase();
    return extension === 'pdf';
  }

  isValidFileSize(obj: any): boolean {
    const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
    const base64Size = obj.base64.length * (3 / 4) - (obj.base64.indexOf('=') > 0 ? 4 - obj.base64.indexOf('=') : 0);
    return base64Size <= maxSize;
}




  // addFile(nombreDocumentoBase: string, data: { name: string, base64: string }) {
  //     let values = this.documentos.value as [{ [key: string]: string }]
  //     const idxAgregarDocumento = this.indexToAdd(values, nombreDocumentoBase)
  //     if (idxAgregarDocumento !== -1) {
  //         values[idxAgregarDocumento] = {[nombreDocumentoBase]: data.base64}
  //     } else {
  //         values.push({[nombreDocumentoBase]: data.base64})
  //     }
  // }
  //
  // indexToAdd(arr: any[], s: string): number {
  //     return arr.findIndex(obj => obj.hasOwnProperty(s))
  // }

  getDocumentIndex(array: FormArray, id: number) {
    return array.getRawValue().findIndex((document: any) => {
      return document.id_persona_documento === id
    })
  }

  saveChanges(){
    this.isValid = true
    this.eventSaveChanges.emit(true)
  }

}
