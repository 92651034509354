<form [formGroup]="form">
    <div class="px-1/4 grid gap-4 grid-cols-1 content-center sm:grid-cols-1 lg:grid-cols-3">
        <mat-form-field class="w-full">
            <mat-label>Tipo de cotizante</mat-label>
            <mat-select formControlName="codigo_tipo_cotizante" placeholder="Selecciona">
                <mat-option *ngFor="let food of parametrosService.tipoCotizante$|async" [value]="food.cod">
                    {{food.valor}}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Documento de identidad</mat-label>
            <input matInput type="text" placeholder="Subir documento" [value]="documentoIdentidadBase" disabled>
            <mat-icon svgIcon="upload" matSuffix (click)="openFile()"></mat-icon>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Estado</mat-label>
            <input matInput type="text" [value]="'Pendiente'" placeholder="Estado" readonly>
        </mat-form-field>
    </div>

    <mat-accordion>
        <mat-expansion-panel *ngFor="let type of containers; index as idx" >

            <mat-expansion-panel-header [collapsedHeight]="'69px'" [expandedHeight]="'69px'" *ngIf="mostrarAfp(type.label)">
                <mat-panel-title>
                    <div class="bg-primary p-1 rounded-full flex justify-center items-center mr-1">
                        <mat-icon
                            class="icon-size-4 text-white ml-auto"
                        >{{type.icon}}</mat-icon>
                    </div>

                    Información {{type.label}}
                </mat-panel-title>
                <mat-panel-description>
                    <mat-icon
                        class="icon-size-5 text-primary ml-auto hover:scale-125"
                        [svgIcon]="'heroicons_solid:trash'" (click)="clearStep($event, type.cod, idx)"></mat-icon>
                </mat-panel-description>
                <div class="absolute -top-0.5 left-0 z-10 h-3 w-full"
                     [ngClass]="{'bg-gray-300': !acceptedEntity[idx], 'bg-primary': acceptedEntity[idx]}">
                </div>
            </mat-expansion-panel-header>
            <div class="px-1/4 grid gap-4 grid-cols-1 content-center sm:grid-cols-1 lg:grid-cols-2" *ngIf="mostrarAfp(type.label)">
                <mat-form-field>
                    <mat-label>Fecha maxima de afiliacion efectiva por entidad</mat-label>
                    <input matInput type="text"
                           [ngModel]="successAffiliationDate[type.label]"
                           [ngModelOptions]="{standalone: true}"
                           placeholder="AAAA/MM/DD" readonly>
                </mat-form-field>

                <mat-form-field class="w-full">
                    <mat-label>Tipo de novedad {{type.label}}</mat-label>
                    <mat-select [formControlName]="'codigo_tipo_novedad_'+type.cod" placeholder="Selecciona">
                        <mat-option *ngFor="let food of noveltyList[type.label]|async" [value]="food.cod">
                            {{food.valor}}
                        </mat-option>
                    </mat-select>
                    <mat-error matErrorMessages></mat-error>
                </mat-form-field>

                <mat-form-field class="w-full" *ngIf="form.get('codigo_tipo_novedad_'+type.cod).value">
                    <mat-label>Entidad {{type.label}}</mat-label>
                    <mat-select [formControlName]="'id_entidad_'+type.cod" placeholder="Selecciona"
                                (selectionChange)="handleEntityChange(type.label)">
                        <mat-option *ngFor="let food of entitiesLists[type.label]|async" [value]="food.id_entidad_tipoentidad">
                            {{food.nombre_entidad}}
                        </mat-option>
                    </mat-select>
                    <mat-error matErrorMessages></mat-error>
                </mat-form-field>

                <div class="col-span-full grid gap-4 grid-cols-1 content-center sm:grid-cols-1 lg:grid-cols-1">
                    <button *ngIf="!acceptedEntity[idx]; else elseValidBlock"
                            [disabled]="!form.get('codigo_tipo_novedad_'+type.cod).valid || !form.get('id_entidad_'+type.cod).valid"
                            mat-stroked-button color="primary"
                            (click)="acceptedEntity[idx] = true">Guardar cambios
                    </button>
                    <ng-template #elseValidBlock>
                        <button mat-raised-button class="bg-green text-white">Validado</button>
                    </ng-template>
                </div>
            </div>
        </mat-expansion-panel>
    </mat-accordion>
    <div *ngIf="entity === TipoEntidadType.EPS || entity === TipoEntidadType.CCF || entity === TipoEntidadType.ALL"
         class="w-full flex justify-center mt-5">
        <mat-checkbox class="example-margin pb-5" (click)="beneficiariosClicked($event)" [checked]="tieneBeneficiarios">
            <span class="text-3xl font-bold">¿Tiene beneficiarios?</span>
        </mat-checkbox>
    </div>

</form>



