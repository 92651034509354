import { Component, inject, Input, SimpleChanges } from '@angular/core';
import { ContainerDataModel } from '@shared/interfaces/affiliations.interface';
import {
    AbstractControl,
    FormArray,
    FormBuilder,
    FormGroup,
    Validators,
} from '@angular/forms';
import { customeValidations } from '@utils/validations/customeValidations';
import { TipoEntidadType } from '@shared/enums/tipo-afiliacion.enum';
import { momentService } from '@utils/functions/momentService';
import { forkJoin } from 'rxjs';
import { DepartmentService } from '@shared/services/department/department.service';
import { ParameterService } from '@shared/services/parameter/parameter.service';
import { AfiliacionService } from '@shared/services/afiliacion/afiliacion.service';
import { DepartmentInterface } from '@shared/interfaces/Department.interface';
import { ResultResponseInterface } from '@shared/interfaces/result-response.interface';
import { ParameterInterface } from '@shared/interfaces/Parameter.interface';
import { AfiliacionesBaseFunctions } from '@shared/functions/afiliaciones/afiliaciones-base.functions';
import Swal from 'sweetalert2';
import { UtilPopupService } from '@shared/services/util-popup.service';
import { LoadingBackdropService } from '@shared/services/loading-backdrop.service';
import { MatDialog } from '@angular/material/dialog';
import { AfiliacionFormGeneradoModalComponent } from '@shared/components/base-affiliation-container/components/afiliacion-form-generado-modal/afiliacion-form-generado-modal.component';

@Component({
    selector: 'app-base-affiliation-container',
    templateUrl: './base-affiliation-container.component.html',
    styleUrls: ['./base-affiliation-container.component.scss'],
})
export class BaseAffiliationContainerComponent {
    @Input() containerData!: ContainerDataModel[];
    @Input() isEditable: boolean = false;
    @Input() type: string = TipoEntidadType.ALL;
    @Input() haveBeneficiaries: boolean = false;
    @Input() isNew: boolean = true;
    private fb: FormBuilder;
    baseForm: FormGroup;
    beneficiariesCount = 1;
    departmentsList: DepartmentInterface[];
    parameters: ResultResponseInterface<ParameterInterface[]>;
    parametersData: {};
    acceptedSteps = [];
    private utilPopupService: UtilPopupService;
    beneficiariesValidity: Array<boolean> = [];
    private dataResultCreateAfiliacion: any;

    fields = {
        company: [],
        employee: [],
        affiliation: [],
        beneficiaries: [],
    };

    ngOnChanges(changes: SimpleChanges) {
        const newContainerData = changes.containerData?.currentValue;

        if (!Array.isArray(newContainerData)) {
            console.warn(
                'BaseAffiliationContainerComponent: El input containerData no es un arreglo.'
            );
        }

        for (const item of newContainerData) {
            if (
                typeof item.title !== 'string' ||
                typeof item.formControl !== 'string'
            ) {
                console.warn(
                    'BaseAffiliationContainerComponent: Uno o más elementos en containerData no cumplen con la estructura de ContainerDataModel.'
                );
            }
        }
    }

    constructor(
        private momentS: momentService,
        private departmentS: DepartmentService,
        private parameterS: ParameterService,
        private afiliacionS: AfiliacionService,
        private afiliacionFunctions: AfiliacionesBaseFunctions,
        private _loadingService: LoadingBackdropService,
        private dialog: MatDialog
    ) {
        this.fb = inject(FormBuilder);
        this.utilPopupService = inject(UtilPopupService);
    }

    ngOnInit() {
        // this.initBaseForm()
        switch (this.type) {
            case TipoEntidadType.EPS:
                this.baseForm = this.fb.group({
                    datos_empresa: this.buildFormCompanyData(),
                    datos_empleado: this.buildFormEpsEmployeeData(),
                    informacion_afiliacion: this.buildFormEPSAffiliationData(),
                    beneficiarios: this.fb.array([]),
                });
                // console.log(this.baseForm)

                break;
            case TipoEntidadType.CCF:
                this.baseForm = this.fb.group({
                    datos_empresa: this.buildFormCompanyData(),
                    datos_empleado: this.buildFormCCFEmployeeData(),
                    informacion_afiliacion: this.buildFormCCFAffiliationData(),
                    beneficiarios: this.fb.array([]),
                });
                break;
            case TipoEntidadType.AFP:
                this.baseForm = this.fb.group({
                    datos_empresa: this.buildFormCompanyData(),
                    datos_empleado: this.buildFormAFPEmployeeData(),
                    informacion_afiliacion: this.buildFormAFPAffiliationData(),
                    beneficiarios: this.fb.array([]),
                });
                break;
            case TipoEntidadType.ARL:
                this.baseForm = this.fb.group({
                    datos_empresa: this.buildFormCompanyData(),
                    datos_empleado: this.buildFormARLEmployeeData(),
                    informacion_afiliacion: this.buildFormARLAffiliationData(),
                    beneficiarios: this.fb.array([]),
                });
                break;
            case TipoEntidadType.ALL:
                this.baseForm = this.buildFormFUAM();
                break;
            default:
                break;
        }

        const requestGetDepartaments = this.departmentS.getDepartments();

        forkJoin([requestGetDepartaments]).subscribe(
            ([response1]) => {
                this.departmentsList = response1;
            },
            (error) => {
                console.log(error);
            },
            () => {
                this.parametersData = {};
            }
        );

        this.acceptedSteps = new Array(this.containerData.length).fill(false);
        this.containerData.forEach((item, index) => {
            this.baseForm
                .get(item.formControl)
                .statusChanges.subscribe((status) => {
                    if (
                        this.acceptedSteps[index] === true &&
                        status === 'INVALID'
                    ) {
                        this.acceptedSteps[index] = false;
                    }
                });
        });
    }

    addBeneficiariesFormArray() {
        (this.baseForm.get('beneficiarios') as FormArray)?.push(
            this.builBeneficiariesForm()
        );
    }

    removeBeneficiariesFormArrayByIndex(index: number) {
        (this.baseForm.get('beneficiarios') as FormArray)?.removeAt(index);
    }

    /*All the entities and FUA MANUAL have the same controls in the datos_empresa form group, so thats it there is an unique
     * function to generate the form group if one day one of them change, we should create specific functions to each type
     * of form for example buildFormEPSCompanyData and the others*/
    buildFormCompanyData() {
        return this.fb.group({
            id: ['', [Validators.required]],
            nit_empleador: [''],
            nivel_1: ['', [Validators.required]],
            nivel_2: [''],
            nivel_3: [''],
            nivel_4: [''],
            tipo_contrato: ['', [Validators.required]],
            fecha_ingreso: ['', [Validators.required]],
            departamento_trabajo: ['', [Validators.required]],
            centro_trabajo: ['', [Validators.required]],
            clase_riesgo: ['', [Validators.required]],
        });
    }

    getBaseFormEmployeeData() {
        const form =  this.fb.group({
            primer_apellido: [
                '',
                [
                    Validators.required,
                    Validators.minLength(3),
                    customeValidations.maxLength(36),
                    customeValidations.onlyText,
                ],
            ],
            segundo_apellido: [
                '',
                [
                    Validators.required,
                    customeValidations.maxLength(36),
                    customeValidations.onlyText,
                ],
            ],
            primer_nombre: [
                '',
                [
                    Validators.required,
                    customeValidations.maxLength(36),
                    customeValidations.onlyText,
                ],
            ],
            segundo_nombre: [
                '',
                [
                    Validators.required,
                    customeValidations.maxLength(36),
                    customeValidations.onlyText,
                ],
            ],
            fecha_nacimiento: [
                '',
                [
                    Validators.required,
                    customeValidations.maxYears(100, 'Edad'),
                    customeValidations.minYears(18, 'Edad'),
                ],
            ],
            codigo_sexo: ['', [Validators.required]],
            codigo_tipo_identificacion: ['', [Validators.required],],
            numero_identificacion: [
                '',
                [Validators.required,],
            ],
            fecha_expedicion_documento: [
                '',
                [
                    Validators.required,
                    customeValidations.maxYears(100, 'Fecha'),
                ],
            ],
            id_depto_expedicion_documento: ['', [Validators.required]],
            id_mpio_expedicion_documento: ['', [Validators.required]],
            id_ciudad_expedicion_documento: ['', [Validators.required]],
            id_depto_residencia: ['', [Validators.required]],
            id_mpio_residencia: ['', [Validators.required]],
            id_ciudad_residencia: ['', [Validators.required]],
            barrio_residencia: ['', [Validators.required]],
        }) as FormGroup;


        this.validacionDinamicaPorTipoIdentificacion(
            form.get('codigo_tipo_identificacion'),
            form.get('numero_identificacion')
        );

        return form;
    }

    buildFormEpsEmployeeData() {
        const epsForm = this.fb.group({
            correo: ['', [Validators.required, Validators.email]],
            numero_movil: ['', [Validators.required, Validators.maxLength(10), customeValidations.onlyNumbers]],
            telefono_fijo: ['', [Validators.required, Validators.maxLength(7), customeValidations.onlyNumbers]],
            codigo_discapacidad: ['NA', [Validators.required]],
            codigo_condicion: ['NA', [Validators.required]],
            emplea_lenguaje_especial: ['No', [Validators.required]],
            codigo_etnia: ['NA', [Validators.required]],
            factor_vulnerabilidad: ['NA'],
            base_cotizacion: ['', [Validators.required, customeValidations.onlyNumbers]],
            cargo: [''],
            codigo_modalidad_trabajo: ['', [Validators.required]],
            direccion_laboral: [''],
            entidad_afp: ['', [Validators.required]],
            entidad_arl: ['', [Validators.required]],
        });

        const baseForm = this.getBaseFormEmployeeData();

        return this.fb.group(
            Object.assign({}, baseForm.controls, epsForm.controls)
        );
    }

    buildFormCCFEmployeeData() {
        const ccfForm = this.fb.group({
            correo: ['', [Validators.required, Validators.email]],
            numero_movil: ['', [Validators.required, Validators.maxLength(10), customeValidations.onlyNumbers]],
            telefono_fijo: ['', [Validators.required, Validators.maxLength(7), customeValidations.onlyNumbers]],
            codigo_estado_civil: ['', [Validators.required]],
            codigo_discapacidad: ['NA', [Validators.required]],
            codigo_condicion: ['NA', [Validators.required]],
            emplea_lenguaje_especial: ['No', [Validators.required]],
            factor_vulnerabilidad: ['NA'],
            cabeza_hogar: ['No'],
            vivienda_propia: ['No'],
            sector_agropecuario: ['No'],
            codigo_nivel_educativo: ['', [Validators.required]],
            codigo_nivel_ocupacional: ['', [Validators.required]],
            base_cotizacion: ['', [Validators.required, customeValidations.onlyNumbers]],
            cargo: [''],
            codigo_modalidad_trabajo: ['', [Validators.required]],
            direccion_laboral: [''],
            entidad_eps: ['', [Validators.required]],
        });

        const baseForm = this.getBaseFormEmployeeData();

        return this.fb.group(
            Object.assign({}, baseForm.controls, ccfForm.controls)
        );
    }

    buildFormARLEmployeeData() {
        const arlForm = this.fb.group({
            base_cotizacion: ['', [Validators.required, customeValidations.onlyNumbers]],
            cargo: [''],
            codigo_modalidad_trabajo: ['', [Validators.required]],
            entidad_afp: ['', [Validators.required]],
            entidad_eps: ['', [Validators.required]],
            estado: ['Pendiente'],
        });

        const baseForm = this.getBaseFormEmployeeData();

        return this.fb.group(
            Object.assign({}, baseForm.controls, arlForm.controls)
        );
    }

    buildFormAFPEmployeeData() {
        const form =  this.fb.group({
            primer_apellido: [
                '',
                [
                    Validators.required,
                    Validators.minLength(3),
                    customeValidations.maxLength(36),
                    customeValidations.onlyText,
                ],
            ],
            segundo_apellido: [
                '',
                [
                    Validators.required,
                    customeValidations.maxLength(36),
                    customeValidations.onlyText,
                ],
            ],
            primer_nombre: [
                '',
                [
                    Validators.required,
                    customeValidations.maxLength(36),
                    customeValidations.onlyText,
                ],
            ],
            segundo_nombre: [
                '',
                [
                    customeValidations.maxLength(36),
                    customeValidations.onlyText,
                ],
            ],
            fecha_nacimiento: [
                '',
                [
                    Validators.required,
                    customeValidations.maxYears(100, 'Edad'),
                    customeValidations.minYears(18, 'Edad'),
                ],
            ],
            codigo_sexo: ['', [Validators.required]],
            codigo_tipo_identificacion: ['', [Validators.required]],
            numero_identificacion: [
                '',
                [Validators.required, ],
            ],
            fecha_expedicion_documento: [
                '',
                [
                    Validators.required,
                    customeValidations.maxYears(100, 'Fecha'),
                ],
            ],
            id_depto_expedicion_documento: ['', [Validators.required]],
            id_mpio_expedicion_documento: ['', [Validators.required]],
            id_ciudad_expedicion_documento: ['', [Validators.required]],
            id_depto_residencia: ['', [Validators.required]],
            id_mpio_residencia: ['', [Validators.required]],
            id_ciudad_residencia: ['', [Validators.required]],
            barrio_residencia: ['', [Validators.required]],

            base_cotizacion: ['', [Validators.required, customeValidations.onlyNumbers]],
            cargo: [''],
            codigo_modalidad_trabajo: ['', [Validators.required]],
            entidad_arl: ['', [Validators.required]],
            entidad_eps: ['', [Validators.required]],
            estado: ['Pendiente'],
        });

        this.validacionDinamicaPorTipoIdentificacion(
            form.get('codigo_tipo_identificacion'),
            form.get('numero_identificacion')
        );

        return form;
    }

    /* Affiliation Data have 2 additional controls called 'fecha maxima de afiliacion efectiva por entidad' and 'estado'
     * These are obtained by request to the backend so its only rendered in the view but is not necessary to pass it to
     * the backend at make the query.
     * */

    buildFormEPSAffiliationData() {
        return this.fb.group({
            codigo_tipo_cotizante: ['', [Validators.required]],
            id_entidad_eps: ['', [Validators.required]],
            codigo_tipo_novedad_eps: ['', [Validators.required]],
            tiene_beneficiarios: [false, [Validators.required]],
            documentos: this.fb.array([]),
        });
    }

    buildFormCCFAffiliationData() {
        return this.fb.group({
            codigo_tipo_cotizante: ['', [Validators.required]],
            id_entidad_ccf: ['', [Validators.required]],
            codigo_tipo_novedad_ccf: ['', [Validators.required]],
            tiene_beneficiarios: [false, [Validators.required]],
            documentos: this.fb.array([]),
        });
    }

    buildFormARLAffiliationData() {
        return this.fb.group({
            codigo_tipo_cotizante: ['', [Validators.required]],
            id_entidad_arl: ['', [Validators.required]],
            codigo_tipo_novedad_arl: ['', [Validators.required]],
            documentos: this.fb.array([]),
        });
    }

    buildFormAFPAffiliationData() {
        return this.fb.group({
            codigo_tipo_cotizante: ['', [Validators.required]],
            id_entidad_afp: ['', [Validators.required]],
            codigo_tipo_novedad_afp: ['', [Validators.required]],
            documentos: this.fb.array([]),
        });
    }

    /*All the entities that have beneficiaries and FUA MANUAL have the same controls in the beneficiarios form group, so that's it there is an unique
     * function to generate the form group if one day one of them change, we should create specific functions to each type
     * of form for example buildBeneficiariesEPSForm and the others*/
    builBeneficiariesForm() {
        this.beneficiariesValidity.push(false);
        const form  = this.fb.group({
            primer_apellido: [
                '',
                [
                    Validators.required,
                    Validators.minLength(3),
                    customeValidations.maxLength(36),
                    customeValidations.onlyText,
                ],
            ],
            segundo_apellido: [
                '',
                [
                    Validators.required,
                    customeValidations.maxLength(36),
                    customeValidations.onlyText,
                ],
            ],
            primer_nombre: [
                '',
                [
                    Validators.required,
                    customeValidations.maxLength(36),
                    customeValidations.onlyText,
                ],
            ],
            segundo_nombre: [
                '',
                [
                    customeValidations.maxLength(36),
                    customeValidations.onlyText,
                ],
            ],
            fecha_nacimiento: [
                '',
                [
                    Validators.required,
                    customeValidations.maxYears(100, 'Edad'),
                    customeValidations.minYears(0, 'Edad'),
                ],
            ],
            codigo_tipo_identificacion: ['', [Validators.required]],
            numero_identificacion: [
                '',
                [Validators.required],
            ],
            codigo_sexo: ['', [Validators.required]],
            codigo_discapacidad: ['NA', [Validators.required]],
            pertenece_a_eps: [false],
            pertenece_a_ccf: [false],
            codigo_parentesco: ['', [Validators.required]],
            codigo_tipo_parentesco: ['', [Validators.required]],
            documentos: this.fb.array([]),
        });

        this.validacionDinamicaPorTipoIdentificacion(
            form.get('codigo_tipo_identificacion'),
            form.get('numero_identificacion')
        );

        return form;
    }

    buildFormFUAM() {
        const form =  this.fb.group({
            datos_empresa: this.fb.group({
                id: [null, [Validators.required]],
                nit_empleador: [''],
                nivel_1: ['', [Validators.required]],
                nivel_2: [''],
                nivel_3: [''],
                nivel_4: [''],
                tipo_contrato: ['', [Validators.required]],
                fecha_ingreso: ['', [Validators.required]],
                clase_riesgo: ['', [Validators.required]],
                departamento_trabajo: ['', [Validators.required]],
                centro_trabajo: ['', [Validators.required]],
            }),
            datos_empleado: this.fb.group({
                datos_personales: this.fb.group({
                    primer_apellido: [
                        '',
                        [
                            Validators.required,
                            customeValidations.maxLength(36),
                            customeValidations.onlyText,
                        ],
                    ],
                    segundo_apellido: ['', [ customeValidations.onlyText]],
                    primer_nombre: [
                        '',
                        [
                            Validators.required,
                            customeValidations.maxLength(36),
                            customeValidations.onlyText,
                        ],
                    ],
                    segundo_nombre: [
                        '',
                        [
                            customeValidations.maxLength(36),
                            customeValidations.onlyText,
                        ],
                    ],
                    id_nacionalidad: ['', [Validators.required]],
                    codigo_tipo_identificacion: ['', [Validators.required]],
                    numero_identificacion: [
                        '',
                        [
                            Validators.required,
                        ],
                    ],
                    fecha_expedicion_documento: [
                        '',
                        [
                            Validators.required,
                            customeValidations.maxYears(100, 'Fecha'),
                        ],
                    ],
                    id_depto_expedicion_documento: ['', [Validators.required]],
                    id_mpio_expedicion_documento: ['', [Validators.required]],
                    id_ciudad_expedicion_documento: ['', [Validators.required]],
                    fecha_nacimiento: [
                        '',
                        [
                            Validators.required,
                            customeValidations.maxYears(100, 'Edad'),
                            customeValidations.minYears(18, 'Edad'),
                        ],
                    ],
                    id_depto_nacimiento: ['', [Validators.required]],
                    id_mpio_nacimiento: ['', [Validators.required]],
                    id_ciudad_nacimiento: ['', [Validators.required]],
                    id_depto_residencia: ['', [Validators.required]],
                    id_mpio_residencia: ['', [Validators.required]],
                    id_ciudad_residencia: ['', [Validators.required]],
                    barrio_residencia: ['', [Validators.required]],
                    codigo_tipo_direccion_residencia: [
                        '',
                        [Validators.required],
                    ],
                    direccion_residencia: ['', [Validators.required]],
                    codigo_sexo: ['', [Validators.required]],
                    codigo_estado_civil: ['', [Validators.required]],
                    correo: ['', [Validators.required, Validators.email]],
                    numero_movil: ['', [Validators.required, Validators.maxLength(10), customeValidations.onlyNumbers]],
                    telefono_fijo: ['', [Validators.required, Validators.maxLength(7), customeValidations.onlyNumbers]],
                    codigo_discapacidad: ['NA', [Validators.required]],
                    codigo_condicion: ['NA', [Validators.required]],
                    emplea_lenguaje_especial: ['No', [Validators.required]],
                    codigo_etnia: ['NA', [Validators.required]],
                    factor_vulnerabilidad: ['NA'],
                    cabeza_hogar: ['No'],
                    vivienda_propia: ['No'],
                    sector_agropecuario: ['No'],
                    codigo_nivel_educativo: ['', [Validators.required]],
                    afp_actual: [''],
                    eps_actual: [''],
                    arl_actual: [''],
                    ccf_actual: [''],
                }),
                datos_laborales: this.fb.group({
                    codigo_nivel_ocupacional: ['', [Validators.required]],
                    codigo_modalidad_trabajo: ['', [Validators.required]],
                    cargo: ['', [Validators.required]],
                    numero_contrato: ['', [Validators.required]],
                    base_cotizacion: ['', [Validators.required, customeValidations.onlyNumbers]],
                    direccion_laboral: [''],
                    codigo_jornada_laboral: [''],
                }),
                documentos: this.fb.array([]),
            }),
            informacion_afiliacion: this.fb.group({
                codigo_tipo_cotizante: ['', [Validators.required]],
                afiliacion_efectiva: [
                    this.momentS.formatDate(
                        this.momentS.addDays(new Date(), 1)
                    ),
                ],
                tipoCotizante: [''],
                tiempoRadicacion: [''],
                afiliadoAntesCcf: [''],
                entidadAcfAnterior: [''],
                estado: ['Pendiente'],
                id_entidad_eps: ['', [Validators.required]],
                codigo_tipo_novedad_eps: ['', [Validators.required]],
                id_entidad_arl: ['', [Validators.required]],
                codigo_tipo_novedad_arl: ['', [Validators.required]],
                id_entidad_afp: [],
                codigo_tipo_novedad_afp: [],
                id_entidad_ccf: ['', [Validators.required]],
                codigo_tipo_novedad_ccf: ['', [Validators.required]],
                fecha_ingreso: [
                    this.momentS.formatDate(
                        this.momentS.addDays(new Date(), 1)
                    ),
                    [Validators.required],
                ],
                documentos: this.fb.array([]),
            }),
            beneficiarios: this.fb.array([]),
        });

        this.validacionDinamicaPorTipoIdentificacion(
            form.get('datos_empleado.datos_personales.codigo_tipo_identificacion'),
            form.get('datos_empleado.datos_personales.numero_identificacion')
        );

        return form;
    }

    validacionDinamicaPorTipoIdentificacion(controlTipoIdentificacion:AbstractControl, controlNumeroIdentificacion:AbstractControl) {
        controlTipoIdentificacion.valueChanges.subscribe((value) => {

            const dinamicValidators = {
                RC: [customeValidations.onlyNumbers, Validators.maxLength(12)],
                CNV: [Validators.maxLength(12), customeValidations.specialCharacters],
                TI: [Validators.maxLength(12), customeValidations.onlyNumbers],
                CC: [Validators.maxLength(12), customeValidations.onlyNumbers],
                CE: [Validators.maxLength(12), customeValidations.onlyNumbers],
                P: [
                    Validators.maxLength(12),
                    customeValidations.specialCharacters,
                ],
                CD: [
                    Validators.maxLength(12),
                    customeValidations.specialCharacters,
                ],
                SP: [
                    Validators.maxLength(20),
                    customeValidations.specialCharacters,
                ],
                PPT: [
                    Validators.maxLength(15),
                    customeValidations.specialCharacters,
                ],
                PONU: [
                    Validators.maxLength(15),
                    customeValidations.specialCharacters,
                ],
            };

            const validators = dinamicValidators[value] || [];
            validators.push(Validators.required);


            controlNumeroIdentificacion.setValidators([...validators]);
            controlNumeroIdentificacion.updateValueAndValidity();
        });
    }

    getStepTitle(code: string) {
        switch (code) {
            case 'datos_empresa':
                return 'Datos de la empresa';
            case 'datos_empleado':
                return 'Datos del empleado';
            case 'informacion_afiliacion':
                return 'Información de afiliación';
            case 'beneficiarios':
                return 'Información de Beneficiarios';
        }
    }

    getFormControl(control: string): AbstractControl {
        return this.baseForm.get(control);
    }

    getFormControlAsFormGroup(control: string) {
        return this.baseForm.get(control) as FormGroup;
    }

    getFormControlAsFormArray(control: string) {
        return this.baseForm.get(control) as FormArray;
    }

    getFormControlByFormAsFormArray(control: string, form: FormGroup) {
        return form.get(control) as FormArray;
    }

    handleBeneficiariesCountChange(event: any) {
        const beneficiariesArray = this.getFormControl(
            'beneficiarios'
        ) as FormArray;
        const lengthBeneficiariesArray =
            beneficiariesArray.getRawValue().length;

        if (event.value > lengthBeneficiariesArray) {
            for (let i = lengthBeneficiariesArray; i < event.value; i++) {
                beneficiariesArray.push(this.builBeneficiariesForm());
            }
        } else if (event.value < lengthBeneficiariesArray) {
            for (let i = lengthBeneficiariesArray; i >= event.value; i--) {
                beneficiariesArray.removeAt(i);
                this.beneficiariesValidity.splice(i, 1);
            }
        }
    }

    haveBeneficiariesHandler() {
        this.haveBeneficiaries = !this.haveBeneficiaries;

        this.beneficiariesCount = 1;
        const beneficiariesArray = this.getFormControl(
            'beneficiarios'
        ) as FormArray;
        beneficiariesArray.clear();

        if (this.haveBeneficiaries) {
            beneficiariesArray.push(this.builBeneficiariesForm());
        }
    }

    //call to the crearAfiliacion ep it show error message if there is any error, otherwise the response should be an pop up
    //with a success afilliation message
    saveAffiliation() {
        this._loadingService.show();
        this.afiliacionS
            .crearAfiliacion(
                this.afiliacionFunctions
                    .getBodyToRequest(this.baseForm, this.type)
                    .getRawValue()
            )
            .subscribe(
                (data) => {
                    this.dataResultCreateAfiliacion = data;

                    this.generarAfiliacion(data.objeto.id);

                    // the next line was removed of the sweet alert as solution to issue 7 at 2/11/2023,
                    // add it if is necessaru
                    // <button id="btnVerEstado" style="background-color: #fff; width: 379px; height: 30px; color: #6523FF">Ver estado de afiliación</button>
                },
                async (_error) => {
                    this._loadingService.hide();
                    console.log(_error);
                    await this.utilPopupService.mostrarMensaje(
                        _error.error.descripcion,
                        'error',
                        '',
                        false
                    );
                }
            );
    }

    onCompanySelected(value: any) {
        const formEmployee = this.getFormControlAsFormGroup('datos_empleado');
        formEmployee.get('direccion_laboral')?.setValue(value);
        (formEmployee.get('datos_laborales') as FormGroup)
            ?.get('direccion_laboral')
            ?.setValue(value);
    }

    handleAtDelete(idx: any) {
        this.getFormControlAsFormArray('beneficiarios').removeAt(idx);
        this.beneficiariesCount = this.beneficiariesCount - 1;
        this.beneficiariesValidity.splice(idx, 1);
    }

    isBeneficiariesValid() {
        return this.beneficiariesValidity.every((item) => item === true);
    }

    handleUpdateValidityBeneficiaries(event: {
        idx: number;
        valid: Array<boolean>;
    }) {
        this.beneficiariesValidity[event.idx] = event.valid.every(
            (item) => item === true
        );

        const validAux = this.isBeneficiariesValid();
        if (!validAux) {
            this.acceptedSteps[this.acceptedSteps.length - 1] = false;
        }
    }

    protected readonly TipoEntidadType = TipoEntidadType;

    generarAfiliacion(idAfiliacion: number) {
        this._loadingService.hide();
        this.loadDocuments();
    }

    loadDocuments() {
        const dialogRef = this.dialog.open(
            AfiliacionFormGeneradoModalComponent,
            {
                height: '85%',
                width: '75%',
                disableClose: true,
            }
        );
        dialogRef.componentInstance.afiliacionId =
            this.dataResultCreateAfiliacion.objeto.id;
        dialogRef.componentInstance.correo =  this.baseForm.get('datos_empleado').get('datos_personales').get('correo').value;
        dialogRef.componentInstance.getDocuments();
        dialogRef.componentInstance.onSaveAffiliation.subscribe(
            (data: string) => {
                dialogRef.close();
                this.mostrarAlertaExitoCreacion();
            }
        );
    }

    private mostrarAlertaExitoCreacion() {
        const data = this.dataResultCreateAfiliacion;
        Swal.fire({
            html: `
                  <div style="font-family: 'roboto'; margin-bottom:22px; color: #D9FF00; margin-top: 41px; font-size: 36px">Afiliación exitosa</div>
                  <div style="display: flex; justify-content: center">
                    <p id='labelNombreAfiliacion' style="font-size: 20px; font-weight: 400; color:#fff; margin-right: 8px">Nombre de la afiliación:</p>
                    <input id="inputAfiliacion" type="text" style="border: 1px solid white; width: 201px; height: 21px; margin-right: 21px; color: #D9FF00; font-weight: 700; font-size: 12px; text-align: center" value="${data.objeto.identificador}"/>
                    <p style="font-size: 20px; font-weight: 400; color:#fff; margin-right: 8px">Fecha:</p>
                    <input type="text" style="border: 1px solid white; width: 102px; height: 21px; margin-right: 21px; color: #D9FF00; font-weight: 700; font-size: 12px; text-align: center" value="${data.objeto.fecha}" disabled/>
                    <p style="font-size: 20px; font-weight: 400; color:#fff; margin-right: 8px">Hora:</p>
                    <input type="text" style="border: 1px solid white; width: 102px; height: 21px; margin-right: 21px; color: #D9FF00; font-weight: 700; font-size: 12px; text-align: center" value="${data.objeto.hora}" disabled/>
                  </div>
                  <div id="containerConfirmacion" style="margin-top: 40px; margin-bottom: 40px; display: flex; justify-content: center"></div>
                  <div id="containerConfirmacion" style="margin-top: 40px; margin-bottom: 40px; display: flex; justify-content: center">
                  <p style="font-size: 20px; font-weight: 400; color: #fff; text-align: center; width: 70%">Su afiliación ha sido enviada con éxito, visite nuestra sección de estado de afiliaciones, para visualizar el estatus y descargar documentos correspondientes de la afiliación.</p>
                  </div>
                  <div id="containerOpciones" style="margin-top: 40px; margin-bottom: 40px; display: flex; flex-direction: column; align-items: center; gap: 8px">
                  <button id="btnOtraAfiliacion" style="background-color: #fff; width: 379px; height: 30px; color: #000">Hacer otra afiliación</button>
                  </div>
                  `,
            width: '60%',
            background: '#6523ff',
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            willClose: () => {
                location.reload();
            },
        }).then((result) => {
            if (
                result.dismiss === Swal.DismissReason.esc ||
                result.dismiss === Swal.DismissReason.backdrop
            ) {
                return; // Si se cancela o se cierra el modal, no se realiza ninguna acción adicional
            }
        });

        // the next lines were removed as solution to issue 7 at 2/11/2023,
        // const buttonVerEstado = document.getElementById('btnVerEstado');
        // buttonVerEstado.addEventListener('click', () => {
        //   console.log('Ver estado clicked');
        // });
        const buttonOtraAfiliacion =
            document.getElementById('btnOtraAfiliacion');

        buttonOtraAfiliacion.addEventListener('click', () => {
            location.reload();
        });
    }
}
