import {
    Component,
    EventEmitter,
    Input,
    Output,
    SimpleChanges,
} from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { DepartmentInterface } from '@shared/interfaces/Department.interface';

@Component({
    selector: 'app-beneficiaries-container',
    templateUrl: './beneficiaries-container.component.html',
    styleUrls: ['./beneficiaries-container.component.scss'],
})
export class BeneficiariesContainerComponent {
    @Input() form!: FormArray;
    @Input() parametros!: Object;
    @Input() amount!: number;
    @Input() departamentos: DepartmentInterface[];
    @Input() entity!: string;
    @Output() atDelete: EventEmitter<any> = new EventEmitter<any>();
    @Output() updateValidity: EventEmitter<{
        idx: number;
        valid: Array<boolean>;
    }> = new EventEmitter<{ idx: number; valid: Array<boolean> }>();

    amountArray: Array<any>;
    codeType: string[] = [];
    acceptedStep: Array<boolean>;
    beneficiariesValidity: any;
    isByDelete = false;
    indexDeleted = -1;

    ngOnInit() {
        this.amountArray = Array(this.amount).fill(false);
        this.codeType = Array(this.amount).fill('');
        this.acceptedStep = Array(this.amount).fill(false);
        this.beneficiariesValidity = Array(this.amount).fill([false, false]);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.amount) {
            if (this.isByDelete) {
                this.amountArray.splice(this.indexDeleted, 1);
                this.codeType.splice(this.indexDeleted, 1);
                this.acceptedStep.splice(this.indexDeleted, 1);
                this.beneficiariesValidity.splice(this.indexDeleted, 1);
                this.isByDelete = false;
                this.indexDeleted = -1;
            } else {
                if (
                    changes.amount.previousValue < changes.amount.currentValue
                ) {
                    for (
                        let i = changes.amount.previousValue;
                        i < changes.amount.currentValue;
                        i++
                    ) {
                        this.amountArray.push(false);
                        this.codeType.push('');
                        this.acceptedStep.push(false);
                        this.beneficiariesValidity.push([false, false]);
                    }
                } else if (
                    changes.amount.previousValue > changes.amount.currentValue
                ) {
                    for (
                        let i = changes.amount.previousValue;
                        i >= changes.amount.currentValue;
                        i--
                    ) {
                        this.amountArray.splice(i, 1);
                        this.codeType.splice(i, 1);
                        this.acceptedStep.splice(i, 1);
                        this.beneficiariesValidity.splice(i, 1);
                    }
                }
            }
        }
    }

    getFormGroupByIndex(idx: number) {
        return this.form.at(idx) as FormGroup;
    }

    canBeSaved(idx: number): boolean {
        const bForm: FormGroup = this.getFormGroupByIndex(idx);
        const { pertenece_a_eps, pertenece_a_ccf } = bForm.value;
        if (!pertenece_a_eps && !pertenece_a_ccf) {
            return false;
        }
        return bForm.valid;
    }

    getDocumentsAsFormArray(idx: number) {
        return this.form.at(idx).get('documentos') as FormArray;
    }

    updateCodeType(value: any, index: number) {
        this.codeType[index] = value;
    }

    clearStep(event: any, idx: number) {
        event.stopPropagation();
        this.indexDeleted = idx;
        this.isByDelete = true;

        this.atDelete.emit(idx);
    }

    handleStatusChange(event: any, idx: number) {
        if (this.acceptedStep[idx] === true) {
            this.acceptedStep[idx] = false;
        }

        this.beneficiariesValidity[idx][0] = false;
        this.updateValidity.emit({
            idx: idx,
            valid: this.beneficiariesValidity[idx],
        });
    }

    saveChanges(idx: number) {
        this.acceptedStep[idx] = true;
        this.beneficiariesValidity[idx][0] = true;

        this.updateValidity.emit({
            idx: idx,
            valid: this.beneficiariesValidity[idx],
        });
    }

    handleDocsSaveChanges(event: any, idx: number) {
        this.beneficiariesValidity[idx][1] = event;

        this.updateValidity.emit({
            idx: idx,
            valid: this.beneficiariesValidity[idx],
        });
    }
}
