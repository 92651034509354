import { Injectable } from '@angular/core';
import {
  HttpEvent,
  HttpHandler,
  HttpHeaders,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Observable, throwError, TimeoutError } from 'rxjs';
import { catchError, finalize, map, timeout } from 'rxjs/operators';
import {environment} from "@env/environment";
const APP_XHR_TIMEOUT = 50000;

@Injectable()
export class DefaultInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(this.performRequest(req)).pipe(
      timeout(APP_XHR_TIMEOUT),
      map(res => this.handleSuccessfulResponse(res)),
      catchError(err => this.handleErrorResponse(err)),
      finalize(this.handleRequestCompleted.bind(this))
    );
  }

  private getAdditionalHeaders(headers?: HttpHeaders): {
    [name: string]: string;
  } {
    const res: { [name: string]: string } = {};
    /*if (!headers?.has('x-funcionalidad')) {
      res['x-funcionalidad'] = '';
    }*/
    return res;
  }

  private performRequest1(req: HttpRequest<any>): HttpRequest<any> {
    let headers: HttpHeaders = req.headers;

    // Prefijo de servidor unificado
    let url = req.url;

    if (!url.startsWith('https://') && !url.startsWith('http://') && !url.includes('assets') && !url.includes('mock')) {
      const  baseUrl = environment.baseUrlAPi;
      url = baseUrl + (baseUrl.endsWith('/') && url.startsWith('/') ? url.substring(1) : url);
    }

    return req.clone({ url, setHeaders: this.getAdditionalHeaders(headers) });
  }

  private performRequest(req: HttpRequest<any>): HttpRequest<any> {
    let headers: HttpHeaders = req.headers;

    // Prefijo de servidor unificado
    let url = req.url;
    const isExternalUrl = url.startsWith('https://') ? true : false;
    if (!isExternalUrl && !url.startsWith('http://') && !url.includes('assets') && !url.includes('mock')) {
      const baseUrl = environment.baseUrlAPi;
      url = baseUrl + (baseUrl.endsWith('/') && url.startsWith('/') ? url.substring(1) : url);
    }
    return req.clone({ url, setHeaders: this.getAdditionalHeaders(headers) });
}


  private handleSuccessfulResponse(event: any): HttpResponse<any> {
    // console.log('response at interceptor', event);

    if (event instanceof HttpResponse) {
      event = event.clone({ body: event.body.response });
    }
    return event;
  }

  private handleErrorResponse(errorResponse: any): Observable<HttpEvent<any>> {
    // console.log('error at interceptor', errorResponse);

    if (errorResponse instanceof TimeoutError) {
      return throwError(() => 'Timeout Exception');
    }
    console.log('BASE_FRONT', errorResponse);
    switch (errorResponse.status) {
      case 401: // Unauthorized
        console.log('Sesión vencida!!!, redirecciona al Login');
        break;
      case 404:
      case 500:
        return throwError(() => errorResponse);
      case 503: // Internal Server Error
        break;
      default: // Other Error
    }


    return throwError(() => errorResponse);
  }

  private handleRequestCompleted(): void {
    // console.log(`Request finished`);
  }
}
