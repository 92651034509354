import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@core/models/Api.model';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class FiltroParametrosService {
    constructor(private http: HttpClient) {}

    busquedaControlAfiliacionId(id: string): Observable<any> {
        return this.http.get<any>(`${Api.urlAfiliacion}/afiliacion/${id}`);
    }

    busquedaControlAfiliacion(
        data: any,
        eps: boolean,
        afp: boolean,
        arl: boolean,
        ccf: boolean
    ): Observable<any> {
        const ruta = `${Api.urlAfiliacion}/afiliacion/?eps=${eps}&ccf=${ccf}&arl=${arl}&afp=${afp}`;
        return this.http.post<any>(ruta, data);
    }

    getEmpresas(): Observable<any> {
        return this.http
            .get<any>(`${Api.urlCliente}/cliente_basic/get_by_params/`)
            .pipe(map((resp) => resp.objeto));
    }

    getParametrosCompleto(): Observable<any> {
        return this.http.get<any>(`${Api.url_parametro}/`);
    }

    getParametrosEstados(): Observable<any> {
        return this.http.get<any>(`${Api.url_parametro}/estados`);
    }

    getParametrosEstadosBeneficiario(): Observable<any> {
        return this.http.get<any>(`${Api.url_parametro}/estado_beneficiario`);
    }

    getDiasRecientes(): Observable<any> {
        return this.http.get<any>(`${Api.url_parametro}/dias_recientes`);
    }
}
