<mat-card class="w-full mb-5 shadow-md">
    <mat-card-header class="flex justify-center items-center px-24 justify-between">
        <mat-card-title class="flex justify-center items-center text-2xl font-bold text-black">{{modulo}}</mat-card-title>
        <div class="checkbox-container">
            <p class="flex justify-end text-sm font-normal text-black">Seleccione el tipo de subida que desee visualizar</p>
            <mat-checkbox (change)="emitManualClick($event.checked)" class="checkbox-item text-md">&nbsp;&nbsp;&nbsp;&nbsp;Manual</mat-checkbox>
            <mat-checkbox (change)="emitMasivoClick($event.checked)" class="checkbox-item text-md">&nbsp;&nbsp;&nbsp;&nbsp;Masivo</mat-checkbox>
            <mat-checkbox (change)="emitOcrClick($event.checked)" class="checkbox-item text-md">&nbsp;&nbsp;&nbsp;&nbsp;OCR</mat-checkbox>
            <mat-checkbox (change)="emitApiClick($event.checked)" class="checkbox-item text-md">&nbsp;&nbsp;&nbsp;&nbsp;API</mat-checkbox>
        </div>
    </mat-card-header>
    <mat-card-content></mat-card-content>
</mat-card>
