<div>
    <div class="dialog-header">
        <mat-icon class="close-icon" (click)="closePopup()">close</mat-icon>
        <h2 class="text-3xl text-gray-500 leading-9 align-center font-bold mt-8 mb-4">
            Seleccione los <span class="departamento-text">Departamentos</span> en los que tiene presencia la empresa
        </h2>
    </div>
    <div class="departamentos-container">
        <div class="departamento-item">
            <mat-checkbox [(ngModel)]="todosSeleccionados" (change)="seleccionarTodos()">Todos</mat-checkbox>
        </div>
        <div *ngFor="let department of departments" class="department-item">
            <mat-checkbox [(ngModel)]="department.seleccionado" (change)=deselectionTodos()>{{department.nombre}}</mat-checkbox>
        </div>
    </div>
    <button
        class="text-white bg-primary w-full flex justify-center"
        mat-button color="primary"
        (click)="guardarCambios()"
    >
        Guardar cambios
    </button>
</div>
