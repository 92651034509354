import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class FileService {

  constructor() {
  }

  openFile(): Observable<{ name: string, base64: string, extension: string }> {
    return new Observable((observer) => {
      const fileInput = document.createElement('input')
      fileInput.type = 'file'

      fileInput.addEventListener('change', (event: any) => {
        const selectedFile = event.target.files[0]
        // console.log(selectedFile)
        if (selectedFile) {
          const reader = new FileReader()
          reader.onload = () => {
            const base64String = reader.result as string
            const data = {
              name: selectedFile.name,
              base64: base64String.split(',')[1],
              extension: selectedFile.type.split('/')[1],
            }
            observer.next(data)
            observer.complete()
          }
          reader.onerror = (error) => {
            observer.error(error)
          }
          reader.readAsDataURL(selectedFile)
        } else {
          observer.error('No se seleccionó ningún archivo')
        }
      })

      fileInput.click()
    })
  }

}
