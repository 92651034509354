<div class="flex-auto p-6 sm:p-15">
   <div class="text-3xl text-center font-bold leading-6 pb-10"  >
       <p>Recuerda que para esta entidad el colaborador será notificado para<br>que firme el siguiente formulario</p>
   </div>
    <div class="border-t-[2px] border-x-[2px] border-gray-300 rounded-t-[8px] justify-around pb-10">
        <!-- Título principal -->
        <div class="flex justify-center text-3xl font-bold text-primary py-10">
            Formulario de afiliación generado
        </div>
        <div class=" w-full px-10">
            <div><iframe
                *ngIf="docSanitized"
                [src]="docSanitized"
                width="100%"
                height="700px"
                title="iframe"
            ></iframe></div>
        </div>
        <div *ngIf="documentos && documentos.length > 0" class="flex justify-center text-lg mb-10">
            <mat-paginator
                [length]="documentos.length"
                [pageSize]="1"
                [pageSizeOptions]="[1]"
                (page)="handlePageEvent($event)"
                showFirstLastButtons>
            </mat-paginator>
        </div>
        <!--  end content -->
    </div>
    <div class="flex-auto mb-6 w-full bg-gray-200 rounded-b-[8px] p-10">
        <div class="text-primary font-bold text-3xl pb-[10px]">Firma del colaborador</div>
        <div class="bg-white w-full min-h-[185px]"><!-- firma en creación --></div>
    </div>
    <div class="flex justify-center items-center w-full mt-20">
        <button type="button"  mat-stroked-button
                (click)="notificarPorCorreo()"
                class="bg-white font-medium text-lg text-primary  border-primary btn-afiliacion max-w-full mr-10"
        ><mat-icon color="primary" class="text-3xl">email</mat-icon><span class="ml-5">Notificar por correo</span>
        </button>
        <button type="button"  mat-stroked-button
                class="bg-white font-medium text-lg text-primary btn-afiliacion max-w-full"
        ><mat-icon color="primary" class="text-3xl">message</mat-icon><span class="ml-5">Notificar por WhatsApp</span>
        </button>
    </div>
    <div class="flex justify-center items-center my-6 w-full">
        <button type="button" mat-stroked-button
                (click)="copiarEnlaceNotificacion()"
                class="bg-white font-medium text-lg text-primary rounded-none w-full  max-w-full">
            <mat-icon color="primary" class="text-3xl">link</mat-icon><span class="ml-5 text-2xl">Copiar enlace de notificación</span>
        </button>
    </div>
    <div class="flex justify-center items-center my-6 w-full">
        <button type="button" mat-button
                (click)="guardarAfiliacion()"
                class="bg-primary font-medium text-2xl text-white rounded-none btn-entidad w-full">Guardar afiliación
        </button>
    </div>

</div>

