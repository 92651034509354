<form [formGroup]="DatosPersonales">
    <div class="font-bold py-5 text-gray-600 text-lg">Datos Personales</div>
    <div
        class="grid gap-4 grid-cols-1 content-center sm:grid-cols-1 lg:grid-cols-4"
    >
        <mat-form-field class="w-full">
            <mat-label>Primer apellido</mat-label>
            <input
                matInput
                type="text"
                formControlName="primer_apellido"
                placeholder="Ingresa tu primer apellido"
            />
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Segundo apellido</mat-label>
            <input
                matInput
                type="text"
                formControlName="segundo_apellido"
                placeholder="Ingresa tu segundo apellido"
            />
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Primer nombre</mat-label>
            <input
                matInput
                type="text"
                formControlName="primer_nombre"
                placeholder="Ingresa tu primer nombre"
            />
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Segundo nombre</mat-label>
            <input
                matInput
                type="text"
                formControlName="segundo_nombre"
                placeholder="Ingresa tu segundo nombre"
            />
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Nacionalidad</mat-label>
            <mat-select
                formControlName="id_nacionalidad"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="let food of countries | async"
                    [value]="food.id"
                >
                    {{ food.nombre }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Tipo de identificacion</mat-label>
            <mat-select
                formControlName="codigo_tipo_identificacion"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="
                        let food of parametrosService.listaTipoIdentifiacion$
                            | async
                    "
                    [value]="food.cod"
                >
                    {{ food.valor }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Numero de identificacion</mat-label>
            <input
                matInput
                type="text"
                formControlName="numero_identificacion"
                placeholder="Ingresa tu identificacion"
            />
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field color="accent">
            <mat-label>Fecha de expedición de documento</mat-label>
            <input
                matInput
                [max]="today"
                [matDatepicker]="fechaexpediciondocumento"
                formControlName="fecha_expedicion_documento"
                placeholder="AAAA/MM/DD"
            />
            <mat-datepicker-toggle
                matIconSuffix
                [for]="fechaexpediciondocumento"
            ></mat-datepicker-toggle>
            <mat-datepicker #fechaexpediciondocumento></mat-datepicker>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Departamento de expedición del documento</mat-label>
            <mat-select
                formControlName="id_depto_expedicion_documento"
                placeholder="selecciona"
                (selectionChange)="handleExpeditionDepartmentChange($event)"
            >
                <mat-option
                    *ngFor="let food of departamentos"
                    [value]="food.id"
                >
                    {{ food.nombre }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Municipio de expedición del documento</mat-label>
            <mat-select
                formControlName="id_mpio_expedicion_documento"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="let food of expeditionCities | async"
                    [value]="food.id"
                >
                    {{ food.nombre }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Ciudad de expedición del documento</mat-label>
            <mat-select
                formControlName="id_ciudad_expedicion_documento"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="let food of expeditionCities | async"
                    [value]="food.id"
                >
                    {{ food.nombre }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field color="accent">
            <mat-label>Fecha de nacimiento</mat-label>
            <input
                matInput
                [matDatepicker]="fechaNacimiento"
                [max]="today"
                formControlName="fecha_nacimiento"
                placeholder="AAAA/MM/DD"
            />
            <mat-datepicker-toggle
                matIconSuffix
                [for]="fechaNacimiento"
            ></mat-datepicker-toggle>
            <mat-datepicker #fechaNacimiento></mat-datepicker>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Departamento de nacimiento</mat-label>
            <mat-select
                formControlName="id_depto_nacimiento"
                placeholder="selecciona"
                (selectionChange)="handleBirthDepartmentChange($event)"
            >
                <mat-option
                    *ngFor="let food of departamentos"
                    [value]="food.id"
                >
                    {{ food.nombre }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Municipio de nacimiento</mat-label>
            <mat-select
                formControlName="id_mpio_nacimiento"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="let food of birthCities | async"
                    [value]="food.id"
                >
                    {{ food.nombre }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Ciudad de nacimiento</mat-label>
            <mat-select
                formControlName="id_ciudad_nacimiento"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="let food of birthCities | async"
                    [value]="food.id"
                >
                    {{ food.nombre }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Departamento de residencia</mat-label>
            <mat-select
                formControlName="id_depto_residencia"
                placeholder="selecciona"
                (selectionChange)="handleResidenceDepartmentChange($event)"
            >
                <mat-option
                    *ngFor="let food of departamentos"
                    [value]="food.id"
                >
                    {{ food.nombre }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Municipio de residencia</mat-label>
            <mat-select
                formControlName="id_mpio_residencia"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="let food of residenceCities | async"
                    [value]="food.id"
                >
                    {{ food.nombre }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Ciudad de residencia</mat-label>
            <mat-select
                formControlName="id_ciudad_residencia"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="let food of residenceCities | async"
                    [value]="food.id"
                >
                    {{ food.nombre }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Barrio de residencia</mat-label>
            <input
                matInput
                type="text"
                formControlName="barrio_residencia"
                placeholder="Ingresa tu barrio de residencia"
            />
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Tipo de dirección de residencia</mat-label>
            <mat-select
                formControlName="codigo_tipo_direccion_residencia"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="let food of parametrosService.tipoDirRes$ | async"
                    [value]="food.cod"
                >
                    {{ food.valor }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full col-span-2">
            <mat-label>Dirección de residencia</mat-label>
            <input
                matInput
                type="text"
                formControlName="direccion_residencia"
                placeholder="Escribe tu direción"
            />
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Sexo</mat-label>
            <mat-select formControlName="codigo_sexo" placeholder="selecciona">
                <mat-option
                    *ngFor="let food of parametrosService.sexo$ | async"
                    [value]="food.cod"
                >
                    {{ food.valor }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Estado civil</mat-label>
            <mat-select
                formControlName="codigo_estado_civil"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="let food of parametrosService.estadoCivil$ | async"
                    [value]="food.cod"
                >
                    {{ food.valor }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>correo</mat-label>
            <input
                matInput
                type="text"
                formControlName="correo"
                placeholder="Ingresa tu correo"
            />
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Número móvil</mat-label>
            <input
                matInput
                type="text"
                formControlName="numero_movil"
                placeholder="Ingresa tu numero movil"
            />
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Telefono fijo</mat-label>
            <input
                matInput
                type="text"
                formControlName="telefono_fijo"
                placeholder="Ingresa tu numero movil"
            />
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Discapacidad</mat-label>
            <mat-select
                formControlName="codigo_discapacidad"
                placeholder="Discapacidad"
            >
                <mat-option
                    *ngFor="let food of parametrosService.discapacidad$ | async"
                    [value]="food.cod"
                >
                    {{ food.valor }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Condición</mat-label>
            <mat-select
                formControlName="codigo_condicion"
                placeholder="Condición"
            >
                <mat-option
                    *ngFor="let food of parametrosService.condicion$ | async"
                    [value]="food.cod"
                >
                    {{ food.valor }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Emplea un lenguaje especial</mat-label>
            <mat-select
                formControlName="emplea_lenguaje_especial"
                placeholder="selecciona"
            >
                <mat-option *ngFor="let food of ['Si', 'No']" [value]="food">
                    {{ food }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Etnia</mat-label>
            <mat-select formControlName="codigo_etnia" placeholder="Selecciona">
                <mat-option
                    *ngFor="let food of parametrosService.etnia$ | async"
                    [value]="food.cod"
                >
                    {{ food.valor }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Factor de vulnerabilidad</mat-label>
            <mat-select
                formControlName="factor_vulnerabilidad"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="
                        let food of parametrosService.vulnerabilities$ | async
                    "
                    [value]="food.cod"
                >
                    {{ food.valor }}
                </mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Usted es cabeza de hogar</mat-label>
            <mat-select formControlName="cabeza_hogar" placeholder="selecciona">
                <mat-option *ngFor="let food of ['Si', 'No']" [value]="food">
                    {{ food }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Tiene vivienda propia</mat-label>
            <mat-select
                formControlName="vivienda_propia"
                placeholder="selecciona"
            >
                <mat-option *ngFor="let food of ['Si', 'No']" [value]="food">
                    {{ food }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Sector agropecuario</mat-label>
            <mat-select
                formControlName="sector_agropecuario"
                placeholder="selecciona"
            >
                <mat-option *ngFor="let food of ['Si', 'No']" [value]="food">
                    {{ food }}
                </mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Nivel educativo</mat-label>
            <mat-select
                formControlName="codigo_nivel_educativo"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="
                        let food of parametrosService.nivelEducativo$ | async
                    "
                    [value]="food.cod"
                >
                    {{ food.valor }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>EPS Actual</mat-label>
            <mat-select formControlName="eps_actual" placeholder="selecciona">
                <mat-option [value]="''">Ninguna</mat-option>
                <mat-option
                    *ngFor="let entity of eps_entities"
                    [value]="entity.id_entidad_tipoentidad"
                >
                    {{ entity.nombre_entidad }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>AFP Actual</mat-label>
            <mat-select formControlName="afp_actual" placeholder="selecciona">
                <mat-option [value]="''">Ninguna</mat-option>
                <mat-option
                    *ngFor="let entity of afp_entities"
                    [value]="entity.id_entidad_tipoentidad"
                >
                    {{ entity.nombre_entidad }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>CCF Actual</mat-label>
            <mat-select formControlName="ccf_actual" placeholder="selecciona">
                <mat-option [value]="''">Ninguna</mat-option>
                <mat-option
                    *ngFor="let entity of ccf_entities"
                    [value]="entity.id_entidad_tipoentidad"
                >
                    {{ entity.nombre_entidad }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>ARL Actual</mat-label>
            <mat-select formControlName="arl_actual" placeholder="selecciona">
                <mat-option [value]="''">Ninguna</mat-option>
                <mat-option
                    *ngFor="let entity of arl_entities"
                    [value]="entity.id_entidad_tipoentidad"
                >
                    {{ entity.nombre_entidad }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
    </div>
</form>
<div class="font-bold py-5 text-gray-600 text-lg">Datos laborales</div>
<form [formGroup]="DatosLaborales">
    <div
        class="grid gap-4 grid-cols-1 content-center sm:grid-cols-1 lg:grid-cols-4"
    >
        <mat-form-field class="w-full">
            <mat-label>Nivel ocupacional</mat-label>
            <mat-select
                formControlName="codigo_nivel_ocupacional"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="
                        let food of parametrosService.nivelOcupacional$ | async
                    "
                    [value]="food.cod"
                >
                    {{ food.valor }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Modalidad de trabajo</mat-label>
            <mat-select
                formControlName="codigo_modalidad_trabajo"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="
                        let food of parametrosService.modalidadTrabajo$ | async
                    "
                    [value]="food.cod"
                >
                    {{ food.valor }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Cargo</mat-label>
            <input
                matInput
                type="text"
                formControlName="cargo"
                placeholder="Ingresa tu cargo actual"
            />
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Número de contrato cliente</mat-label>
            <input
                matInput
                type="text"
                formControlName="numero_contrato"
                placeholder="Ingresa el número de contrato"
            />
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Salario base</mat-label>
            <input
                matInput
                type="text"
                formControlName="base_cotizacion"
                placeholder="Ingresa tu salario actual"
            />
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Dirección laboral</mat-label>
            <input
                matInput
                type="text"
                formControlName="direccion_laboral"
                readonly
            />
        </mat-form-field>
        <mat-form-field class="w-full">
            <mat-label>Jornada laboral</mat-label>
            <mat-select
                formControlName="codigo_jornada_laboral"
                placeholder="selecciona"
            >
                <mat-option
                    *ngFor="let jornada of jornadas"
                    [value]="jornada.cod"
                >
                    {{ jornada.valor }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
    </div>
</form>
