import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Api} from "@core/models/Api.model";
import {DepartmentInterface} from "@shared/interfaces/Department.interface";
import {CityInterface} from "@shared/interfaces/city.interface";

const departmentsBaseUrl = `${Api.BaseUrl}/departments/`

@Injectable({
    providedIn: 'root'
})

export class DepartmentService {


    constructor(private _http: HttpClient) {
    }

    getDepartments(): Observable<DepartmentInterface[]>{
        return this._http.get<DepartmentInterface[]>(`${departmentsBaseUrl}`)
    }

    getCitiesByDepartmentId(id: number): Observable<CityInterface[]>{
        return this._http.get<CityInterface[]>(`${departmentsBaseUrl}${id}/cities`)
    }

}
