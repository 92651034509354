import { Component, inject, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DepartmentInterface } from '@shared/interfaces/Department.interface';
import { CityInterface } from '@shared/interfaces/city.interface';
import { DepartmentCityService } from '@shared/services/department-city.service';
import { CountriesInterface } from '@shared/interfaces/countries.interface';
import { forkJoin, of } from 'rxjs';
import { ParametrosService } from '@shared/services/parametros.service';
import { GenericObjectEntityInterface } from '@shared/interfaces/generic-object-response.interface';
import { TipoParametroEnum } from '@shared/types/tipo-informacion.type';

@Component({
    selector: 'app-employee-fua-form',
    templateUrl: './employee-fua-form.component.html',
    styleUrls: ['./employee-fua-form.component.scss'],
})
export class EmployeeFuaFormComponent implements OnInit {
    @Input() form!: FormGroup;
    @Input() parametros = Object({});
    @Input() departamentos: DepartmentInterface[];
    @Input() clientId: number;
    private departmentCityService: DepartmentCityService = inject(
        DepartmentCityService
    );

    public parametrosService: ParametrosService = inject(ParametrosService);
    birthCities = of<CityInterface[]>([]);
    expeditionCities = of<CityInterface[]>([]);
    residenceCities = of<CityInterface[]>([]);
    countries = of<CountriesInterface[]>([]);
    eps_entities: GenericObjectEntityInterface[] = [];
    afp_entities: GenericObjectEntityInterface[] = [];
    arl_entities: GenericObjectEntityInterface[] = [];
    ccf_entities: GenericObjectEntityInterface[] = [];
    jornadas = [];
    today: Date = new Date();

    constructor() {
        this.countries = this.departmentCityService.getCountries();
    }

    async ngOnInit(): Promise<void> {
        forkJoin({
            eps: this.parametrosService.getEntities('eps', this.clientId),
            ccf: this.parametrosService.getEntities('ccf', this.clientId),
            afp: this.parametrosService.getEntities('afp', this.clientId),
            arl: this.parametrosService.getEntities('arl', this.clientId),
            jornadas: this.parametrosService.getParametro(
                TipoParametroEnum.TIPO_JORNADA_LABORAL
            ),
        }).subscribe(({ eps, ccf, afp, arl, jornadas }) => {
            this.eps_entities = eps;
            this.ccf_entities = ccf;
            this.afp_entities = afp;
            this.arl_entities = arl;
            this.jornadas = jornadas;
        });
    }

    get f() {
        return this.form.controls;
    }

    get DatosPersonales() {
        return this.form.controls.datos_personales as FormGroup;
    }

    get DatosLaborales() {
        return this.form.controls.datos_laborales as FormGroup;
    }

    handleBirthDepartmentChange({ value }: any) {
        this.birthCities =
            this.departmentCityService.getCitiesByDepartment(value);
    }

    handleExpeditionDepartmentChange({ value }: any) {
        this.expeditionCities =
            this.departmentCityService.getCitiesByDepartment(value);
    }

    handleResidenceDepartmentChange({ value }: any) {
        this.residenceCities =
            this.departmentCityService.getCitiesByDepartment(value);
    }
}
