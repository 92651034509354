<div class="block relative mt-[-15px] mr-[-15px] float-right">
    <mat-icon  (click)="closeModal()"  class="icon-size-[41px] cursor-pointer text-gray-300">close</mat-icon>
</div>

<div class="mb-[50px] max-w-[550px] mx-auto">
    <div class="text-center pb-[50px]">
        <p style="font-size: 34px; font-weight: 500; margin: 0;"><span class="text-primary;">Notificar por correo - </span></p>
    </div>

    <form [formGroup]="emailForm" (ngSubmit)="enviar()" class="flex flex-wrap gap-4">
        <mat-form-field class="w-full md:w-1/2 ">
            <mat-label>Para:</mat-label>
            <input matInput placeholder="Ingrese el correo electrónico" formControlName="para">
            <mat-error *ngIf="emailForm.get('para').invalid && (emailForm.get('para').dirty || emailForm.get('para').touched)">
                <span *ngIf="emailForm.get('para').errors?.required">Este campo es obligatorio.</span>
                <span *ngIf="emailForm.get('para').errors?.pattern">El correo electrónico no es válido.</span>
            </mat-error>
        </mat-form-field>
    
        <mat-form-field class="w-full md:w-1/2">
            <mat-label>Asunto</mat-label>
            <input matInput placeholder="Ingrese el asunto" formControlName="asunto">
            <mat-error *ngIf="emailForm.get('asunto').invalid && (emailForm.get('asunto').dirty || emailForm.get('asunto').touched)">Este campo es obligatorio.</mat-error>
        </mat-form-field>
    
        <mat-form-field class="w-full">
            <mat-label>Mensaje</mat-label>
            <input matInput placeholder="Ingrese el mensaje" formControlName="mensaje">
            <mat-error *ngIf="emailForm.get('mensaje').invalid && (emailForm.get('mensaje').dirty || emailForm.get('mensaje').touched)">Este campo es obligatorio.</mat-error>
        </mat-form-field>
    
        <button type="submit" class="w-full text-md rounded-[4px] h-[44px] md:w-auto" mat-raised-button color="primary" [disabled]="emailForm.invalid">
            <span>Enviar</span>
        </button>
    </form>
    
    
</div>
