import { Component, Input } from '@angular/core';
import { MatDialogRef } from "@angular/material/dialog";
import { FormBuilder, FormGroup, Validators } from '@angular/forms'; 
import { NotificationsService } from "@shared/services/notifications/notifications.service";
import { EmailNotificationInterface } from "@shared/interfaces/email-notification.interface";
import { UtilPopupService } from '@shared/services/util-popup.service';
import { catchError, of } from 'rxjs';
import { MaterialModule } from '@shared/material.module';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
    selector: 'app-affiliation-email-notification',
    templateUrl: './affiliation-email-notification.component.html',
    standalone: true,
    imports: [
            MaterialModule,
            ReactiveFormsModule
        ],
        providers: [UtilPopupService],
    styleUrls: ['./affiliation-email-notification.component.scss']
})
export class AffiliationEmailNotificationComponent {

    @Input() correo: string = '';
    @Input() idAfiliacionGeneral: number = 0;

    emailForm: FormGroup; 

    constructor(
        public dialogRef: MatDialogRef<AffiliationEmailNotificationComponent>,
        private formBuilder: FormBuilder,
        private notificationService: NotificationsService,
        private utilPopupService: UtilPopupService,
    ) {
        this.emailForm = this.formBuilder.group({
            para: ['', [Validators.required, Validators.pattern('[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}')]],
            asunto: ['', [Validators.required]],
            mensaje: ['', [Validators.required]]
        });
    }

    closeModal() {
        this.dialogRef.close()
    }

    enviar() {
        if (this.emailForm.invalid) {
            return;
        }

        const email: EmailNotificationInterface = {
            correo: this.emailForm.value.para,
            asunto: this.emailForm.value.asunto,
            mensaje: this.emailForm.value.mensaje
        }

        console.log('email', email)

        this.notificationService.sendEmail(this.idAfiliacionGeneral, email, false)
        .pipe(
            catchError(error => {
                this.utilPopupService.mostrarMensaje('Ocurrió un error al enviar el correo.', 'error', 'Error', false);
                return of(null); 
            })
        )
        .subscribe((resp) => {
            if (resp) {
                this.utilPopupService.mostrarMensaje(`El formulario de afiliación a sido enviado con éxito al correo  ${this.emailForm.value.para}`, 'success', 'Envio exitoso', false);
            }
            this.closeModal();
        });
    }

}
