import {Injectable} from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponseBase
} from '@angular/common/http';
import {BehaviorSubject, catchError, filter, Observable, switchMap, take, throwError} from 'rxjs';
import {AuthService} from 'app/core/auth/auth.service';
import {AuthUtils} from 'app/core/auth/auth.utils';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    private refreshToking = false;
    private refreshToken$: BehaviorSubject<any> = new BehaviorSubject<any>(null);
    /**
     * Constructor
     */
    constructor(private _authService: AuthService) {
    }

    /**
     * Vuelva a cargar nueva información de Token
     *
     *  Debido a que la solicitud ya se lanzó, no será otra ejecución una vez más  Por lo tanto, debe combinarse con la situación comercial para volver a adjuntar el nuevo Token
     */
    private reAttachToken(req: HttpRequest<any>): HttpRequest<any> {
        return req.clone({
            setHeaders: {
                Authorization: `Bearer ${this._authService.accessToken}`,
                Permissions: `${this._authService.permissionsToken}`
            }
        });
    }

    // #region Refresh Token Método 1: use 401 para actualizar el token

    private tryRefreshToken(ev: HttpResponseBase, req: HttpRequest<any>, next: HttpHandler): Observable<any> {
        // 1. Si la solicitud es una solicitud de token de actualización, significa que la página de inicio de sesión se puede saltar directamente desde el token de actualización
        if ([`/auth/token`].some(url => req.url.includes(url))) {
            // this.toLogin();
            return throwError(ev);
        }
        // 2. Si `refreshToking` es `verdadero`, significa que ya está en la solicitud para actualizar el token, y todas
        // las solicitudes posteriores entrarán en el estado de espera y luego reiniciarán la solicitud hasta
        // que se devuelva el resultado.
        if (this.refreshToking) {
            return this.refreshToken$.pipe(
                filter(v => !!v),
                take(1),
                switchMap(() => next.handle(this.reAttachToken(req)))
            );
        }
        // 3. Intente llamar al token de actualización
        this.refreshToking = true;
        this.refreshToken$.next(null);

        return this._authService.refreshTokenRequest().pipe(
            switchMap(res => {
                // Notificar solicitudes posteriores para proceder
                this.refreshToking = false;
                this.refreshToken$.next(res);
                // Vuelva a guardar el nuevo token
                    //TODO la logica de almacenamiento de los nuevos datos esta en refreshTokenRequest()
                // reiniciar la solicitud
                return next.handle(this.reAttachToken(req));
            }),
            catchError(err => {
                this.refreshToking = false;
                // this.toLogin();
                return throwError(err);
            })
        );
    }


    /**
     * Intercept
     *
     * @param req
     * @param next
     */
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // Clone the request object
        let newReq = req.clone();

        // Request
        //
        // If the access token didn't expire, add the Authorization header.
        // We won't add the Authorization header if the access token expired.
        // This will force the server to return a "401 Unauthorized" response
        // for the protected API routes which our response interceptor will
        // catch and delete the access token from the local storage while logging
        // the user out from the app.
        if (this._authService.accessToken && !AuthUtils.isTokenExpired(this._authService.accessToken)) {
            newReq = this.reAttachToken(req)
        }

        // Response
        return next.handle(newReq).pipe(
            catchError((error) => {

                // Catch "401 Unauthorized" responses
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // Sign out
                    this._authService.signOut();

                    // Reload the app
                    location.reload();
                }

                return throwError(error);
            })
        );
    }
}
