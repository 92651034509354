import {inject, Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Api} from "@core/models/Api.model";
import {DepartamentInterface} from "@shared/interfaces/departament.interface";
import {CityInterface} from "@shared/interfaces/city.interface";
import { CountriesInterface } from '@shared/interfaces/countries.interface';


@Injectable({
    providedIn: 'root'
})
export class DepartmentCityService {
    private http: HttpClient

    constructor() {
        this.http = inject(HttpClient);
    }

    getCountries() {
        return this.http.get<CountriesInterface[]>(`${Api.url_countries}`)
    }

    getDepartments() {
        return this.http.get<DepartamentInterface[]>(`${Api.url_departamentos}`)
    }

    getCities() {
        return this.http.get<CityInterface[]>(`${Api.url_ciudades}`);
    }

    getCitiesByDepartment(idDepartamento: string) {
        return this.http.get<CityInterface[]>(`${Api.url_departamentos}${idDepartamento}/cities`)
    }
}
