import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { DepartmentInterface } from '@shared/interfaces/Department.interface';
import { ParametrosService } from '@shared/services/parametros.service';
import { forkJoin } from 'rxjs';
import {
    GenericBranchInterface,
    GenericCompanyInterface,
    GenericLevelInterface,
    GenericObjectResponseInterface,
    GenericWorkPlaceInterface,
} from '@shared/interfaces/generic-object-response.interface';

@Component({
    selector: 'app-company-form',
    templateUrl: './company-form.component.html',
    styleUrls: ['./company-form.component.scss'],
})
export class CompanyFormComponent {
    @Input() form!: FormGroup;
    @Input() departamentos!: DepartmentInterface[];
    @Output() companyChange = new EventEmitter<any>();

    fechaIngresoAnterior = false;

    public parametrosService: ParametrosService = inject(ParametrosService);
    workPlacesData = [];
    customerCompanies: GenericCompanyInterface[] = [];
    contracts: GenericObjectResponseInterface[] = [];
    riskLevels: any[] = [];

    levels = {
        1: [],
        2: [],
        3: [],
        4: [],
    };
    today: Date = new Date();

    ngOnInit(): void {
        this.parametrosService.companies$.subscribe(
            (x) => (this.customerCompanies = x)
        );
        this.parametrosService.contracts$.subscribe(
            (x) => (this.contracts = x)
        );
        // this.parametrosService.getEntities().subscribe(resp => // console.log('resp',resp))
    }

    get f() {
        return this.form.controls;
    }

    handleChangeWorkPLace({ value }) {
        this.f['clase_riesgo'].setValue(
            this.workPlacesData.find(
                (x: GenericWorkPlaceInterface) => x.id == value
            )?.clase_riesgo
        );
    }

    handleChangeCustomerCompany({ value }) {
        const { nit, direccion_sede, niveles, sucursales } =
            this.customerCompanies.find(
                (x: GenericCompanyInterface) => x.id == value
            );

        // console.log(sucursales);
        this.f['nit_empleador'].setValue(nit);
        this.setLevels(niveles, '1');
        this.getWorkplaces(sucursales);

        this.companyChange.emit(direccion_sede);
    }

    async handleLevelChange(event: any, origin: string, destination: string) {
        const data = await this.parametrosService
            .getLevesByParentId(event.value)
            .toPromise();
        this.setLevels(data, destination);
    }

    private setLevels(data: GenericLevelInterface[], destination: string) {
        this.levels[destination] = data;
        switch (destination) {
            case '1':
                this.levels['2'] = [];
                this.levels['3'] = [];
                this.levels['4'] = [];

                this.f['nivel_2'].setValue(null);
                this.f['nivel_3'].setValue(null);
                this.f['nivel_4'].setValue(null);
                break;
            case '2':
                this.levels['3'] = [];
                this.levels['4'] = [];

                this.f['nivel_3'].setValue(null);
                this.f['nivel_4'].setValue(null);
                break;
            case '3':
                this.levels['4'] = [];

                this.f['nivel_4'].setValue(null);
                break;
        }
    }

    private async getWorkplaces(branches: GenericBranchInterface[]) {
        this.workPlacesData = await this.callServiceGetWorkplaces(branches);
    }

    private callServiceGetWorkplaces(branches: GenericBranchInterface[]) {
        return new Promise<any[]>((resolve, reject) => {
            const observables = branches.map((branch) =>
                this.parametrosService.getWorkplacesByBranchId(branch.id)
            );

            forkJoin(observables).subscribe(
                (responses: any[]) => {
                    const data = responses.reduce(
                        (acc, response) => acc.concat(response),
                        []
                    );
                    // console.log('data return', data);
                    resolve(data);
                },
                (error) => {
                    console.error('Error:', error);
                    reject(error);
                }
            );
        });
    }

    onFechaIngresoChange() {
        const fechaIngreso = this.f['fecha_ingreso'].value;
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        this.fechaIngresoAnterior = fechaIngreso < today;
    }
}
