import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class LoadingBackdropService {
    private open = false;

    constructor() {}

    show() {
        if (this.open) {
            return;
        }
        const backdropWrapperElement = this.createLoadingBackdropTemplate();
        const bodyElement = document.querySelector('body');
        bodyElement?.appendChild(backdropWrapperElement);
        this.open = true;
    }

    hide() {
        const backdropWrapperElement =
            document.querySelector('#loadingBackdrop');
        backdropWrapperElement?.remove();
        this.open = false;
    }

    private createLoadingBackdropTemplate(): HTMLDivElement {
        const element = document.createElement('div');
        element.setAttribute('id', 'loadingBackdrop');
        element.setAttribute(
            'style',
            `
      width: 100vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: 0;
      z-index: 1901;
    `
        );
        element.innerHTML = `
      <div class="backdrop"></div>
      <div class="spinner-focum">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    `;
        return element;
    }
}
