import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ResultResponseInterface } from '../interfaces/result-response.interface';
import {
    GenericCompanyInterface,
    GenericLevelInterface,
    GenericObjectEntityInterface,
    GenericObjectResponseInterface,
    GenericWorkPlaceInterface,
} from '../interfaces/generic-object-response.interface';
import { map, Observable } from 'rxjs';
import { Api } from '@core/models/Api.model';
import { TipoParametroEnum } from '../types/tipo-informacion.type';
import { ParametroInterface } from '@shared/interfaces/parametro.interface';
import { ValorInterface } from '@shared/interfaces/Parameter.interface';

@Injectable({
    providedIn: 'root',
})
export class ParametrosService {
    public listaTipoIdentifiacion$ = this.getParametro(TipoParametroEnum.TIPO_DOCUMENTOS);
    public listaTipoIdentificacionEntidades$ = this.getParametro(TipoParametroEnum.TIPOS_IDENTIFICACION_ENTIDADES);
    public listaRegimenes$ = this.getParametro(TipoParametroEnum.REGIMENES);
    public sexo$ = this.getParametro(TipoParametroEnum.SEXO);
    public tipoCotizante$ = this.getParametro(TipoParametroEnum.TIPO_COTIZANTE);
    public tipoDirRes$ = this.getParametro(TipoParametroEnum.TIPO_DIR_RES);
    public discapacidad$ = this.getParametro(TipoParametroEnum.DISCAPACIDAD);
    public condicion$ = this.getParametro(TipoParametroEnum.CONDICION);
    public etnia$ = this.getParametro(TipoParametroEnum.ETNIA);
    public modalidadTrabajo$ = this.getParametro(
        TipoParametroEnum.MODALIDAD_TRABAJO
    );
    public tipoNovedad$ = this.getParametro(TipoParametroEnum.TIPO_NOVEDAD);
    public parentezco$ = this.getParametro(TipoParametroEnum.TIPO_PARENTEZCO);
    public parentezcoHermanos$ = this.getParametro(
        TipoParametroEnum.PARENTEZCO_HERMANOS
    );
    public parentezcoHijos$ = this.getParametro(
        TipoParametroEnum.PARENTEZCO_HIJOS
    );
    public estadoCivil$ = this.getParametro(TipoParametroEnum.ESTADO_CIVIL);
    public nivelEducativo$ = this.getParametro(
        TipoParametroEnum.NIVEL_EDUCATIVO
    );
    public nivelOcupacional$ = this.getParametro(
        TipoParametroEnum.NIVEL_OCUPACIONAL
    );
    public estados$ = this.getParametro(TipoParametroEnum.ESTADOS);
    public tipoNovedadAfiliacionEPS$: Observable<
        GenericObjectResponseInterface[]
    > = this.getParametro(TipoParametroEnum.TIPO_NOVEDAD_EPS);
    public tipoNovedadAfiliacionAFP$: Observable<
        GenericObjectResponseInterface[]
    > = this.getParametro(TipoParametroEnum.TIPO_NOVEDAD_AFP);
    public tipoNovedadAfiliacionARL$: Observable<
        GenericObjectResponseInterface[]
    > = this.getParametro(TipoParametroEnum.TIPO_NOVEDAD_ARL);
    public tipoNovedadAfiliacionCCF$: Observable<
        GenericObjectResponseInterface[]
    > = this.getParametro(TipoParametroEnum.TIPO_NOVEDAD_CCF);

    public companies$: Observable<GenericCompanyInterface[]> =
        this.getCompanies();
    public contracts$: Observable<ValorInterface[]> = this.getParametro(
        TipoParametroEnum.TIPO_CONTRATO
    );
    public vulnerabilities$: Observable<GenericObjectResponseInterface[]> =
        this.getParametro(TipoParametroEnum.FACTOR_VULNERABILIDAD);
    public tipoContacto$ = this.getParametro(TipoParametroEnum.TIPO_CONTACTO);
    public tipoMeta$ = this.getParametro(TipoParametroEnum.TIPO_META);
    public tipoCliente$ = this.getParametro(TipoParametroEnum.TIPO_CLIENTE);
    public periodoFacturacion$ = this.getParametro(
        TipoParametroEnum.PERIODO_FACTURACION
    );
    public monedaFacturacion$ = this.getParametro(
        TipoParametroEnum.MONEDA_FACTURACION
    );

    constructor(private _http: HttpClient) {}

    getParametro(
        clave: TipoParametroEnum
    ): Observable<GenericObjectResponseInterface[]> {
        return this._http
            .get<ResultResponseInterface<ParametroInterface>>(
                `${Api.url_parametro}/${clave}/`
            )
            .pipe(map((resp) => resp.objeto.valor));
    }

    getCompanies(all = false): Observable<GenericCompanyInterface[]> {
        return this._http
            .get<ResultResponseInterface<GenericCompanyInterface[]>>(
                `${Api.urlCliente}/cliente_basic/get_cliente_niveles/?all=${all}`
            )
            .pipe(map((resp) => resp.objeto));
    }

    getLevesByParentId(parentId: number): Observable<GenericLevelInterface[]> {
        return this._http
            .get<ResultResponseInterface<GenericLevelInterface[]>>(
                `${Api.urlCliente}/nivel_basic/get_by_parent/?nivel_padre=${parentId}`
            )
            .pipe(map((resp) => resp.objeto));
    }

    getWorkplacesByBranchId(
        branchId: number
    ): Observable<GenericWorkPlaceInterface[]> {
        return this._http
            .get<ResultResponseInterface<GenericWorkPlaceInterface[]>>(
                `${Api.urlCliente}/centro_trabajo/get_by_params/?sucursal=${branchId}`
            )
            .pipe(map((resp) => resp.objeto));
    }

    getEntities(type = 'eps', idClient = 3) {
        return this._http
            .get<ResultResponseInterface<GenericObjectEntityInterface[]>>(
                `${Api.urlEntidad}/entidad_by_tipo_by_cliente/?tipo=${type}&cliente=${idClient}`
            )
            .pipe(map((resp) => resp.objeto));
    }

    getSuccessAffiliationDate(body: {
        entidad_tipoentidad_id: number;
        fecha_ingreso: string;
    }) {
        return this._http
            .post<ResultResponseInterface<{ fecha_maxima_afiliacion: string }>>(
                `${Api.urlAfiliacion}/fecha_maxima_afiliacion/`,
                body
            )
            .pipe(map((resp) => resp.objeto.fecha_maxima_afiliacion));
    }
}
