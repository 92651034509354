import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Api } from '@core/models/Api.model';
import { Observable, map } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AfiliacionesDocumentoService {
    constructor(private http: HttpClient) {}

    uploadAnulationDocument(body: any): Observable<any> {
        return this.http.post<any>(`${Api.urlDocumento}/special/save/`, body);
    }

    getListaPersonaDocumentos() {
        return this.http.get<any[]>(
            `${Api.urlEmpleado}/persona_documento/basic`
        );
    }

    getListaDeDocumentos(): Observable<any> {
        return this.http.get<any>(
            `${Api.urlAfiliacion}/afiliacion_doc/normal/`
        );
    }

    getDocumentoAfiliacionEntidad(
        id: number,
        proceso: string,
        id_afiliacion_doc: number
    ): Observable<any> {
        return this.http
            .get<any>(
                `${Api.urlAfiliacion}/afiliacion_entidad_doc/special/afiliacion_entidad/${id}/`,
                {
                    params: {
                        proceso,
                        id_afiliacion_doc,
                    },
                }
            )
            .pipe(map((resp) => resp[resp.length - 1]));
    }
}
