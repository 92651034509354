import {Injectable} from '@angular/core';
import {
    HttpClient,
    HttpEvent,
    HttpEventType,
    HttpHeaders,
} from '@angular/common/http';
import {BehaviorSubject, Observable, catchError, map, throwError} from 'rxjs';
import {Api} from '@core/models/Api.model';

@Injectable({
    providedIn: 'root',
})
export class AfiliacionService {
    private progressSources: Map<string, BehaviorSubject<number>> = new Map();

    constructor(private _http: HttpClient) {
    }

    crearAfiliacion(body: any): Observable<any> {
        return this._http.post<any>(
            `https://afil-dev.focunti.com/afiliacion/crear_afiliacion/`,
            body
        );
    }

    crearAfiliacionMasivo(id: string, body: any): Observable<any> {
        this.getProgressSource(id).next(0); // Reset progress
        const req = this._http
            .post<any>(
                // `http://localhost:8000/afiliacion/carga_masiva/`,
                `${Api.BaseUrlAfiliacion}/afiliacion/carga_masiva/`,
                body,
                {
                    observe: 'events',
                    reportProgress: true,
                }
            )
            .pipe(
                map((event) => this.updateProgress(id, event)),
                catchError(this.handleError)
            );
        return req;
    }

    crearBeneficiariosMasivo(id: string, body: any): Observable<any> {
        this.getProgressSource(id).next(0); // Reset progress
        const req = this._http
            .post<any>(
                // `http://localhost:8000/afiliacion/carga_masiva_beneficiarios/`,
                `${Api.BaseUrlAfiliacion}/afiliacion/carga_masiva_beneficiarios/`,
                body,
                {
                    observe: 'events',
                    reportProgress: true,
                }
            )
            .pipe(
                map((event) => this.updateProgress(id, event)),
                catchError(this.handleError)
            );
        return req;
    }

    borrarMasivo(id_masivo: string): Observable<any> {
        return this._http.delete<any>(
            // `http://localhost:8000/afiliacion/borrar-masivo/${id_masivo}/`
            `${Api.BaseUrlAfiliacion}/afiliacion/borrar-masivo/${id_masivo}/`
        );
    }

    verificarExcel(hash: string): Observable<any> {
        return this._http.post<any>(
            // `http://localhost:8000/afiliacion/verificar_carga_masiva/`,
            `${Api.BaseUrlAfiliacion}/afiliacion/verificar_carga_masiva/`,
            { hash }
        );
    }

    getProgressSource(id: string): BehaviorSubject<number> {
        if (!this.progressSources.has(id)) {
            this.progressSources.set(id, new BehaviorSubject<number>(0));
        }
        return this.progressSources.get(id);
    }

    subirArchivosAfiliacionMasivo(
        id: string,
        body: any[],
        isBenef = false
    ): Observable<any> {
        this.getProgressSource(id).next(0);
        const uploadData = JSON.stringify(body);

        const req = this._http
            .post<any>(
                // `http://localhost:8000/afiliacion/carga_masiva/documentos_empleado/?beneficiarios=${isBenef}`,
                `${Api.BaseUrlAfiliacion}/afiliacion/carga_masiva/documentos_empleado/?beneficiarios=${isBenef}`,
                uploadData,
                {
                    observe: 'events',
                    reportProgress: true,
                    headers: new HttpHeaders({
                        'Content-Type': 'application/json',
                    }),
                }
            )
            .pipe(
                map((event) => this.updateProgress(id, event)),
                catchError(this.handleError)
            );

        return req;
    }

    private updateProgress(id: string, event: HttpEvent<any>): any {
        switch (event.type) {
            case HttpEventType.UploadProgress:
                const percentDone = Math.round(
                    (100 * event.loaded) / event.total
                );
                this.getProgressSource(id).next(percentDone);
                return {type: 'progress', progress: percentDone};

            case HttpEventType.Response:
                return {type: 'response', body: event.body};
        }
    }

    private handleError(error: any) {
        console.error(error);
        return throwError(error);
    }

    downloadExample(modelo: number = 1): Observable<any> {
        return this._http.get<any>(
            `${Api.BaseUrlAfiliacion}/afiliacion/carga_masiva/modelo/?modelo=${modelo}`
        );
    }

    getHistorialAfiliaciones(): Observable<any> {
        return this._http
            .post<any>(`${Api.urlAfiliacion}/afiliacion_historial`, {
                nombre_masivo_afiliacion_identificador: null,
                fecha_ingreso: null,
            })
            .pipe(map((resp) => resp.objeto));
    }

    getDocumentosFirmaAfilicacion(id: number): Observable<any> {
        return this._http.get<any>(
            `${Api.urlAfiliacion}/afiliacion/firma/documentos/${id}`
        );
    }

    /*
    Deprecated: Los documentos se generan al crear la afiliación

    generateDocumentosFirmaAfilicacion(id: number): Observable<any> {
        return this._http.post<any>(
            `${Api.urlAfiliacion}/afiliacion_entidad/firma/documentos`,{
                "afiliacion_entidad_id": id,
            }
        );
    }*/
}
