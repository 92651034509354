import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';

@Directive({
    selector: '[appUpperCase]',
    providers: [{
        provide: NG_VALUE_ACCESSOR,
        useExisting: UpperCaseDirective,
        multi: true
    }]
})
export class UpperCaseDirective implements ControlValueAccessor {

    constructor(private el: ElementRef, private renderer: Renderer2) {
    }

    @HostListener('input', ['$event.target.value'])
    onInput(value: string) {
        const newValue = value.toUpperCase();
        this.renderer.setProperty(this.el.nativeElement, 'value', newValue);
        this.onChange(newValue);
    }

    onChange = (_: any) => {
    };
    onTouched = () => {
    };

    writeValue(value: any): void {
        if (value != null) {
            const newValue = value.toUpperCase();
            this.renderer.setProperty(this.el.nativeElement, 'value', newValue);
        }
    }

    registerOnChange(fn: any): void {
        this.onChange = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouched = fn;
    }
}
