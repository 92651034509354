import * as moment from "moment";

export class momentService {
    addDays(date: Date, days: number) {
        return moment(date).add(days, 'days').toDate();
    }
    formatDate(date: Date) {
        return moment(date).format('YYYY-MM-DD');
    }

    formatDateDMY(date: string) {
        return moment(date).format('DD/MM/YYYY');
    }

    formatDateWithTime(date: Date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    formatDateWithTimeAndSeconds(date: Date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss');
    }

    formatDateWithTimeAndSecondsAndMilliSeconds(date: Date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss.SSS');
    }

    formatDateWithTimeAndMilliSeconds(date: Date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss.SSS');
    }

    formatDateWithTimeAndSecondsAndMilliSecondsAndMicroSeconds(date: Date) {
        return moment(date).format('YYYY-MM-DD HH:mm:ss.SSSSSS');
    }
}
