import {Route} from '@angular/router';
import {AuthGuard} from 'app/core/auth/guards/auth.guard';
import {NoAuthGuard} from 'app/core/auth/guards/noAuth.guard';
import {LayoutComponent} from 'app/layout/layout.component';
import {InitialDataResolver} from 'app/app.resolvers';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [

    // Redirect empty path to '/example'
    {path: '', pathMatch: 'full', redirectTo: 'home'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    {path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'home'},

    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () => import('app/modules/auth/confirmation-required/confirmation-required.module').then(m => m.AuthConfirmationRequiredModule)
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.module').then(m => m.AuthForgotPasswordModule)
            },
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.module').then(m => m.AuthSignInModule)
            },
            {
                path: 'sign-up',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.module').then(m => m.AuthSignUpModule)
            }
        ]
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty'
        },
        children: [
            {
                path: 'sign-out',
                loadChildren: () => import('app/modules/auth/sign-out/sign-out.module').then(m => m.AuthSignOutModule)
            },
            {
                path: 'unlock-session',
                loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.module').then(m => m.AuthUnlockSessionModule)
            }
        ]
    },

    // Landing routes
    {
        path: '',
        canMatch: [], // TODO: Verificar si debe ser []
        component: LayoutComponent,
        data: {
            layout: 'modern'
        },
        children: [
            {
                path: 'firma-formulario-afiliacion',
                loadChildren: () => import('app/modules/afiliacion/firma-formulario-afiliacion/firma-formulario-afiliacion.module').then(m => m.FirmaFormularioAfiliacionModule)
            }
        ]
    },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'modern'
        },
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            {
                path: 'home',
                loadChildren: () => import('app/modules/landing/home.module').then(m => m.LandingHomeModule)
            },
            {
                path: 'example',
                loadChildren: () => import('app/modules/admin/example/example.module').then(m => m.ExampleModule)
            },
            {
                path: 'afiliacion',
                loadChildren: () => import('app/modules/afiliacion/afiliacion.module').then(m => m.AfiliacionModule)
            },
            {
                path: 'afiliacion-individual',
                loadChildren: () => import('app/modules/afiliacion/afiliacion-individual/afiliacion-individual.module').then(m => m.AfiliacionIndividualModule)
            },
            {
                path: 'control-afiliacion',
                loadChildren: () => import('app/modules/afiliacion/control-afiliacion/control-afiliacion.module').then(m => m.ControlAfiliacionModule)
            },
            {
                path: 'afiliacion-home',
                loadChildren: () => import('app/modules/afiliacion/home-afiliacion/home-afiliacion.module').then(m => m.HomeAfiliacionModule)
            },
            {
                path: 'ans',
                loadChildren: () => import('app/modules/ans/ans.module').then(m => m.AnsModule)
            },
            {
                path: 'historial-afiliaciones',
                loadChildren: () => import('app/modules/afiliacion/historial-afiliaciones/historial-afiliaciones.module').then(m => m.HistorialAfiliacionesModule)
            },
            {
                path: 'informes',
                loadChildren: () => import('app/modules/informes/informes.module').then(m => m.InformesModule)
            }
        ]
    }
];
