<mat-accordion>
    <mat-expansion-panel *ngFor="let i of form.controls; index as idx">
        <mat-expansion-panel-header>
            <mat-panel-title>
                <div class="flex-1 flex justify-between">
                    Beneficiario {{ idx + 1 }}
                    <mat-icon
                        *ngIf="amountArray.length > 1"
                        class="icon-size-5 text-primary hover:scale-125"
                        [svgIcon]="'heroicons_solid:trash'"
                        (click)="clearStep($event, idx)"
                    ></mat-icon>
                </div>
            </mat-panel-title>
        </mat-expansion-panel-header>
        <mat-stepper
            linear
            #stepper
            class="bg-transparent"
            labelPosition="bottom"
        >
            <mat-step>
                <ng-template matStepLabel
                    >Información beneficiarios</ng-template
                >
                <ng-template matStepContent>
                    <app-beneficiares-data-form
                        (codeTypeChange)="updateCodeType($event, idx)"
                        (statusChange)="handleStatusChange($event, idx)"
                        [departamentos]="departamentos"
                        [entity]="entity"
                        [form]="getFormGroupByIndex(idx)"
                    >
                    </app-beneficiares-data-form>
                    <div
                        class="px-1/3 col-span-full grid gap-4 grid-cols-1 content-center sm:grid-cols-1 lg:grid-cols-1"
                    >
                        <button
                            mat-stroked-button
                            color="primary"
                            *ngIf="!acceptedStep[idx]"
                            [disabled]="!canBeSaved(idx)"
                            (click)="saveChanges(idx)"
                        >
                            Guardar cambios
                        </button>
                        <button
                            mat-raised-button
                            class="bg-green text-white"
                            *ngIf="acceptedStep[idx]"
                        >
                            Validado
                        </button>
                        <button
                            mat-raised-button
                            color="primary"
                            matStepperNext
                            [disabled]="!acceptedStep[idx]"
                        >
                            Siguiente
                        </button>
                    </div>
                </ng-template>
            </mat-step>
            <mat-step>
                <ng-template matStepLabel>Documentos beneficiarios --</ng-template>
                <ng-template matStepContent>
                    <app-beneficiares-documents-form
                        [entity]="entity"
                        [codeType]="codeType[idx]"
                        [parametros]="parametros"
                        [documentos]="getDocumentsAsFormArray(idx)"
                        (eventSaveChanges)="handleDocsSaveChanges($event, idx)"
                    ></app-beneficiares-documents-form>
                </ng-template>
            </mat-step>
        </mat-stepper>
    </mat-expansion-panel>
</mat-accordion>
