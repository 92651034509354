import {Component, inject, Input, SimpleChanges} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DepartmentInterface} from "@shared/interfaces/Department.interface";
import {DepartmentCityService} from "@shared/services/department-city.service";
import {of} from "rxjs";
import {DepartamentInterface} from "@shared/interfaces/departament.interface";
import {CityInterface} from "@shared/interfaces/city.interface";
import {ParametrosService} from "@shared/services/parametros.service";
import {GenericObjectEntityInterface} from "@shared/interfaces/generic-object-response.interface";

@Component({
  selector: 'app-employee-form',
  templateUrl: './employee-form.component.html',
  styleUrls: ['./employee-form.component.scss']
})
export class EmployeeFormComponent {
    @Input() form!: FormGroup
    @Input() departamentos!: DepartmentInterface[]
    @Input() clientId: number

    entitiesAfp = of(<GenericObjectEntityInterface[]>[])
    entitiesArl = of(<GenericObjectEntityInterface[]>[])
    entitiesEps = of(<GenericObjectEntityInterface[]>[])

    // @ViewChild('primer_apellido') primerApellidoTemplate: TemplateRef<any>;
    // @ViewChild('segundo_apellido') segundoApellidoTemplate: TemplateRef<any>;
    // @ViewChild('primer_nombre') primerNombreTemplate: TemplateRef<any>;
    // @ViewChild('segundo_nombre') segundoNombreTemplate: TemplateRef<any>;

    // templateMappings = {}
    //
    // ngAfterViewInit() {
    //     this.templateMappings['primer_apellido'] = this.primerApellidoTemplate
    //     this.templateMappings['segundo_apellido'] = this.segundoApellidoTemplate
    //     this.templateMappings['primer_nombre'] = this.primerNombreTemplate
    //     this.templateMappings['segundo_nombre'] = this.segundoNombreTemplate
    // }

    public parametrosService: ParametrosService = inject(ParametrosService)
    private departmentCityService: DepartmentCityService = inject(DepartmentCityService)
    public departments = of<DepartamentInterface[]>([])

    public citiesDepartament$ = of<CityInterface[]>([])
    public citiesBirdDay$ = of<CityInterface[]>([])
    public residenceCities$ = of<CityInterface[]>([])

    fields: string[] = []

    constructor() {
        this.departments = this.departmentCityService.getDepartments();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.form) {
            console.log(changes.form)

            this.fields = Object.keys(changes.form.currentValue.controls)
        }
    }

    ngOnInit() {
        this.entitiesAfp = this.parametrosService.getEntities('afp', this.clientId)
        this.entitiesArl = this.parametrosService.getEntities('arl', this.clientId)
        this.entitiesEps = this.parametrosService.getEntities('eps', this.clientId)
    }

    // getTemplateByField(field: string) {
    //     return this.templateMappings[field]
    // }
    today: Date = new Date();

    onDepartmentChange({value}) {
        this.citiesDepartament$ = this.departmentCityService.getCitiesByDepartment(value)
    }

    onDepartmentBirdDayChange({value}) {
        this.citiesBirdDay$ = this.departmentCityService.getCitiesByDepartment(value)
    }

    onDepartamentResidenceChange({value}) {
        this.residenceCities$ = this.departmentCityService.getCitiesByDepartment(value)
    }
}
