<div class="">
    <form [formGroup]="form">
        <div
            class="grid gap-4 grid-cols-1 content-center sm:grid-cols-1 lg:grid-cols-4"
        >
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Primer apellido</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="primer_apellido"
                    placeholder="Ingresa tu primer apellido"
                />
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Segundo apellido</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="segundo_apellido"
                    placeholder="Ingresa tu segundo apellido"
                />
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Primer nombre</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="primer_nombre"
                    placeholder="Ingresa tu primer nombre"
                />
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>
            <mat-form-field class="w-full" appearance="outline">
                <mat-label>Segundo nombre</mat-label>
                <input
                    matInput
                    type="text"
                    formControlName="segundo_nombre"
                    placeholder="Ingresa tu segundo nombre"
                />
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>
        </div>

        <div
            class="grid gap-4 grid-cols-1 content-center sm:grid-cols-1 lg:grid-cols-4"
        >
            <mat-form-field class="w-full">
                <mat-label>Tipo de identificacion</mat-label>
                <mat-select
                    placeholder="selecciona"
                    formControlName="codigo_tipo_identificacion"
                >
                    <mat-option
                        *ngFor="
                            let food of parametrosService.listaTipoIdentifiacion$
                                | async
                        "
                        [value]="food.cod"
                    >
                        {{ food.valor }}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Numero de identificacion</mat-label>
                <input
                    matInput
                    type="text"
                    placeholder="Ingresa tu identificacion"
                    formControlName="numero_identificacion"
                />
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field color="accent">
                <mat-label>Fecha de nacimiento</mat-label>
                <input
                    matInput
                    [max]="today"
                    [matDatepicker]="fechaNacimiento"
                    placeholder="AAAA/MM/DD"
                    formControlName="fecha_nacimiento"
                />
                <mat-datepicker-toggle
                    matIconSuffix
                    [for]="fechaNacimiento"
                ></mat-datepicker-toggle>
                <mat-datepicker #fechaNacimiento></mat-datepicker>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Sexo</mat-label>
                <mat-select
                    placeholder="selecciona"
                    formControlName="codigo_sexo"
                >
                    <mat-option
                        *ngFor="let food of parametrosService.sexo$ | async"
                        [value]="food.cod"
                    >
                        {{ food.valor }}
                    </mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <div
            class="grid gap-4 grid-cols-1 content-center sm:grid-cols-1 lg:grid-cols-4"
        >
            <mat-form-field class="w-full">
                <mat-label>Discapacidad</mat-label>
                <mat-select
                    placeholder="selecciona"
                    formControlName="codigo_discapacidad"
                >
                    <mat-option
                        *ngFor="
                            let food of parametrosService.discapacidad$ | async
                        "
                        [value]="food.cod"
                    >
                        {{ food.valor }}
                    </mat-option>
                </mat-select>
            </mat-form-field>

            <mat-form-field class="w-full">
                <mat-label>Parentesco</mat-label>
                <mat-select
                    placeholder="selecciona"
                    formControlName="codigo_parentesco"
                    (selectionChange)="handleParentChange($event)"
                >
                    <mat-option
                        *ngFor="let food of parentItems"
                        [value]="food.cod"
                    >
                        {{ food.valor }}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>
            <mat-form-field class="w-full">
                <mat-label>Tipo parentesco</mat-label>
                <mat-select
                    placeholder="selecciona"
                    (selectionChange)="handleTipoParentesco($event)"
                    formControlName="codigo_tipo_parentesco"
                    [disabled]="disableSubParent"
                >
                    <!-- <mat-option *ngFor="let food of subParentItems" [value]="food.cod"> -->
                    <mat-option *ngFor="let food of subParentItems; let first = first" [value]="food.cod" [selected]="first">
                            <!-- {{ food.valor }}
                        </mat-option> -->
                        {{ food.valor }}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>
            <div class="w-full">
                <div class="col-span-4 text-center">
                    Seleccione el tipo de afiliación que desea afiliar al
                    beneficiario
                </div>
                <div class="flex flex-row justify-center">
                    <mat-checkbox formControlName="pertenece_a_eps">
                        EPS
                    </mat-checkbox>
                    <mat-checkbox formControlName="pertenece_a_ccf">
                        CCF
                    </mat-checkbox>
                </div>
            </div>
        </div>
    </form>
</div>
