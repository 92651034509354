import {Pipe, PipeTransform} from '@angular/core';


@Pipe({
    name: 'tipoDocFilter'
})
export class TipoDocFilter implements PipeTransform {
    transform(value: any): any {
        if (value != null)
            return value.filter(tipoDoc => tipoDoc.cod != 'RC' && tipoDoc.cod != 'TI' && tipoDoc.cod != 'CNV');
        return value;
    }
}
