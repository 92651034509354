export enum TipoParametroEnum {
    TIPOS_IDENTIFICACION_ENTIDADES = 'tipo_identificacion_entidad',
    TIPO_DOCUMENTOS = 'tipo_documento',
    REGIMENES = 'regimen',
    TIPO_PROCESO = 'tipo_proceso',
    PERIODICIDAD = 'PERIODICIDAD',
    PERIODICIDAD_TIPO_NOMINA = 'tipo_nomina',
    TIEMPO_RESPUESTA = 'TIEMPO_RESPUESTA',
    TIEMPO_AFILIACION = 'TIEMPO_AFILIACION',
    CIUDADES = 'CIUDADES',
    MUNICIPIOS = 'MUNICIPIOS',
    TASA_ROTACION = 'TASA_ROTACION',
    OBJETO_SOCIAL = 'OBJETO_SOCIAL',
    TAMANIO_EMPRESA = 'tamanio_empresa',
    FORMA_PAGO = 'FORMA_PAGO',
    DIAS_PAGO = 'DIAS_PAGO',
    SEXO = 'sexo',
    ETNIA = 'etnia',
    TIPO_COTIZANTE = 'tipo_cotizante',
    TIPO_DIR_RES = 'tipo_dir_res',
    TIPO_DIR_TRAB = 'tipo_dir_trab',
    DISCAPACIDAD = 'discapacidad',
    CONDICION = 'condicion',
    TIPO_JORNADA_LABORAL = 'jornada_laboral',
    MODALIDAD_TRABAJO = 'modalidad_trabajo',
    TIPO_NOVEDAD = 'tipo_novedad',
    PARENTEZCO = 'parentezco',
    PARENTEZCO_HIJOS = 'parentezco_hijos_eps',
    PARENTEZCO_HERMANOS = 'parentezco_hermanos',
    TIPO_PARENTEZCO = 'tipo_parentezco',
    ESTADO_CIVIL = 'estado_civil',
    NIVEL_EDUCATIVO = 'nivel_educativo',
    NIVEL_OCUPACIONAL = 'nivel_ocupacional',
    ESTADOS = 'estados',
    ESTADOS_ANULACION = 'estados_anulacion',
    ESTADOS_NOVEDAD = 'estados_novedad',
    FACTOR_VULNERABILIDAD = 'factor_vulnerabilidad',
    TIPO_NOVEDAD_CCF = 'tipo_novedad_ccf',
    TIPO_NOVEDAD_AFP = 'tipo_novedad_afp',
    TIPO_NOVEDAD_ARL = 'tipo_novedad_arl',
    TIPO_NOVEDAD_EPS = 'tipo_novedad_eps',
    TIPO_CONTACTO = 'tipo_contacto',
    TIPO_META = 'tipo_meta',
    TIPO_CLIENTE = 'tipo_cliente',
    PERIODO_FACTURACION = 'periodo_facturacion',
    MONEDA_FACTURACION = 'moneda_facturacion',
    TIPO_CONTRATO = 'tipo_contrato',
}
