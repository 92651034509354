import { Injectable } from '@angular/core';
import Swal, { SweetAlertIcon } from "sweetalert2";

@Injectable({
    providedIn: 'root'
})
export class UtilPopupService {

    constructor() {
    }

    /**
     * Getter que devuelve un Swal configurado.
     */
    get swal(): typeof Swal {
        return Swal.mixin({
            allowOutsideClick: false,
            allowEscapeKey: false,
            cancelButtonText: "No",
            confirmButtonText: "Si",
            showCancelButton: true,
            showCloseButton: true,
            customClass: {
                popup: 'bg-primary text-white min-w-1/3',
                title: 'mat-headline-3 font-normal text-lime',
                actions: "flex flex-col flex-auto w-full px-8",
                confirmButton: 'w-full bg-white text-primary',
                cancelButton: 'w-full bg-lime text-primary'
            }
        });
    }

    /**
     * Método para mostrar un mensaje en pantalla usando sweetalert2
     * @param mensaje Texto del mensaje, puede ser HTML
     * @param tipo Tipo del mensaje 'warning' | 'error' | 'success' | 'info' | 'question'
     * @param titulo Título en el mensaje
     * @param mostrarConfirmacion Indica si se debe mostrar el botón de confirmación
     * @returns typeof @Swal
     */
    mostrarMensaje(mensaje: string, tipo: SweetAlertIcon, titulo: string = '', mostrarConfirmacion: boolean = true) {
        const options: any = {
            title: titulo,
            html: mensaje,
            icon: tipo,
        };

        if (!mostrarConfirmacion) {
            // Agregar opciones relacionadas con el botón de confirmación
            options.showCancelButton = false;
            options.showCloseButton = true;
            options.confirmButtonText = 'Continuar';
         
        }

        return this.swal.fire(options);
    }
}
