import {Component, EventEmitter, Input, Output} from '@angular/core';
import {MaterialModule} from "@shared/material.module";
import Swal from "sweetalert2";
import {MatDialog} from "@angular/material/dialog";
import {
    AffiliationLinkNotificationCopyComponent
} from "@shared/components/base-affiliation-container/components/affiliation-link-notification-copy/affiliation-link-notification-copy.component";
import {
    AffiliationEmailNotificationComponent
} from "@shared/components/base-affiliation-container/components/affiliation-email-notification/affiliation-email-notification.component";
import {DomSanitizer} from "@angular/platform-browser";
import {AfiliacionService} from "@shared/services/afiliacion/afiliacion.service";
import {UtilPopupService} from "@shared/services/util-popup.service";
import {NgIf} from "@angular/common";
import {NotificationsService} from "@shared/services/notifications/notifications.service";
import {EmailNotificationInterface} from "@shared/interfaces/email-notification.interface";
import {Documento} from "@features/afiliacion/interfaces/documentos-masivo.interface";
import { PageEvent } from '@angular/material/paginator';


@Component({
    selector: 'app-afiliacion-form-generado-modal',
    templateUrl: './afiliacion-form-generado-modal.component.html',
    standalone: true,
    imports: [
        MaterialModule,
        NgIf
    ],
    styleUrls: ['./afiliacion-form-generado-modal.component.scss']
})
export class AfiliacionFormGeneradoModalComponent {

    @Input() afiliacionId: number

    docSanitized: any = null;
    currentPageIndex: number = 0;
    @Input() correo: string = '';
    documentos: Documento[] = [];
    urlNotificacion: string = '';

    constructor(
        private dialog: MatDialog,
        private sanitizer: DomSanitizer,
        private afiliacionService: AfiliacionService,
        private utilPopupService: UtilPopupService,
        private notificationsService: NotificationsService
    ) {
    }

    @Output() onSaveAffiliation = new EventEmitter<string>();

    guardarAfiliacion() {
        this.onSaveAffiliation.emit('Guardar afiliación');
    }


    notificarPorCorreo() {
        const htmlText: string = `
                <div style="font-family: 'roboto'; text-align: center; margin-bottom: 10%;">
                    <p style="font-size: 34px; font-weight: 500; margin: 0;"><span style="color: #fff;">El correo al que estamos notificando es &quot;${this.correo}&quot;<br><span
                    style="text-align: center; font-size: 34px; font-weight: 500;color: #d9ff00; max-width: 400px ">¿Desea notificar a un correo distinto?</p>
                </div>

                <div style="display: flex; flex-direction: column; gap: 15px; align-items: center; margin-bottom: 5%;">
                    <button id="nuevoCorreoSi" style="background-color: #fff; color: #6523ff; border: none; padding: 12px 0; cursor: pointer; font-size: 16px; border-radius: 5px; width: 60%;">Si</button>
                    <button id="nuevoCorreoNo" style="background-color: #2F2F2F; color: #D9FF00; border: none; padding: 12px 0; cursor: pointer; font-size: 16px; border-radius: 5px; width: 60%;">No</button>
                </div>
            `;

        this.popupBase(htmlText, () => {
            document
                .getElementById('nuevoCorreoSi')
                .addEventListener('click', () => {
                    Swal.close();

                    const dialogRef = this.dialog.open(AffiliationEmailNotificationComponent, {
                        data: {
                            // informacion: this.informacionDepartamentos,
                        },
                        width: '85%',
                        minHeight: '410px',
                        maxWidth: '995px',
                        disableClose: false,
                    })
                    dialogRef.componentInstance.idAfiliacionGeneral = this.afiliacionId;
                    dialogRef.componentInstance.correo = this.correo;

                });

            document
                .getElementById('nuevoCorreoNo')
                .addEventListener('click', () => {

                    const email: EmailNotificationInterface = {
                        correo: this.correo,
                        asunto: 'Formulario de afiliación',
                        mensaje: 'Usted ha recibio los formularios de afiliación para su firma'
                    }

                    this.notificationsService.sendEmail(this.afiliacionId, email, false).subscribe((resp) => {
                        Swal.close();
                        this.popupBase(`
                      <div style="font-family: 'roboto'; text-align: center; margin-bottom: 10%;">
                        <p style="font-size: 34px; font-weight: 700; margin: 0;"><span style="color: #d9ff00;">Envio exitoso</p>
                        <p style="text-align: center; font-size: 20px; font-weight: 500; margin-top: 30px;margin-left: auto; margin-right: auto; color: #fff; max-width: 400px ">
                        El formulario de afiliación a sido enviado con éxito al correo <span style="color: #d9ff00;">${this.correo}</span>
                        </p>
                    </div>
                    `)
                    });


                });
        });

    }

    popupBase(htmlText: string, callback: any = null) {

        Swal.fire({
            html: htmlText,
            width: '50%',
            padding: '3em',
            background: '#6523ff',
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            customClass: {
                container: 'popup-container-class',
            },
            didOpen: () => {

                if (callback) {
                    callback();
                }
            },
        }).then((result) => {
            if (
                result.dismiss === Swal.DismissReason.esc ||
                result.dismiss === Swal.DismissReason.backdrop
            ) {
                return;
            }
        });
    }

    copiarEnlaceNotificacion() {
        const dialogRef = this.dialog.open(AffiliationLinkNotificationCopyComponent, {
            data: {
                // informacion: this.informacionDepartamentos,
            },
            height: '50%',
            width: '85%',
            maxHeight: '397px',
            maxWidth: '995px',
            disableClose: false,
        })
        dialogRef.componentInstance.enlace = this.urlNotificacion;
    }


    getDocuments() {
        this.documentos = [];
        this.afiliacionService.getDocumentosFirmaAfilicacion(this.afiliacionId).subscribe(
            (response) => {
                if (!response.error) {
                    this.urlNotificacion = response.objeto.url_notificacion;
                    const afiliaciones_entidades  =  response.objeto.afiliaciones_entidades ?? [];
                    afiliaciones_entidades.forEach(afiliacion => {
                        this.documentos.push(...afiliacion.documentos);
                    })

                    if (this.documentos.length > 0) {
                        this.loadDocuments(0);
                    } else {
                        this.utilPopupService.mostrarMensaje('No se encontraron documentos', 'error', '', false);

                    }
                }
            },
            (error) => {

            }
        );
    }

    private loadDocuments(index:number) {
        const document = this.documentos[index];
        const base64 = document.base64;
        const binaryString = atob(base64);
        const byteNumbers = new Array(binaryString.length);
        for (let i = 0; i < binaryString.length; i++) {
            byteNumbers[i] = binaryString.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        const documentPDF = new Blob([byteArray], {type: 'application/pdf'});
        const blobUrl = URL.createObjectURL(documentPDF)
        this.docSanitized = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl)
    }

    handlePageEvent(event: PageEvent) {
        this.currentPageIndex = event.pageIndex;
        this.loadDocuments(this.currentPageIndex);
    }
}
