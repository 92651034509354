import {Component, EventEmitter, inject, Input, Output, SimpleChanges} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DepartmentInterface} from "@shared/interfaces/Department.interface";
import {CityInterface} from "@shared/interfaces/city.interface";
import {DepartmentService} from "@shared/services/department/department.service";
import {ParametrosService} from "@shared/services/parametros.service";
import {GenericObjectResponseInterface} from "@shared/interfaces/generic-object-response.interface";
import {TipoEntidadType} from "@shared/enums/tipo-afiliacion.enum";
import {Subscription} from "rxjs";

@Component({
  selector: 'app-beneficiares-data-form',
  templateUrl: './beneficiares-data-form.component.html',
  styleUrls: ['./beneficiares-data-form.component.scss']
})
export class BeneficiaresDataFormComponent {
  @Input() form!: FormGroup
  @Input() departamentos: DepartmentInterface[]
  @Input() entity!: string
  @Output() codeTypeChange = new EventEmitter<string>()
  @Output() statusChange: EventEmitter<string> = new EventEmitter<string>()

  residenceCities: CityInterface[] = []
  public parametrosService: ParametrosService = inject(ParametrosService)
  parentItems: GenericObjectResponseInterface[] = []
  subParentItems: GenericObjectResponseInterface[] = []
  disableSubParent = true
  sub: Subscription
    today: Date = new Date();

  constructor(
    private departmentS: DepartmentService,
  ) {
  }

  ngOnInit() {
    this.parametrosService.parentezco$.toPromise().then(res => {
      switch (this.entity) {
        case TipoEntidadType.EPS:
          this.parentItems = res.filter(item => {
            return !item.tipo_entidad || item.tipo_entidad === TipoEntidadType.EPS.toLowerCase()
          });
          break;
        case TipoEntidadType.AFP:
          this.parentItems = res.filter(item => {
            return !item.tipo_entidad || item.tipo_entidad === TipoEntidadType.CCF.toLowerCase()
          })
          break;
        case TipoEntidadType.ALL:
          this.parentItems = res
      }
    })

    this.sub = this.form.statusChanges.subscribe(status => {
      if(status === 'INVALID'){
        this.statusChange.emit(status)
      }

    })
  }

  ngOnDestroy() {
    this.sub.unsubscribe()
  }

  ngOnChanges(changes: SimpleChanges) {
  }


  get f() {
    return this.form.controls;
  }


  handleResidenceDepartmentChange(event: any) {
    this.departmentS.getCitiesByDepartmentId(Number(event.value)).subscribe((data) => {
      this.residenceCities = data
    })
  }

  handleTipoParentesco(event: any) {
    this.codeTypeChange.emit(event.value)
  }

  async handleParentChange(event: any) {
    this.f.codigo_tipo_parentesco.setValue('');
    if (event.value != 'Hij' && event.value != 'Her') {
      this.subParentItems = []
      this.subParentItems.push(this.parentItems.find(item => item.cod === event.value))
      this.f.codigo_tipo_parentesco.setValue(event.value)
      this.codeTypeChange.emit(event.value)
    } else if (event.value === 'Hij') {

      this.subParentItems = await this.parametrosService.parentezcoHijos$.toPromise()
    } else if (event.value === 'Her') {

      this.subParentItems = await this.parametrosService.parentezcoHermanos$.toPromise()
    }
  }

}
