<form [formGroup]="form">
    <div class="font-bold py-5 text-gray-600 text-lg">Datos Personales</div>

    <div class="grid gap-4 grid-cols-2 content-center sm:grid-cols-2 lg:grid-cols-4">
        <ng-container *ngFor="let field of fields" [ngSwitch]="field">

            <mat-form-field *ngSwitchCase="'primer_apellido'" class="w-full">
                <mat-label>Primer apellido</mat-label>
                <input matInput type="text" formControlName="primer_apellido" placeholder="Ingresa tu primer apellido">
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field *ngSwitchCase="'segundo_apellido'" class="w-full">
                <mat-label>Segundo apellido</mat-label>
                <input matInput type="text" formControlName="segundo_apellido" placeholder="Ingresa tu segundo apellido">
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field *ngSwitchCase="'primer_nombre'" class="w-full">
                <mat-label>Primer nombre</mat-label>
                <input matInput type="text" formControlName="primer_nombre" placeholder="Ingresa tu primer nombre">
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field *ngSwitchCase="'segundo_nombre'" class="w-full">
                <mat-label>Segundo nombre</mat-label>
                <input matInput type="text" formControlName="segundo_nombre" placeholder="Ingresa tu segundo nombre">
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field color="accent" *ngSwitchCase="'fecha_nacimiento'">
                <mat-label>Fecha de nacimiento</mat-label>
                <input matInput [matDatepicker]="fechaNacimiento" [max]="today" formControlName="fecha_nacimiento"
                       placeholder="AAAA/MM/DD">
                <mat-datepicker-toggle matIconSuffix [for]="fechaNacimiento"></mat-datepicker-toggle>
                <mat-datepicker #fechaNacimiento></mat-datepicker>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'codigo_sexo'">
                <mat-label>Sexo</mat-label>
                <mat-select formControlName="codigo_sexo" placeholder="selecciona">
                    <mat-option *ngFor="let item of parametrosService.sexo$ |async" [value]="item.cod">
                        {{item.valor}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'codigo_tipo_identificacion'">
                <mat-label>Tipo de identificacion</mat-label>
                <mat-select formControlName="codigo_tipo_identificacion" placeholder="selecciona">
                    <mat-option *ngFor="let item of parametrosService.listaTipoIdentifiacion$ |async "
                                [value]="item.cod">
                        {{item.valor}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'numero_identificacion'">
                <mat-label>Numero de identificacion</mat-label>
                <input matInput type="text" formControlName="numero_identificacion"
                       placeholder="Ingresa tu identificación">
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field color="accent" *ngSwitchCase="'fecha_expedicion_documento'">
                <mat-label>Fecha de expedición de documento</mat-label>
                <input matInput [matDatepicker]="fechaexpediciondocumento" [max]="today" formControlName="fecha_expedicion_documento"
                       placeholder="AAAA/MM/DD">
                <mat-datepicker-toggle matIconSuffix [for]="fechaexpediciondocumento"></mat-datepicker-toggle>
                <mat-datepicker #fechaexpediciondocumento></mat-datepicker>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'id_depto_expedicion_documento'">
                <mat-label>Departamento de expedición del documento</mat-label>
                <mat-select formControlName="id_depto_expedicion_documento" placeholder="selecciona"
                            (selectionChange)="onDepartmentChange($event)">
                    <mat-option *ngFor="let item of departments |async" [value]="item.id">
                        {{item.nombre}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'id_mpio_expedicion_documento'">
                <mat-label>Municipio de expedición del documento</mat-label>
                <mat-select formControlName="id_mpio_expedicion_documento" placeholder="selecciona">
                    <mat-option *ngFor="let item of citiesDepartament$ |async" [value]="item.id">
                        {{item.nombre}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'id_ciudad_expedicion_documento'">
                <mat-label>Ciudad de expedición del documento</mat-label>
                <mat-select formControlName="id_ciudad_expedicion_documento" placeholder="selecciona">
                    <mat-option *ngFor="let item of citiesDepartament$ |async" [value]="item.id">
                        {{item.nombre}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'id_depto_residencia'">
                <mat-label>Departamento de residencia</mat-label>
                <mat-select formControlName="id_depto_residencia" placeholder="selecciona"
                            (selectionChange)="onDepartmentBirdDayChange($event)">
                    <mat-option *ngFor="let item of departments |async" [value]="item.id">
                        {{item.nombre}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'id_mpio_residencia'">
                <mat-label>Municipio de residencia</mat-label>
                <mat-select formControlName="id_mpio_residencia" placeholder="selecciona">
                    <mat-option *ngFor="let item of citiesDepartament$ |async" [value]="item.id">
                        {{item.nombre}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'id_ciudad_residencia'">
                <mat-label>Ciudad de residencia</mat-label>
                <mat-select formControlName="id_ciudad_residencia" placeholder="selecciona">
                    <mat-option *ngFor="let item of citiesDepartament$ |async" [value]="item.id">
                        {{item.nombre}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'barrio_residencia'">
                <mat-label>Barrio de residencia</mat-label>
                <input matInput type="text" formControlName="barrio_residencia" placeholder="Ingresa tu dirección rural">
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'correo'">
                <mat-label>Correo</mat-label>
                <input matInput type="text" formControlName="correo" placeholder="Ingresa tu numero correo">
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'numero_movil'">
                <mat-label>Número móvil</mat-label>
                <input matInput type="text" formControlName="numero_movil" placeholder="Ingresa tu numero número móvil">
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'telefono_fijo'">
                <mat-label>Teléfono fijo</mat-label>
                <input matInput type="text" formControlName="telefono_fijo" placeholder="Ingresa tu teléfono">
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'codigo_discapacidad'">
                <mat-label>Discapacidad</mat-label>
                <mat-select formControlName="codigo_discapacidad" placeholder="selecciona">
                    <mat-option *ngFor="let item of parametrosService.discapacidad$ | async" [value]="item.cod">
                        {{item.valor}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'codigo_condicion'">
                <mat-label>Condición</mat-label>
                <mat-select formControlName="codigo_condicion" placeholder="selecciona">
                    <mat-option *ngFor="let item of parametrosService.condicion$ |async" [value]="item.cod">
                        {{item.valor}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'emplea_lenguaje_especial'">
                <mat-label>Emplea un lenguaje especial</mat-label>
                <mat-select formControlName="emplea_lenguaje_especial" placeholder="selecciona">
                    <mat-option *ngFor="let food of ['Si', ' No']" [value]="food">
                        {{food}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'codigo_etnia'">
                <mat-label>Etnía</mat-label>
                <mat-select formControlName="codigo_etnia" placeholder="selecciona">
                    <mat-option *ngFor="let item of parametrosService.etnia$|async" [value]="item.cod">
                        {{item.valor}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'factor_vulnerabilidad'">
                <mat-label>Factor de vulnerabilidad</mat-label>
                <mat-select formControlName="factor_vulnerabilidad" placeholder="selecciona">
                    <mat-option *ngFor="let food of parametrosService.vulnerabilities$|async" [value]="food.cod">
                        {{food.valor}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'base_cotizacion'">
                <mat-label>Salario base</mat-label>
                <input matInput type="text" formControlName="base_cotizacion" placeholder="Ingresa tu salario actual">
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'cargo'">
                <mat-label>Cargo</mat-label>
                <input matInput type="text" formControlName="cargo" placeholder="Ingresa tu cargo actual">
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'codigo_modalidad_trabajo'">
                <mat-label>Modalidad de trabajo</mat-label>
                <mat-select formControlName="codigo_modalidad_trabajo" placeholder="selecciona">
                    <mat-option *ngFor="let item of parametrosService.modalidadTrabajo$|async " [value]="item.cod">
                        {{item.valor}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" appearance="outline" *ngSwitchCase="'direccion_laboral'">
                <mat-label>Direccion laboral</mat-label>
                <input matInput type="text" formControlName="direccion_laboral" readonly>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'codigo_estado_civil'">
                <mat-label>Estado civil</mat-label>
                <mat-select formControlName="codigo_estado_civil" placeholder="selecciona">
                    <mat-option *ngFor="let item of parametrosService.estadoCivil$ | async" [value]="item.cod">
                        {{item.valor}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'cabeza_hogar'">
                <mat-label>Usted es cabeza de hogar</mat-label>
                <mat-select formControlName="cabeza_hogar" placeholder="selecciona">
                    <mat-option *ngFor="let food of ['Si', ' No']" [value]="food">
                        {{food}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'vivienda_propia'">
                <mat-label>Tiene vivienda propia</mat-label>
                <mat-select formControlName="vivienda_propia" placeholder="selecciona">
                    <mat-option *ngFor="let food of ['Si', ' No']" [value]="food">
                        {{food}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'sector_agropecuario'">
                <mat-label>Sector agropecuario</mat-label>
                <mat-select formControlName="sector_agropecuario" placeholder="selecciona">
                    <mat-option *ngFor="let food of ['Si', ' No']" [value]="food">
                        {{food}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'codigo_nivel_educativo'">
                <mat-label>Nivel educativo</mat-label>
                <mat-select formControlName="codigo_nivel_educativo" placeholder="selecciona">
                    <mat-option *ngFor="let item of parametrosService.nivelEducativo$|async " [value]="item.cod">
                        {{item.valor}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'codigo_nivel_ocupacional'">
                <mat-label>Nivel ocupacional</mat-label>
                <mat-select formControlName="codigo_nivel_ocupacional" placeholder="selecciona">
                    <mat-option *ngFor="let item of parametrosService.nivelOcupacional$|async " [value]="item.cod">
                        {{item.valor}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'entidad_afp'">
                <mat-label>Entidad AFP</mat-label>
                <mat-select formControlName="entidad_afp" placeholder="selecciona">
                    <mat-option *ngFor="let food of entitiesAfp|async"
                                [value]="food.id_entidad_tipoentidad">
                        {{food.nombre_entidad}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'entidad_arl'">
                <mat-label>Entidad ARL</mat-label>
                <mat-select formControlName="entidad_arl" placeholder="selecciona">
                    <mat-option *ngFor="let food of entitiesArl|async"
                                [value]="food.id_entidad_tipoentidad">
                        {{food.nombre_entidad}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" *ngSwitchCase="'entidad_eps'">
                <mat-label>Entidad EPS</mat-label>
                <mat-select formControlName="entidad_eps" placeholder="selecciona">
                    <mat-option *ngFor="let food of entitiesEps|async"
                                [value]="food.id_entidad_tipoentidad">
                        {{food.nombre_entidad}}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>

            <mat-form-field class="w-full" appearance="outline" *ngSwitchCase="'estado'">
                <mat-label>estado</mat-label>
                <input matInput type="text" formControlName="estado" readonly>
            </mat-form-field>
        </ng-container>
    </div>
</form>
