import {Component, Inject, Input, OnInit} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DepartmentService } from '@shared/services/department/department.service';


@Component({
  selector: 'app-departments-operations-modal',
  templateUrl: './departments-operations-modal.component.html',
  styleUrls: ['./departments-operations-modal.component.scss']
})
export class DepartmentsOperationsModalComponent implements OnInit {

    departments: any[] = [];
    todosSeleccionados: boolean = null;
    @Input () defaultDepartmentsSelected: any[] = [];

    constructor(
        public dialogRef: MatDialogRef<DepartmentsOperationsModalComponent>,
        private departmentService: DepartmentService
    ) {}

    ngOnInit() {
        this.departmentService.getDepartments().subscribe(data => {
            this.departments = data.map(dep => ({
                ...dep,
                seleccionado: this.defaultDepartmentsSelected.includes(dep.codigo_departamento)
            }));
            this.todosSeleccionados =  this.departments.every(dep => dep.seleccionado)
        });
    }

    seleccionarTodos() {
        this.departments.forEach(dep => dep.seleccionado = this.todosSeleccionados);
    }

    deselectionTodos(){
        if (this.todosSeleccionados) {
            this.todosSeleccionados = false;
        }

        const departmentSelected = this.departments.filter(dep => dep.seleccionado);
        this.todosSeleccionados = departmentSelected.length === this.departments.length;
    }

    guardarCambios() {
        this.dialogRef.close(this.departments.filter(dep => dep.seleccionado));
    }

    closePopup(): void {
        this.dialogRef.close();
    }

}
