import { AbstractControl, ValidatorFn } from '@angular/forms';

export class customeValidations {
    static dateBeforeCurrent(control: AbstractControl) {
        if (!control.value) {
            return null;
        }

        const value = control.value;
        const valueDate = new Date(value);
        const currentDate = new Date();

        const valueYear = valueDate.getFullYear();
        const valueMonth = valueDate.getMonth();
        const valueDay = valueDate.getDate();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth();
        const currentDay = currentDate.getDate();

        const validYear = valueYear > currentYear;
        const validMonth =
            valueYear >= currentYear && valueMonth > currentMonth;
        const validDay =
            valueYear >= currentYear &&
            valueMonth >= currentMonth &&
            valueDay >= currentDay;

        if (validYear) {
            return null;
        }
        if (validMonth) {
            return null;
        }
        if (validDay) {
            return null;
        }

        return {
            dateBeforeCurrent: {
                dateBeforeCurrent: true,
                currentDate: valueDate,
            },
        };
    }

    static maxLength(length: number): ValidatorFn {
        return (control: AbstractControl) => {
            const value = control.value;
            if (value.length > length)
                return {
                    maxLength: {
                        maxLength: true,
                        requiredLength: length,
                        actualLength: value.length,
                    },
                };
            return null;
        };
    }

    static specialCharacters(control: AbstractControl) {
        const specialChar = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;

        if (control.value && specialChar.test(control.value)) {
            return { specialCharacters: { specialCharacters: true } };
        }
        return null;
    }

    static onlyText(control: AbstractControl) {
        const regexOnlyText = /^[a-zA-ZñÑá-úÁ-Ú\s]+$/;

        if (control.value && !regexOnlyText.test(control.value)) {
            return { onlyText: { onlyText: true } };
        }
        return null;
    }

    static onlyNumbers(control: AbstractControl) {
        const regexOnlyNumbers = /^[0-9]+$/;

        if (control.value && !regexOnlyNumbers.test(control.value)) {
            return { onlyNumbers: { onlyNumbers: true } };
        }
        return null;
    }

    static maxYears(maxYears: number, label?: string) {
        return (control: AbstractControl) => {
            const value = control.value;
            const valueDate = new Date(value);
            const currentDate = new Date();

            const valueYear = valueDate.getFullYear();
            const currentYear = currentDate.getFullYear();
            const years = currentYear - valueYear;

            if (value && years > maxYears) {
                return {
                    maxYears: {
                        maxYears: true,
                        requiredMaxYears: maxYears,
                        actualYears: years,
                        label,
                    },
                };
            }
            return null;
        };
    }

    static minYears(minYears: number, label?: string) {
        return (control: AbstractControl) => {
            const value = control.value;
            const valueDate = new Date(value);
            const currentDate = new Date();

            const valueYear = valueDate.getFullYear();
            const currentYear = currentDate.getFullYear();
            const years = currentYear - valueYear;

            if (value && years < minYears) {
                return {
                    minYears: {
                        minYears: true,
                        requiredMinYears: minYears,
                        actualYears: years,
                        label,
                    },
                };
            }
            return null;
        };
    }

    static isValidDate(): ValidatorFn {
        return (
            control: AbstractControl
        ): { [key: string]: boolean } | null => {
            if (!control.value) {
                return null;
            }

            const date = new Date(control.value);

            if (isNaN(date.getTime())) {
                return { invalidDate: true };
            }

            return null;
        };
    }
}
