import {Component, Injector, Input} from '@angular/core';
import {FormControl, ReactiveFormsModule} from "@angular/forms";
import {CommonModule} from "@angular/common";
import {MaterialModule} from "@shared/material.module";
import {MatFormField, MatFormFieldControl} from "@angular/material/form-field";
import {MatInput} from "@angular/material/input";
import {MatSelect} from "@angular/material/select";
import {MatRadioButton} from "@angular/material/radio";
import {MatCheckbox} from "@angular/material/checkbox";
import {onlyNumbers} from "@shared/validators/only-numbers";

@Component({
    selector: '[matErrorMessages]',
    template: `
        <ng-container>
            {{ error }}
        </ng-container>
    `,
    styleUrls: ['./error-message.component.scss'],
})
export class ErrorMessageComponent {
    public error = '';
    private inputRef: MatFormFieldControl<MatInput | MatSelect | MatRadioButton | MatCheckbox>;

    constructor(private _inj: Injector) {
    }

    public ngAfterViewInit(): void {
        // grab reference to MatFormField directive, where form control is accessible.
        const container = this._inj.get(MatFormField);
        this.inputRef = container._control;

        // sub to the control's status stream
        if (this.inputRef && this.inputRef.ngControl) {
            this.inputRef.ngControl.statusChanges.subscribe(this.updateErrors);
        }
    }

    private updateErrors = (state: 'VALID' | 'INVALID'): void => {
        if (state === 'INVALID') {
            // active errors on the FormControl
            const controlErrors = this.inputRef.ngControl.errors;

            // just grab one error
            const firstError = Object.keys(controlErrors)[0];
            // console.log(firstError, controlErrors)
            this.error = this.getErrorMessage(firstError, controlErrors[firstError])

        }
    };


    private getErrorMessage(validatorName: string, validatorValue?: any): string {
        // console.log(validatorValue)
        const errorMessages = {
            required: 'Este campo es requerido',
            minlength: `Debe ingresar como minimo ${validatorValue.requiredLength} caracteres`,
            maxlength : `Has excedido el limite de ${validatorValue.requiredLength} caracteres.`,
            pattern: 'Invalid pattern',
            maxYears: `${validatorValue.label} mayor a ${validatorValue.requiredMaxYears} años, comuníquese con el administrador`,
            minYears: `${validatorValue.label} menor a ${validatorValue.requiredMinYears} años, comuníquese con el administrador`,
            specialCharacters: "No se admiten caracteres especiales",
            onlyText: "No se admiten caracteres especiales ni numeros",
            onlyNumbers: "Solo se admiten caracteres numéricos",
            dateBeforeCurrent: "Es posible que genere cartera presunta por dicho periodo"
            // Agrega aquí más mensajes de error personalizados según tus necesidades
        };
        // console.log(errorMessages[validatorName], validatorName)
        return errorMessages[validatorName];
    }

    shouldShowErrors(): boolean {
        // console.log(this.inputRef && this.inputRef.ngControl.errors && this.inputRef.ngControl.touched)
        return this.inputRef && this.inputRef.ngControl.errors && this.inputRef.ngControl.touched;
    }
}
