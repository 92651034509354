import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { inject, Injectable } from '@angular/core';
import { TipoEntidadType } from '@shared/enums/tipo-afiliacion.enum';

@Injectable({
    providedIn: 'root',
})
export class AfiliacionesBaseFunctions {
    private fb: FormBuilder;

    constructor() {
        this.fb = inject(FormBuilder);
    }

    private makeBodyFromEps(form: FormGroup) {}

    private makeBodyFromCcf(form: FormGroup) {}

    private makeBodyFromAfp(form: FormGroup) {}

    private makeBodyFromArl(form: FormGroup) {}

    //this is the base form made in FUAM
    // {
    //     "datos_empresa": {
    //         "id": 1,
    //         "nit_empleador": "9011681775",
    //         "nivel_1": "",
    //         "nivel_2": "",
    //         "nivel_3": "",
    //         "nivel_4": "",
    //         "fecha_ingreso": "2023-09-30T05:00:00.000Z",
    //         "clase_riesgo": "nivel_riesgo_1",
    //         "departamento_trabajo": "91",
    //         "centro_trabajo": "administrativo"
    //     },

    //     "informacion_afiliacion": {
    //         "codigo_tipo_cotizante": "Dep",
    //         "afiliacion_efectiva": "2023-10-01",
    //         "tipoCotizante": "",
    //         "tiempoRadicacion": "",
    //         "afiliadoAntesCcf": "",
    //         "entidadAcfAnterior": "",
    //         "estado": "Pendiente",
    //         "id_entidad_eps": "nueva_eps",
    //         "codigo_tipo_novedad_eps": "tra_ing",
    //         "id_entidad_arl": "SURA",
    //         "codigo_tipo_novedad_arl": "cam_fec_ing",
    //         "id_entidad_afp": "COLPENSIONES",
    //         "codigo_tipo_novedad_afp": "cam_dat_bas",
    //         "id_entidad_ccf": "COLSUBSIDIO",
    //         "codigo_tipo_novedad_ccf": "tra_ing",
    //         "fecha_ingreso": "2023-10-01",
    //         "documentos": []
    //     },
    //     "beneficiarios": []
    // }
    private makeBodyFormFuam(form: FormGroup) {
        const datos_empresaBase = form.get('datos_empresa') as FormGroup;
        const datos_empleadoBase = form.get('datos_empleado') as FormGroup;
        const informacion_afiliacionBase = form.get(
            'informacion_afiliacion'
        ) as FormGroup;
        const beneficiariosBase = form.get('beneficiarios') as FormArray;

        const datos_empresa = this.fb.group({
            id: datos_empresaBase.get('id').value,
            nivel_1_id: datos_empresaBase.get('nivel_1').value ?? null,
            nivel_2_id: datos_empresaBase.get('nivel_2').value ?? null,
            nivel_3_id: datos_empresaBase.get('nivel_3').value ?? null,
            nivel_4_id: datos_empresaBase.get('nivel_4').value ?? null,
            tipo_contrato: datos_empresaBase.get('tipo_contrato').value ?? null,
        });

        const informacion_afiliacion = this.fb.group(
            Object.assign(
                informacion_afiliacionBase.value,
                this.fb.group({
                    afiliado_ccf_anterior: false,
                    identificador: 'identificador123',
                    fecha_fin: null,
                    regimen: '',
                    ingreso_base_de_cotización: '',
                    ciudad_trabajo: '',
                    tiene_beneficiarios: '',
                    tipo_direccion_trabajo: '',
                    direccion_trabajo: '',
                    departamento_trabajo: '',
                    municipio_trabajo: '',
                    clase_riesgo: '',
                    tipo_afiliacion: '',
                }).value,
                {}
            )
        );

        const datos_empleado = datos_empleadoBase;
        const beneficiarios = beneficiariosBase;

        return this.fb.group({
            datos_empresa,
            datos_empleado,
            informacion_afiliacion,
            beneficiarios,
        });
    }

    // this is the form required to generate a new afiliation
    // {
    //     "datos_empresa": {
    //         "id": "1",
    //         "nivel_1_id": 30,
    //         "nivel_2_id": 31,
    //         "nivel_3_id": 32,
    //         "nivel_4_id": 33
    //     },
    //     "datos_empleado": {
    //         "datos_personales": {
    //             "primer_apellido": "Diaz",
    //             "segundo_apellido": "Garcia",
    //             "primer_nombre": "Benjamin",
    //             "segundo_nombre": "Paolo",
    //             "id_nacionalidad": "36",
    //             "codigo_tipo_identificacion": "CC",
    //             "numero_identificacion": "70832ii222",
    //             "fecha_expedicion_documento": "2009-06-23 08:52:47",
    //             "id_depto_expedicion_documento": "5",
    //             "id_mpio_expedicion_documento": "167",
    //             "id_ciudad_expedicion_documento": "167",
    //             "fecha_nacimiento": "1990-08-13 08:52:47",
    //             "id_depto_nacimiento": "5",
    //             "id_mpio_nacimiento": "167",
    //             "id_ciudad_nacimiento": "167",
    //             "id_depto_residencia": "5",
    //             "barrio_residencia": "Parque La Pedrera",
    //             "id_mpio_residencia": "167",
    //             "id_ciudad_residencia": "167",
    //             "codigo_tipo_direccion_residencia": "UR",
    //             "direccion_residencia": "Parque la pedrera 72",
    //             "codigo_sexo": "M",
    //             "codigo_estado_civil": "SO",
    //             "correo": "zabala.fredy@gmail.com",
    //             "numero_movil": "3185023046",
    //             "telefono_fijo": "42555634",
    //             "codigo_discapacidad": "Nin",
    //             "codigo_condicion": "n/a",
    //             "emplea_lenguaje_especial": false,
    //             "codigo_etnia": "Nin",
    //             "factor_vulnerabilidad": "No Aplica",
    //             "cabeza_hogar": "No Aplica",
    //             "vivienda_propia": "si",
    //             "sector_agropecuario": "no",
    //             "codigo_nivel_educativo": "PRF"
    //         },
    //         "datos_laborales": {
    //             "codigo_nivel_ocupacional": "PR",
    //             "codigo_modalidad_trabajo": "Pres",
    //             "cargo": "PM",
    //             "numero_contrato": "123456",
    //             "base_cotizacion": "horas"
    //         },
    //         "documentos": [
    //             {
    //                 "id_persona_documento": 9,
    //                 "extension": "pdf",
    //                 "base64": "JVBERi0xLGCg=="
    //             }
    //         ]
    //     },
    //     "informacion_afiliacion": {
    //         "codigo_tipo_cotizante": "Independiente",
    //         "id_entidad_eps": "4",
    //         "codigo_tipo_novedad_eps": "Nue",
    //         "id_entidad_arl": "4",
    //         "codigo_tipo_novedad_arl": "Nue",
    //         "id_entidad_afp": "4",
    //         "codigo_tipo_novedad_afp": "Nue",
    //         "id_entidad_ccf": "4",
    //         "codigo_tipo_novedad_ccf": "Nue",
    //         "fecha_ingreso": "2023-08-31",
    //         "fecha_fin": null,
    //         "identificador": "identificador123",
    //         "afiliado_ccf_anterior": false,
    //         "regimen": "",
    //         "ingreso_base_de_cotización": "",
    //         "ciudad_trabajo": "",
    //         "tiene_beneficiarios": "",
    //         "tipo_direccion_trabajo": "",
    //         "direccion_trabajo": "",
    //         "departamento_trabajo": "",
    //         "municipio_trabajo": "",
    //         "clase_riesgo": "",
    //         "tipo_afiliacion": "",
    //         "documentos": []
    //     },
    //     "beneficiarios": [
    //         {
    //             "primer_apellido": "Huertas",
    //             "segundo_apellido": "Robayo",
    //             "primer_nombre": "Gloria",
    //             "segundo_nombre": "A",
    //             "codigo_tipo_identificacion": "CC",
    //             "numero_identificacion": "51243567",
    //             "fecha_nacimiento": "1964-06-30 08:52:47",
    //             "codigo_sexo": "F",
    //             "codigo_discapacidad": "Nin",
    //             "codigo_condicion": "n/a",
    //             "etnia": "Nin",
    //             "codigo_tipo_direccion": "UR",
    //             "direccion": "CALLE 46 NO 5 45",
    //             "id_departamento_residencia": "11",
    //             "id_municipio_residencia": "11",
    //             "id_ciudad_residencia": "11",
    //             "correo": "gloria@gmail.com",
    //             "numero_celular": "3123131",
    //             "codigo_parentesco": "Pad",
    //             "codigo_tipo_parentesco": "Mad",
    //             "documentos": [
    //                 {
    //                     "id_persona_documento": 6,
    //                     "extension": "pdf",
    //                     "base64": "JVBERi0xLGCg=="
    //                 }
    //             ]
    //         }
    //     ]
    // }
    public getBodyToRequest(form: FormGroup, type: string) {
        switch (type) {
            case TipoEntidadType.ALL:
                return this.makeBodyFormFuam(form);
            default:
                return null;
        }
    }
}
