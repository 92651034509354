<div class="block relative mt-[-15px] mr-[-15px]" style="float: right">
    <mat-icon  (click)="closeModal()"  class="icon-size-[41px] cursor-pointer text-gray-300">close</mat-icon>
</div>

<div class="mb-[50px] max-w-[587px] mx-auto">
    <div class="text-center pb-[50px]">
        <p style="font-size: 34px; font-weight: 500; margin: 0;"><span style="color: #6523FF;">Copiar enlace de la notificación</span>
        </p>
    </div>
    <div class="mb-[30px]">
        <mat-form-field
            class="w-full">
            <mat-label>Enlace</mat-label>
            <input matInput [readonly]="true" [ngModel]="enlace" placeholder="selecciona">
        </mat-form-field>
        <div>
            <mat-checkbox  ><span class="text-lg">Acortar url</span> </mat-checkbox>
        </div>
    </div>
    <div class="">
        <button
            class="w-full text-md rounded-[4px] h-[44px]"
            (click)="copiarEnlace()"
            mat-raised-button
            color="primary">
            <span>Copiar</span>
        </button>
    </div>

</div>
