import { Component } from '@angular/core';
import {MatInputModule} from "@angular/material/input";
import {MatOptionModule} from "@angular/material/core";
import {MatSelectModule} from "@angular/material/select";
import {NgForOf, NgIf} from "@angular/common";
import {MatCheckboxModule} from "@angular/material/checkbox";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {MaterialModule} from "@shared/material.module";
import {MatDialogRef} from "@angular/material/dialog";
import Swal from "sweetalert2";

@Component({
    selector: 'app-affiliation-link-notification-copy',
    templateUrl: './affiliation-link-notification-copy.component.html',
    standalone: true,
    imports: [
        MatInputModule,
        MatOptionModule,
        MatSelectModule,
        NgForOf,
        NgIf,
        MatCheckboxModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule
    ],
    styleUrls: ['./affiliation-link-notification-copy.component.scss']
})
export class AffiliationLinkNotificationCopyComponent {
    enlace : string = ''
  constructor(
      public dialogRef: MatDialogRef<AffiliationLinkNotificationCopyComponent>
  ) {
  }
    closeModal() {
        this.dialogRef.close();
    }

    copiarEnlace() {
        const textoACopiar = this.enlace;
        const textarea = document.createElement('textarea');
        textarea.value = textoACopiar;
        document.body.appendChild(textarea);
        textarea.select();
        document.execCommand('copy');
        document.body.removeChild(textarea);
        Swal.fire({
            html: `
                <div style="font-family: 'roboto'; text-align: center; margin-bottom: 10%;">
                    <p style="font-size: 48px; font-weight: 700; margin: 0;"><span style="color: #d9ff00;">Copia éxitosa</p>
                    <p style="text-align: center; font-size: 24px; font-weight: 500; margin-top: 30px;margin-left: auto; margin-right: auto; color: #fff; max-width: 400px ">
                    La notificación del formulario ha sido copiada, ya puedes compartirlo por el medio que desees
                    </p>
                </div>
            `,
            width: '50%',
            padding: '3em',
            background: '#6523ff',
            showCancelButton: false,
            showConfirmButton: false,
            showCloseButton: true,
            allowEscapeKey: false,
            allowOutsideClick: false,
            customClass: {
                container: 'popup-container-class',
            },
        }).then((result) => {
            if (
                result.dismiss === Swal.DismissReason.esc ||
                result.dismiss === Swal.DismissReason.backdrop
            ) {
                return;
            }
        });
    }
}
