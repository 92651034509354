import {Injectable} from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {Api} from "@core/models/Api.model";
import {ParameterInterface} from "@shared/interfaces/Parameter.interface";
import {ResultResponseInterface} from "@shared/interfaces/result-response.interface";

const parameterBaseUrl = `${Api.BaseUrl}/parametro/`

@Injectable({
    providedIn: 'root'
})

export class ParameterService{

    constructor(private  _http: HttpClient) {
    }

    getParametros(): Observable<ResultResponseInterface<ParameterInterface[]>>{
        return this._http.get<ResultResponseInterface<ParameterInterface[]>>(`${parameterBaseUrl}`)
    }

    getParametrosSexo(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 1).valor
    }
    getParametrosEtnia(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 2).valor
    }
    getParametrosDiscapacidad(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 3).valor
    }
    getParametrosCondicion(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 4).valor
    }
    getParametrosTipoNovedad(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 5).valor
    }
    getParametrosTipoTramite(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 6).valor
    }
    getParametrosRegimen(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 7).valor
    }
    getParametrosTipoAfiliacion(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 8).valor
    }
    getParametrosTipoCotizante(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 9).valor
    }
    getParametrosTipoDocumento(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 10).valor
    }
    getParametrosTipoHijo(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 11).valor
    }
    getParametrosTipoPadres(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 12).valor
    }
    getParametrosTipoFamiliarInc(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 13).valor
    }
    getParametrosTipoFamiliar(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 14).valor
    }
    getParametrosTipoNomina(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 15).valor
    }
    getParametrosTasaCotizacion(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 16).valor
    }
    getParametrosModalidadTrabajo(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 17).valor
    }
    getParametrosTipoDirRes(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 18).valor
    }
    getParametrosTipoDirLab(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 19).valor
    }
    getParametrosSectorEmpleador(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 20).valor
    }
    getParametrosEstadoCivil(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 21).valor
    }
    getParametrosNivelOcupacional(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 22).valor
    }
    getParametrosNivelEducativo(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 23).valor
    }
    getParametrosTipoTrabajador(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 24).valor
    }
    getParametrosTipoDirTrab(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 25).valor
    }
    getParametrosParentezco(data: ResultResponseInterface<ParameterInterface[]>){
        return data.objeto.find(e => e.id === 26).valor
    }
}
