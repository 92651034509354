export class Api {
    public static BaseUrl = 'app';
    public static BaseUrlAfiliacion = '';
    public static urlLogin: string = `${this.BaseUrl}/token/`;
    public static comboBox: string = `${this.BaseUrl}/api/combobox`;
    public static url_parametro: string = `${this.BaseUrl}/parametro`;
    public static urlAfiliacion: string = `${this.BaseUrlAfiliacion}afiliacion`;
    public static url_departamentos: string = `${this.BaseUrl}/departments/`;
    public static url_countries: string = `${this.BaseUrl}/countries`;
    public static url_ciudades: string = `${this.BaseUrl}/cities`;
    public static url_paises: string = `${this.BaseUrl}/countries`;
    public static urlEntidad: string = `${this.BaseUrlAfiliacion}/entidad`;
    public static urlCliente: string = `${this.BaseUrlAfiliacion}/cliente`;
    public static urlDocumento: string = `${this.BaseUrlAfiliacion}afiliacion_documento`;
    public static urlDocumentoPersonas: string = `${this.BaseUrlAfiliacion}documento`;
    public static urlEmpleado: string = `${this.BaseUrlAfiliacion}/empleado`;
    public static urlDocumentoBeneficiarios: string = `${this.urlEmpleado}/beneficiario_persona_documento`;
    public static urlDocumentoEmpleado: string = `${this.urlEmpleado}/empleado_persona_documento`;
    public static crear_afiliacion_individual: string = `${this.urlEmpleado}/crear_afiliacion_individual`;
    public static url_auto_ingreso: string = `${this.BaseUrl}/auto_sign`;
    public static url_consulta_firma_documentos: string = `${this.urlAfiliacion}/afiliacion/firma/documentos`;
    public static url_firmar_documentos: string = `${this.urlAfiliacion}/afiliacion/firma/digital`;
}
