<form [formGroup]="form">
    <div class="grid gap-4 grid-cols-1 content-center sm:grid-cols-1 lg:grid-cols-2">
        <mat-form-field class="w-full">
            <mat-label>Empresa Cliente </mat-label>
            <mat-select formControlName="id" placeholder="selecciona"
                (selectionChange)="handleChangeCustomerCompany($event)">
                <mat-option *ngFor="let food of parametrosService.companies$ | async" [value]="food.id">
                    {{ food.razon_social }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full" appearance="outline">
            <mat-label>Nit empleador</mat-label>
            <input matInput type="text" formControlName="nit_empleador" readonly />
            <mat-icon svgIcon="mat_solid:remove_red_eye" matSuffix></mat-icon>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Nivel 1</mat-label>
            <mat-select formControlName="nivel_1" placeholder="selecciona"
                (selectionChange)="handleLevelChange($event, '1', '2')">
                <mat-option *ngFor="let food of levels['1']" [value]="food.id">
                    {{ food.valor }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Nivel 2</mat-label>
            <mat-select placeholder="selecciona" formControlName="nivel_2"
                (selectionChange)="handleLevelChange($event, '2', '3')">
                <mat-option *ngFor="let food of levels['2']" [value]="food.id">
                    {{ food.valor }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Nivel 3</mat-label>
            <mat-select placeholder="selecciona" formControlName="nivel_3"
                (selectionChange)="handleLevelChange($event, '3', '4')">
                <mat-option *ngFor="let food of levels['3']" [value]="food.id">
                    {{ food.valor }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Nivel 4</mat-label>
            <mat-select placeholder="selecciona" formControlName="nivel_4">
                <mat-option *ngFor="let food of levels['4']" [value]="food.id">
                    {{ food.valor }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
        <div class="p-0">
            <mat-form-field class="mb-0 w-full">
                <mat-label>Fecha de ingreso</mat-label>
                <input matInput [matDatepicker]="fechaIngreso"  formControlName="fecha_ingreso"
                        (dateChange)="onFechaIngresoChange()" placeholder="AAAA/MM/DD" />
                <mat-datepicker-toggle matIconSuffix [for]="fechaIngreso"></mat-datepicker-toggle>
                <mat-datepicker #fechaIngreso></mat-datepicker>
            </mat-form-field>
            <div *ngIf="fechaIngresoAnterior" class="mt-0 pt-0 text-sm text-red-600"><span>Es posible que genere cartera
                    presunta por dicho periodo</span></div>
        </div>
        <div>
            <mat-form-field class="w-full">
                <mat-label>Tipo de contrato</mat-label>
                <mat-select formControlName="tipo_contrato" placeholder="selecciona">
                    <mat-option *ngFor="
                        let contract of parametrosService.contracts$ | async
                    " [value]="contract.cod">
                        {{ contract.valor }}
                    </mat-option>
                </mat-select>
                <mat-error matErrorMessages></mat-error>
            </mat-form-field>
        </div>
        <mat-form-field class="w-full">
            <mat-label>Departamento de trabajo</mat-label>
            <mat-select placeholder="seleciona" formControlName="departamento_trabajo">
                <mat-option *ngFor="let food of departamentos" [value]="food.codigo_departamento">
                    {{ food.nombre }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Centro de trabajo</mat-label>
            <mat-select formControlName="centro_trabajo" placeholder="seleciona"
                (selectionChange)="handleChangeWorkPLace($event)">
                <mat-option *ngFor="let food of workPlacesData" [value]="food.id">
                    {{ food.nombre }}
                </mat-option>
            </mat-select>
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>

        <mat-form-field class="w-full">
            <mat-label>Clase de riesgo</mat-label>
            <input matInput type="text" formControlName="clase_riesgo" readonly />
            <mat-error matErrorMessages></mat-error>
        </mat-form-field>
    </div>
</form>