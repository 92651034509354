import {
    Component,
    EventEmitter,
    inject,
    Input,
    OnInit,
    Output,
} from '@angular/core';
import { FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { ParametrosService } from '@shared/services/parametros.service';
import { TipoEntidadType } from '@shared/enums/tipo-afiliacion.enum';
import { FileService } from '@utils/functions/fileService';
import Swal from 'sweetalert2';
import { momentService } from '@utils/functions/momentService';
import { AfiliacionesDocumentoService } from '@shared/services/documents/afiliaciones-documento.service';
import { UtilPopupService } from '@shared/services/util-popup.service';

export const TipoEntidadTypeContainer = {
    EPS: {
        label: 'EPS',
        cod: 'eps',
        icon: 'bungalow',
    },
    AFP: {
        label: 'AFP',
        cod: 'afp',
        icon: 'elderly',
    },
    CCF: {
        label: 'CCF',
        cod: 'ccf',
        icon: 'spa',
    },
    ARL: {
        label: 'ARL',
        cod: 'arl',
        icon: 'work',
    },
};

@Component({
    selector: 'app-affiliation-form',
    templateUrl: './affiliation-form.component.html',
    styleUrls: ['./affiliation-form.component.scss'],
})
export class AffiliationFormComponent implements OnInit {
    @Input() form!: FormGroup;
    @Input() entity!: string;
    @Input() tieneBeneficiarios!: boolean;
    @Input() indeedDate: string = '';
    @Input() clientId: number;
    @Input() employeeDocuments: FormArray;

    @Output() beneficiariosChange: EventEmitter<any> = new EventEmitter<any>();

    public parametrosService: ParametrosService = inject(ParametrosService);
    noveltyList = {};
    containers = [];
    documentoIdentidadBase = '';
    acceptedEntity = [];
    entitiesLists = {};
    successAffiliationDate = {};
    idDoc: number;
    private fb: FormBuilder;

    constructor(
        private fileS: FileService,
        private momentS: momentService,
        private afilDocS: AfiliacionesDocumentoService,
        private utilPopupService: UtilPopupService
    ) {
        this.fb = inject(FormBuilder);
    }

    async ngOnInit() {
        if (
            this.entity === TipoEntidadType.EPS ||
            this.entity === TipoEntidadType.ALL
        ) {
            this.acceptedEntity.push(false);
            this.noveltyList[TipoEntidadType.EPS] =
                this.parametrosService.tipoNovedadAfiliacionEPS$;
            this.containers.push(TipoEntidadTypeContainer.EPS);
            this.entitiesLists[TipoEntidadType.EPS] =
                this.parametrosService.getEntities('eps', this.clientId);
            this.successAffiliationDate[TipoEntidadType.EPS] = '';
        }

        if (
            this.entity === TipoEntidadType.AFP ||
            this.entity === TipoEntidadType.ALL
        ) {
            this.acceptedEntity.push(false);
            this.noveltyList[TipoEntidadType.AFP] =
                this.parametrosService.tipoNovedadAfiliacionAFP$;
            this.containers.push(TipoEntidadTypeContainer.AFP);
            this.entitiesLists[TipoEntidadType.AFP] =
                this.parametrosService.getEntities('afp', this.clientId);
            this.successAffiliationDate[TipoEntidadType.AFP] = '';
        }

        if (
            this.entity === TipoEntidadType.CCF ||
            this.entity === TipoEntidadType.ALL
        ) {
            this.acceptedEntity.push(false);
            this.noveltyList[TipoEntidadType.CCF] =
                this.parametrosService.tipoNovedadAfiliacionCCF$;
            this.containers.push(TipoEntidadTypeContainer.CCF);
            this.entitiesLists[TipoEntidadType.CCF] =
                this.parametrosService.getEntities('ccf', this.clientId);
            this.successAffiliationDate[TipoEntidadType.CCF] = '';
        }

        if (
            this.entity === TipoEntidadType.ARL ||
            this.entity === TipoEntidadType.ALL
        ) {
            this.acceptedEntity.push(false);
            this.noveltyList[TipoEntidadType.ARL] =
                this.parametrosService.tipoNovedadAfiliacionARL$;
            this.containers.push(TipoEntidadTypeContainer.ARL);
            this.entitiesLists[TipoEntidadType.ARL] =
                this.parametrosService.getEntities('arl', this.clientId);
            this.successAffiliationDate[TipoEntidadType.ARL] = '';
        }

        this.idDoc = await this.afilDocS
            .getListaPersonaDocumentos()
            .toPromise()
            .then((res) => {
                return res.find((doc) => doc.codigo === 'DNI_Empleado')?.id;
            });

        this.form
            .get('codigo_tipo_cotizante')
            .valueChanges.subscribe((value) => {
                // disable id_entidad_afp when value is 'Pen' (Pensionado)
                if (value === 'Pen') {
                    this.form.get('id_entidad_afp').disable();
                } else {
                    this.form.get('id_entidad_afp').enable();
                }
            });
    }

    // openFile1() {
    //     let data: any = {}
    //     this.fileS.openFile().subscribe(obj => {
    //         data = {
    //             ...obj,
    //             id_persona_documento: this.idDoc
    //         }
    //     }, (_error) => {
    //         Swal.fire('Error', 'Error al cargar archivo', 'error')
    //     }, () => {
    //         this.documentoIdentidadBase = data.name

    //         this.employeeDocuments.push(this.fb.group(data))

    //         console.log(this.employeeDocuments)
    //     })
    // }

    openFile() {
        let data: any = {};
        this.fileS.openFile().subscribe(
            (obj) => {
                const file = obj[0]?.file || obj;
                if (!this.isPDF(file)) {
                    this.utilPopupService.mostrarMensaje(
                        'El tipo de archivo no es válido. <br>Por favor seleccione un archivo .PDF',
                        'info',
                        'Tipo de archivo no válido',
                        false
                    );
                    return;
                }
                if (!this.isValidFileSize(file)) {
                    this.utilPopupService.mostrarMensaje(
                        'El archivo excede el tamaño máximo permitido.<br>Seleccione un archivo que pese menos de 2 megabytes',
                        'info',
                        'Tamaño de archivo no válido',
                        false
                    );
                    return;
                }
                data = {
                    ...obj,
                    id_persona_documento: this.idDoc,
                };
                if (data.name) {
                    this.documentoIdentidadBase = data.name;
                    this.employeeDocuments.push(this.fb.group(data));
                    // // console.log(this.employeeDocuments);
                } else {
                    Swal.fire(
                        'Error',
                        'No se ha seleccionado un archivo válido',
                        'error'
                    );
                }
            },
            (_error) => {
                Swal.fire('Error', 'Error al cargar archivo', 'error');
            }
        );
    }

    isPDF(file: File): boolean {
        const extension = file.name.split('.').pop()?.toLowerCase();
        return extension === 'pdf';
    }

    isValidFileSize(obj: any): boolean {
        const maxSize = 2 * 1024 * 1024; // 2 MB in bytes
        const base64Size =
            obj.base64.length * (3 / 4) -
            (obj.base64.indexOf('=') > 0 ? 4 - obj.base64.indexOf('=') : 0);
        return base64Size <= maxSize;
    }

    beneficiariosClicked(event: any) {
        this.beneficiariosChange.emit(event);
    }

    clearStep(event: any, cod: string, idx: number) {
        event.stopPropagation();
        this.form.get('codigo_tipo_novedad_' + cod).setValue('');
        this.form.get('id_entidad_' + cod).setValue('');

        this.acceptedEntity[idx] = false;
    }

    handleEntityChange(cod: string) {
        // console.log('cod', cod)
        this.successAffiliationDate[cod] = '';

        const body = {
            entidad_tipoentidad_id: this.form.get(
                'id_entidad_' + cod.toLowerCase()
            ).value,
            fecha_ingreso: this.momentS.formatDateDMY(this.indeedDate),
        };

        this.parametrosService.getSuccessAffiliationDate(body).subscribe(
            (resp) => {
                // console.log(resp)
                this.successAffiliationDate[cod] = resp;

                // console.log("aaaa", this.successAffiliationDate[cod])
            },
            (_err) => {
                Swal.fire(
                    'Error',
                    `Error al obtener fecha de afiliación efectiva.\n${_err.descripcion}`,
                    'error'
                );
            }
        );

        // console.log('fecha max', this.successAffiliationDate[cod])
    }

    mostrarAfp(label: string): boolean {
        return (
            this.form.controls['codigo_tipo_cotizante'].value !== 'Pen' ||
            (label !== 'AFP' &&
                this.form.controls['codigo_tipo_cotizante'].value === 'Pen')
        );
    }

    protected readonly TipoEntidadType = TipoEntidadType;
    protected readonly JSON = JSON;
}
