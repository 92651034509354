<div class="px-1/4">
  <ng-container [ngSwitch]="codeType">
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS PAD (PADRES)-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'Pad'">
      <ng-container *ngTemplateOutlet="copia_documento_identidad_trabajador"></ng-container>
      <ng-container *ngTemplateOutlet="copia_documento_identidad_padre_madre"></ng-container>
      <ng-container *ngTemplateOutlet="copia_registro_civil_nacimiento_trabajador_demostrar_parentesco"></ng-container>
      <ng-container *ngTemplateOutlet="certificado_afiliacion_en_grupo_familiar"></ng-container>
      <ng-container *ngTemplateOutlet="declaracion_juramentada_dependencia_economica"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS CON (CONYUGE)-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'Con'">
      <ng-container *ngTemplateOutlet="copia_documento_identificacion_con"></ng-container>
      <ng-container *ngTemplateOutlet="registro_civil_matrimonio_con"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS COM (COMPANERO)-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'Com'">
      <ng-container *ngTemplateOutlet="copia_documento_identificacion_com"></ng-container>
      <ng-container *ngTemplateOutlet="registro_civil_matrimonio_com"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS CUS (BENEFICIARIO POR CUSTODIA)-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'Ben_cus'">
      <ng-container *ngTemplateOutlet="copia_registro_civil_nacimiento_beneficiario"></ng-container>
      <ng-container *ngTemplateOutlet="copia_documento_identidad"></ng-container>
      <ng-container *ngTemplateOutlet="documento_custodia_provenga_autoridad_competente"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS Hij_men_7-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'Hij_men_7'">
      <ng-container *ngTemplateOutlet="copia_registro_civil_nacimiento_hij"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS Hij_men_18-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'Hij_men_18'">
      <ng-container *ngTemplateOutlet="copia_registro_civil_nacimiento_hij_7_18"></ng-container>
      <ng-container *ngTemplateOutlet="copia_tarjeta_identidad_hij_7_18"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS Hij_men_23-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'Hij_men_23'">
      <ng-container *ngTemplateOutlet="copia_registro_civil_nacimiento_beneficiario_hij_18_23"></ng-container>
      <ng-container *ngTemplateOutlet="copia_documento_identidad_hij_18_23"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS Hij_men_25-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'Hij_men_25'">
      <ng-container
        *ngTemplateOutlet="copia_registro_civil_nacimientos_copia_documento_identidad_hij_men_25"></ng-container>
      <ng-container
        *ngTemplateOutlet="declaracion_jurementada_demostrando_dependencia_economica_hij_men_25"></ng-container>
      <ng-container *ngTemplateOutlet="certificado_escolaridad_vigente_hij_men_25"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS Hij_a_18-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'Hij_a_18'">
      <ng-container *ngTemplateOutlet="copia_registro_civil_nacimiento_hij_a_18"></ng-container>
      <ng-container *ngTemplateOutlet="copia_tarjeta_identidad_hij_a_18"></ng-container>
      <ng-container *ngTemplateOutlet="certificado_afiliacion_eps_hij_a_18"></ng-container>
      <ng-container
        *ngTemplateOutlet="declaracion_juramentada_dependencia_economica_convivencia_hij_a_18"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS Hij_a_18_25-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'Hij_a_18_25'">
      <ng-container *ngTemplateOutlet="copia_documento_identidad_hij_a_18_25"></ng-container>
      <ng-container *ngTemplateOutlet="declaracion_juramentada_hij_a_18_25"></ng-container>
      <ng-container *ngTemplateOutlet="certificado_adopcion_hij_a_18_25"></ng-container>
      <ng-container *ngTemplateOutlet="cetificado_afiliacion_eps_hij_a_18_25"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS Hij_25_disc-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'Hij_25_disc'">
      <ng-container *ngTemplateOutlet="copia_registro_civil_nacimiento_beneficiario_hij_25_disc"></ng-container>
      <ng-container *ngTemplateOutlet="copia_documento_identidad_beneficiario_hij_25_disc"></ng-container>
      <ng-container *ngTemplateOutlet="certificado_discapacidad_hij_25_disc"></ng-container>
      <ng-container *ngTemplateOutlet="formulario_afiliacion_firmado_hij_25_disc"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS Hij_25_disc-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'h_huer_18'">
      <ng-container *ngTemplateOutlet="copia_registro_civil_h_huer_18"></ng-container>
      <ng-container *ngTemplateOutlet="copia_legible_registro_civil_documento_identificacion_h_huer_18"></ng-container>
      <ng-container *ngTemplateOutlet="certificado_defuncion_padres_h_huer_18"></ng-container>
      <ng-container
        *ngTemplateOutlet="declaracion_juramentada_dependencia_economica_convivencia_h_huer_18"></ng-container>
      <ng-container *ngTemplateOutlet="certificado_afiliacion_eps_h_huer_18"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS Hij_25_disc-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'h_men_23'">
      <ng-container *ngTemplateOutlet="copia_legible_registro_civil_her_23"></ng-container>
      <ng-container *ngTemplateOutlet="copia_documento_identificacion_trabajador_her_23"></ng-container>
      <ng-container *ngTemplateOutlet="copia_legible_registro_civil_hermano_her_23"></ng-container>
      <ng-container *ngTemplateOutlet="copia_documento_identificacion_hermano_her_23"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS Hij_25_disc-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'h_huer_25'">
      <ng-container *ngTemplateOutlet="copia_registro_civil_h_huer_25"></ng-container>
      <ng-container *ngTemplateOutlet="copia_legible_registro_civil_hermano_h_huer_25"></ng-container>
      <ng-container *ngTemplateOutlet="certificado_defuncion_padres_h_huer_25"></ng-container>
      <ng-container
        *ngTemplateOutlet="declaracion_juramentada_dependencia_economica_convivencia_h_huer_25"></ng-container>
      <ng-container *ngTemplateOutlet="certificado_afiliacion_eps_h_huer_25"></ng-container>
      <ng-container *ngTemplateOutlet="certificado_escolaridad_vigente_h_huer_25"></ng-container>
    </ng-container>
    <!--DOCUMENTS FORM WHEN PARENT TYPE IS Hij_25_disc-->
    <ng-container class="flex flex-col gap-4" *ngSwitchCase="'h_huer_25_disc'">
      <ng-container *ngTemplateOutlet="copia_registro_civil_beneficiario_trabajador_h_huer_25_disc"></ng-container>
      <ng-container *ngTemplateOutlet="copia_documento_identidad_beneficiario_h_huer_25_disc"></ng-container>
      <ng-container *ngTemplateOutlet="registro_civil_defuncion_padres_h_huer_25_disc"></ng-container>
      <ng-container
        *ngTemplateOutlet="declaracion_juramentada_dependencia_economica_convivencia_h_huer_25_disc"></ng-container>
      <ng-container *ngTemplateOutlet="certificado_discapacidad_h_huer_25_disc"></ng-container>
      <ng-container *ngTemplateOutlet="certificado_afiliacion_eps_h_huer_25_disc"></ng-container>
      <ng-container *ngTemplateOutlet="formulario_afiliacion_firmado_h_huer_25_disc"></ng-container>
    </ng-container>


  </ng-container>

  <div class="py-4">
    <div class="grid gap-4 grid-cols-1 content-center sm:grid-cols-1 lg:grid-cols-1">
      <button mat-stroked-button color="primary" *ngIf="!isValid" [disabled]="!areValuesFilled(codeType)" (click)="saveChanges()">Guardar cambios</button>
      <button mat-raised-button class="bg-green text-white" *ngIf="isValid">Validado</button>
    </div>
  </div>
</div>

<ng-template #copia_documento_identidad_trabajador>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del documento de identidad del trabajador</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_PAD_VALUES.copia_documento_identidad_trabajador" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_PAD.copia_documento_identidad_trabajador,
                      FILES_PAD_VALUES,
                      'copia_documento_identidad_trabajador')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_documento_identidad_padre_madre>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del documento de identificación del padre y/o madre</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_PAD_VALUES.copia_documento_identidad_padre_madre" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFileByEntity(
                          entity,
                          FILES_PAD.copia_documento_identidad_padre_madre_caja,
                          FILES_PAD.copia_documento_identidad_padre_madre_eps,
                          FILES_PAD_VALUES,
                          'copia_documento_identidad_padre_madre')">
      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_registro_civil_nacimiento_trabajador_demostrar_parentesco>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del registro civil de nacimiento del trabajador para demostrar parentesco</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_PAD_VALUES.copia_registro_civil_nacimiento_trabajador_demostrar_parentesco" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFileByEntity(
                          entity,
                          FILES_PAD.copia_registro_civil_nacimiento_trabajador_demostrar_parentesco_caja,
                          FILES_PAD.copia_registro_civil_nacimiento_trabajador_demostrar_parentesco_eps,
                          FILES_PAD_VALUES,
                          'copia_registro_civil_nacimiento_trabajador_demostrar_parentesco')">
      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #certificado_afiliacion_en_grupo_familiar>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificado de afiliación a EPS en su grupo familiar (no mayor a 30 días)</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_PAD_VALUES.certificado_afiliacion_en_grupo_familiar" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_PAD.certificado_afiliacion_en_grupo_familiar_eps,
                      FILES_PAD_VALUES,
                      'certificado_afiliacion_en_grupo_familiar')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #declaracion_juramentada_dependencia_economica>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Declaración juramentada que indique dependencia económica</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_PAD_VALUES.declaracion_juramentada_dependencia_economica" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_PAD.declaracion_juramentada_dependencia_economica_eps,
                      FILES_PAD_VALUES,
                      'declaracion_juramentada_dependencia_economica')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_documento_identificacion_con>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del documento de identificación</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_CON_VALUES.copia_documento_identificacion" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFileByEntity(
                      entity,
                      FILES_CON.copia_documento_identificacion,
                      FILES_CON.copia_documento_identificacion_beneficiario,
                      FILES_CON_VALUES,
                      'copia_documento_identificacion')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #registro_civil_matrimonio_con>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Registro civil de matrimonio</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_CON_VALUES.registro_civil_matrimonio" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_CON.registro_civil_matrimonio,
                      FILES_CON_VALUES,
                      'registro_civil_matrimonio')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_documento_identificacion_com>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del documento de identificación</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_COM_VALUES.copia_documento_identificacion" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFileByEntity(
                      entity,
                      FILES_COM.copia_documento_identificacion,
                      FILES_COM.copia_documento_identificacion_beneficiario,
                      FILES_COM_VALUES,
                      'copia_documento_identificacion')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #registro_civil_matrimonio_com>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Declaración juramentada</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_COM_VALUES.declaracion_juramentada" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_COM.declaracion_juramentada,
                      FILES_COM_VALUES,
                      'declaracion_juramentada')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>


<ng-template #copia_registro_civil_nacimiento_beneficiario>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del registro civil de nacimiento del beneficiario</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_CUS_VALUES.copia_registro_civil_nacimiento_beneficiario" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_CUS.copia_registro_civil_nacimiento_beneficiario,
                      FILES_CUS_VALUES,
                      'copia_registro_civil_nacimiento_beneficiario')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_documento_identidad>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del documento de identidad</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_CUS_VALUES.copia_documento_identidad" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_CUS.copia_documento_identidad,
                      FILES_CUS_VALUES,
                      'copia_documento_identidad')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #documento_custodia_provenga_autoridad_competente>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Documento de custodia que provenga de autoridad competente</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_CUS_VALUES.documento_custodia_provenga_autoridad_competente" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_CUS.documento_custodia_provenga_autoridad_competente,
                      FILES_CUS_VALUES,
                      'documento_custodia_provenga_autoridad_competente')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_registro_civil_nacimiento_hij>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del registro civil de nacimiento de los hijos</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_7_VALUES.copia_registro_civil_nacimiento" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFileByEntity(
                      entity,
                      FILES_HIJ_7.copia_registro_civil_nacimiento_caja,
                      FILES_HIJ_7.copia_registro_civil_nacimiento_eps,
                      FILES_HIJ_7_VALUES,
                      'copia_registro_civil_nacimiento')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_registro_civil_nacimiento_hij_7_18>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del registro civil de nacimiento</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_7_18_VALUES.copia_registro_civil_nacimiento" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFileByEntity(
                      entity,
                      FILES_HIJ_7_18.copia_registro_civil_nacimiento_caja,
                      FILES_HIJ_7_18.copia_registro_civil_nacimiento_eps,
                      FILES_HIJ_7_18_VALUES,
                      'copia_registro_civil_nacimiento')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_tarjeta_identidad_hij_7_18>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia de la tarjeta de identidad</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_7_18_VALUES.copia_tarjeta_identidad" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFileByEntity(
                      entity,
                      FILES_HIJ_7_18.copia_tarjeta_identidad_caja,
                      FILES_HIJ_7_18.copia_tarjeta_identidad_eps,
                      FILES_HIJ_7_18_VALUES,
                      'copia_tarjeta_identidad')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_registro_civil_nacimiento_beneficiario_hij_18_23>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del registro civil de nacimiento</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_18_23_VALUES.copia_registro_civil_nacimiento" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_18_23.copia_registro_civil_nacimiento,
                      FILES_HIJ_18_23_VALUES,
                      'copia_registro_civil_nacimiento')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_documento_identidad_hij_18_23>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del documento de identidad</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_18_23_VALUES.copia_documento_identidad" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_18_23.copia_documento_identidad,
                      FILES_HIJ_18_23_VALUES,
                      'copia_documento_identidad')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_legible_registro_civil_her_23>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia legible del registro civil del trabajador</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HER_23_VALUES.copia_legible_registro_civil" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HER_23.copia_legible_registro_civil,
                      FILES_HER_23_VALUES,
                      'copia_legible_registro_civil')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_documento_identificacion_trabajador_her_23>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del documento de identificación del trabajador</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HER_23_VALUES.copia_documento_identificacion_trabajador" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HER_23.copia_documento_identificacion_trabajador,
                      FILES_HER_23_VALUES,
                      'copia_documento_identificacion_trabajador')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_legible_registro_civil_hermano_her_23>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia legible del registro civil del hermano (a)</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HER_23_VALUES.copia_legible_registro_civil_hermano" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HER_23.copia_legible_registro_civil_hermano,
                      FILES_HER_23_VALUES,
                      'copia_legible_registro_civil_hermano')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_documento_identificacion_hermano_her_23>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del documento de identificación del hermano (a)</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HER_23_VALUES.copia_documento_identificacion_hermano" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HER_23.copia_documento_identificacion_hermano,
                      FILES_HER_23_VALUES,
                      'copia_documento_identificacion_hermano')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_registro_civil_nacimientos_copia_documento_identidad_hij_men_25>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del registro civil de nacimiento y copia del documento de identidad</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_MEN_25_VALUES.copia_registro_civil_nacimientos_copia_documento_identidad" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_MEN_25.declaracion_jurementada_demostrando_dependencia_economica,
                      FILES_HIJ_MEN_25_VALUES,
                      'copia_registro_civil_nacimientos_copia_documento_identidad')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #declaracion_jurementada_demostrando_dependencia_economica_hij_men_25>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Declaración juramentada demostrando la dependencia económica del beneficiario</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_MEN_25_VALUES.declaracion_jurementada_demostrando_dependencia_economica" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_MEN_25.declaracion_jurementada_demostrando_dependencia_economica,
                      FILES_HIJ_MEN_25_VALUES,
                      'declaracion_jurementada_demostrando_dependencia_economica')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #certificado_escolaridad_vigente_hij_men_25>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificado de escolaridad vigente</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_MEN_25_VALUES.certificado_escolaridad_vigente" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_MEN_25.certificado_escolaridad_vigente,
                      FILES_HIJ_MEN_25_VALUES,
                      'certificado_escolaridad_vigente')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_registro_civil_nacimiento_beneficiario_hij_25_disc>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del registro civil de nacimiento y copia del documento de identidad</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_25_DISC_VALUES.copia_registro_civil_nacimiento_beneficiario" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_25_DISC.copia_registro_civil_nacimiento_beneficiario,
                      FILES_HIJ_25_DISC_VALUES,
                      'copia_registro_civil_nacimiento_beneficiario')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_documento_identidad_beneficiario_hij_25_disc>
  <div class="flex gap-4 items-center">
    <span
      class="w-1/2 font-medium text-gray-400">Copia del documento de identidad del beneficiario, según su edad</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_25_DISC_VALUES.copia_documento_identidad_beneficiario" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_25_DISC.copia_documento_identidad_beneficiario,
                      FILES_HIJ_25_DISC_VALUES,
                      'copia_documento_identidad_beneficiario')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #certificado_discapacidad_hij_25_disc>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificado de discapacidad expedido por el Ministerio de Salud y Protección Social</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_25_DISC_VALUES.certificado_discapacidad" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_25_DISC.certificado_discapacidad,
                      FILES_HIJ_25_DISC_VALUES,
                      'certificado_discapacidad')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #formulario_afiliacion_firmado_hij_25_disc>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Formulario de afiliación firmado por el trabajador</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_25_DISC_VALUES.formulario_afiliacion_firmado" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_25_DISC.formulario_afiliacion_firmado,
                      FILES_HIJ_25_DISC_VALUES,
                      'formulario_afiliacion_firmado')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_registro_civil_nacimiento_hij_a_18>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del registro civil de nacimiento</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_A_18_VALUES.copia_registro_civil_nacimiento" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_A_18.copia_registro_civil_nacimiento,
                      FILES_HIJ_A_18_VALUES,
                      'copia_registro_civil_nacimiento')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_tarjeta_identidad_hij_a_18>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia de la tarjeta de identidad</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_A_18_VALUES.copia_tarjeta_identidad" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_A_18.copia_tarjeta_identidad,
                      FILES_HIJ_A_18_VALUES,
                      'copia_tarjeta_identidad')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #certificado_afiliacion_eps_hij_a_18>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificado de afiliación a EPS en su grupo familiar (no mayor a 30 días)</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_A_18_VALUES.certificado_afiliacion_eps" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_A_18.certificado_afiliacion_eps,
                      FILES_HIJ_A_18_VALUES,
                      'certificado_afiliacion_eps')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #declaracion_juramentada_dependencia_economica_convivencia_hij_a_18>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Declaración juramentada que indique dependencia y convivencia con el trabajador</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_A_18_VALUES.declaracion_juramentada_dependencia_economica_convivencia" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_A_18.declaracion_juramentada_dependencia_economica_convivencia,
                      FILES_HIJ_A_18_VALUES,
                      'declaracion_juramentada_dependencia_economica_convivencia')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_documento_identidad_hij_a_18_25>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del documento de identidad</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_A_18_25_VALUES.copia_documento_identidad" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_A_18_25.copia_documento_identidad,
                      FILES_HIJ_A_18_25_VALUES,
                      'copia_documento_identidad')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #declaracion_juramentada_hij_a_18_25>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Declaración juramentada demostrando la dependencia económica del beneficiario</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_A_18_25_VALUES.declaracion_juramentada" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_A_18_25.declaracion_juramentada,
                      FILES_HIJ_A_18_25_VALUES,
                      'declaracion_juramentada')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #certificado_adopcion_hij_a_18_25>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificado de adopción o acta de entrega del menor</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_A_18_25_VALUES.certificado_adopcion" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_A_18_25.certificado_adopcion,
                      FILES_HIJ_A_18_25_VALUES,
                      'certificado_adopcion')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #cetificado_afiliacion_eps_hij_a_18_25>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificado de afiliación a EPS en su grupo familiar (no mayor a 30 días)</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_HIJ_A_18_25_VALUES.cetificado_afiliacion_eps" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_HIJ_A_18_25.cetificado_afiliacion_eps,
                      FILES_HIJ_A_18_25_VALUES,
                      'cetificado_afiliacion_eps')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_registro_civil_h_huer_18>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del registro civil del trabajador</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_18_VALUES.copia_registro_civil" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_18.copia_registro_civil,
                      FILES_H_HUER_18_VALUES,
                      'copia_registro_civil')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_legible_registro_civil_documento_identificacion_h_huer_18>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia legible del registro civil y copia del documento de identificación (T.I.) del hermano para demostrar parentesco</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_18_VALUES.copia_legible_registro_civil_documento_identificacion" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_18.copia_legible_registro_civil_documento_identificacion,
                      FILES_H_HUER_18_VALUES,
                      'copia_legible_registro_civil_documento_identificacion')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #certificado_defuncion_padres_h_huer_18>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificado de defunción de padre y madre</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_18_VALUES.certificado_defuncion_padres" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_18.certificado_defuncion_padres,
                      FILES_H_HUER_18_VALUES,
                      'certificado_defuncion_padres')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #declaracion_juramentada_dependencia_economica_convivencia_h_huer_18>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Declaración juramentada que indique dependencia económica y convivencia</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_18_VALUES.declaracion_juramentada_dependencia_economica_convivencia" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_18.declaracion_juramentada_dependencia_economica_convivencia,
                      FILES_H_HUER_18_VALUES,
                      'declaracion_juramentada_dependencia_economica_convivencia')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #certificado_afiliacion_eps_h_huer_18>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificado de afiliación a EPS en su grupo familiar (no mayor a 30 días)</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_18_VALUES.certificado_afiliacion_eps" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_18.certificado_afiliacion_eps,
                      FILES_H_HUER_18_VALUES,
                      'certificado_afiliacion_eps')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_registro_civil_h_huer_25>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del registro civil del trabajador</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_25_VALUES.copia_registro_civil" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_25.copia_registro_civil,
                      FILES_H_HUER_25_VALUES,
                      'copia_registro_civil')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_legible_registro_civil_hermano_h_huer_25>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del registro civil y copia del documento de identifiación (C.C.) del hermano para demostrar su parentesco</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_25_VALUES.copia_legible_registro_civil_hermano" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_25.copia_legible_registro_civil_hermano,
                      FILES_H_HUER_25_VALUES,
                      'copia_legible_registro_civil_hermano')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #certificado_defuncion_padres_h_huer_25>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificado de defunción de padre y madre</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_25_VALUES.certificado_defuncion_padres" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_25.certificado_defuncion_padres,
                      FILES_H_HUER_25_VALUES,
                      'certificado_defuncion_padres')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #declaracion_juramentada_dependencia_economica_convivencia_h_huer_25>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Declaración juramentada que indique la dependecia económica y convivencia</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_25_VALUES.declaracion_juramentada_dependencia_economica_convivencia" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_25.declaracion_juramentada_dependencia_economica_convivencia,
                      FILES_H_HUER_25_VALUES,
                      'declaracion_juramentada_dependencia_economica_convivencia')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #certificado_afiliacion_eps_h_huer_25>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificación de afiliación a EPS en su grupo familiar (no mayor a 30 días)</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_25_VALUES.certificado_afiliacion_eps" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_25.certificado_afiliacion_eps,
                      FILES_H_HUER_25_VALUES,
                      'certificado_afiliacion_eps')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #certificado_escolaridad_vigente_h_huer_25>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificado de escolaridad vigente</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_25_VALUES.certificado_escolaridad_vigente" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_25.certificado_escolaridad_vigente,
                      FILES_H_HUER_25_VALUES,
                      'certificado_escolaridad_vigente')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_registro_civil_beneficiario_trabajador_h_huer_25_disc>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificado de escolaridad vigente</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_25_DISC_VALUES.copia_registro_civil_beneficiario_trabajador" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_25_DISC.copia_registro_civil_beneficiario_trabajador,
                      FILES_H_HUER_25_DISC_VALUES,
                      'copia_registro_civil_beneficiario_trabajador')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #copia_documento_identidad_beneficiario_h_huer_25_disc>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Copia del documento de identidad del beneficiario</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_25_DISC_VALUES.copia_documento_identidad_beneficiario" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_25_DISC.copia_documento_identidad_beneficiario,
                      FILES_H_HUER_25_DISC_VALUES,
                      'copia_documento_identidad_beneficiario')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #registro_civil_defuncion_padres_h_huer_25_disc>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Registro civil de defunción del padre y madre</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_25_DISC_VALUES.registro_civil_defuncion_padres" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_25_DISC.registro_civil_defuncion_padres,
                      FILES_H_HUER_25_DISC_VALUES,
                      'registro_civil_defuncion_padres')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #declaracion_juramentada_dependencia_economica_convivencia_h_huer_25_disc>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Declaración juramentada que indique dependencia y convivencia</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_25_DISC_VALUES.declaracion_juramentada_dependencia_economica_convivencia"
             disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_25_DISC.declaracion_juramentada_dependencia_economica_convivencia,
                      FILES_H_HUER_25_DISC_VALUES,
                      'declaracion_juramentada_dependencia_economica_convivencia')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #certificado_discapacidad_h_huer_25_disc>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificado de discapacidad expedido por el Ministerio de Salud y Protección Social</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_25_DISC_VALUES.certificado_discapacidad" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_25_DISC.certificado_discapacidad,
                      FILES_H_HUER_25_DISC_VALUES,
                      'certificado_discapacidad')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #certificado_afiliacion_eps_h_huer_25_disc>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Certificado de afiliación a EPS en su grupo familiar (no mayor a 30 días)</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_25_DISC_VALUES.certificado_afiliacion_eps" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_25_DISC.certificado_afiliacion_eps,
                      FILES_H_HUER_25_DISC_VALUES,
                      'certificado_afiliacion_eps')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>

<ng-template #formulario_afiliacion_firmado_h_huer_25_disc>
  <div class="flex gap-4 items-center">
    <span class="w-1/2 font-medium text-gray-400">Formulario de afiliación firmado por el trabajador</span>
    <mat-form-field class="w-full">
      <input matInput type="text" placeholder="Subir documento"
             [(ngModel)]="FILES_H_HUER_25_DISC_VALUES.formulario_afiliacion_firmado" disabled>
      <mat-icon svgIcon="upload" matSuffix
                (click)="openFile(
                      FILES_H_HUER_25_DISC.formulario_afiliacion_firmado,
                      FILES_H_HUER_25_DISC_VALUES,
                      'formulario_afiliacion_firmado')">

      </mat-icon>
      <mat-error matErrorMessages></mat-error>
    </mat-form-field>
  </div>
</ng-template>
