import {Injectable} from '@angular/core';
import {EmailNotificationInterface} from "@shared/interfaces/email-notification.interface";
import {Observable} from "rxjs";
import {Api} from "@core/models/Api.model";
import {HttpClient} from "@angular/common/http";
import {WhatsappNotificationInterface} from "@shared/interfaces/whatsapp-notification.interface";

@Injectable({
    providedIn: 'root'
})
export class NotificationsService {

    constructor(private _http: HttpClient) {
    }


    sendEmail(afiliacion_entidad_id: number, email: EmailNotificationInterface, defaultMessage: boolean = true): Observable<any> {
        console.log('afiliacion_entidad_id', afiliacion_entidad_id)
        console.log('notificacion', email.correo, email.asunto, email.mensaje, defaultMessage)

        return this._http.post<any>(
            `${Api.urlAfiliacion}/firma/notificacion/`,
            {
                "afiliacion_entidad_id": afiliacion_entidad_id,
                "tipo_not_id": 1,
                "notificacion": {
                    "correo": email.correo,
                    "asunto": email.asunto,
                    "mensaje": email.mensaje,
                    "por_defecto": defaultMessage
                }
            }
        );
    }

    sendWhatsapp(afiliacion_entidad_id: number, wpp: WhatsappNotificationInterface): Observable<any> {
        return this._http.post<any>(
            `${Api.urlAfiliacion}/firma/notificacion/`,
            {
                "afiliacion_entidad_id": afiliacion_entidad_id,
                "tipo_not_id": 2,
                "notificacion": {
                    "celular": `${wpp.celular}`,
                    "mensaje": wpp.mensaje,
                    "por_defecto": wpp.mensaje === null
                }
            }
        );
    }
}
